import './index.scss';
import { useState } from 'react';
import StarEmptyIcon from '../../assets/svg/star-empty-icon.svg';
import StarGoldIcon from '../../assets/svg/star-gold-icon.svg';

const StarRating = ({rating, setRating}) => {
    const [hover, setHover] = useState(0);
    return (
        <div className="star-rating">
            {[...Array(5)].map((star, index) => {      
                index += 1;  
                return (
                    <div
                        key={`star_rating_${index}`}
                        className="star"
                        onClick={() => setRating(index)}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}
                        onDoubleClick={() => {
                            setRating(0);
                            setHover(0);
                        }}
                    >
                        <img src={index <= ((rating && hover) || hover) ? StarGoldIcon : StarEmptyIcon} />
                    </div>
                );
            })}
        </div>
    );
}

export { StarRating }