import url from 'url';
import { observer } from 'mobx-react-lite'
import { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Context } from '../../index'
import SearchImg from '@assets/svg/search.svg'
import './index.scss'
import { TaskTemplate } from '@Components/TaskTemplate'
import Typed from 'react-typed';
import { LoginPage } from '@Pages/LoginPage';
import { getTaskCategoriesCount, filterTasks, addWidthToTask, getAllTasksCount as getAllTasksCountApi } from '@api/task-api'
import { tasksEndingOfTheWord } from '@utils/utils';

const MyHomePage = observer(() => {
    const { modal, userStore } = useContext(Context);
    const [ searchText, setSearchText ] = useState('');
    const [ selectedCategory, setSelectedCategory ] = useState('');
    const [ taskCategoriesCount, setTaskCategoriesCount] = useState([])
    const [ tasks, setTasks ] = useState([]);
    const [ nextPage, setNextPage ] = useState('');
    const [ pageOrdering, setPageOrdering ] = useState(false);
    const [ bottomCount, setBottomCount ] = useState(1);
    const [ totalTasksCount, setTotalTasksCount ] = useState('');
    const [ isUpdatedOnLogin, setIsUpdatedOnLogin] = useState(false);
    useEffect(() => {
        if (userStore.user?.id > 0 && !isUpdatedOnLogin) {
            getTasksF();
            setIsUpdatedOnLogin(true);
        }
        if (!userStore.user?.id) {
            getTasksF();
            setIsUpdatedOnLogin(false);
        } 
    }, [userStore.user?.id]);
    useEffect(() => {
        const getTaskCategoriesCountF = async () => {
            const result = await getTaskCategoriesCount();
            setTaskCategoriesCount(result.data);
        }
        getTaskCategoriesCountF();
    }, []);
    useEffect(() => {
        getTasksF();
        getAllTaskCount();
    }, []);
    const getTasksF = async () => {
        filterTasks(searchText, 0, 0, 0, 0, [], [selectedCategory], 0, pageOrdering)
        .then((res) => {
            addWidthToTask(res.data);
            setTasks(res.data.results);
            setNextPage(res.data.next)
        })
        .catch((error) => {
            console.log(error);
        });
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        const getTasksF = async () => {
            if(nextPage) {
                if(url.parse(nextPage).query) {
                    const params = new URLSearchParams(url.parse(nextPage).query);
                    const nextPageOrdering = params.get('ordering') === 'created' ? true : false;
                    setPageOrdering(nextPageOrdering);
                    const nextPageURL = params.get('page');
                    filterTasks(searchText, 0, 0, 0, 0, [], [selectedCategory], 0, pageOrdering, nextPageURL)
                    .then((res) => {
                        addWidthToTask(res.data);
                        setTasks([...tasks, ...res.data.results]);
                        setNextPage(res.data.next)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            } else {
                if(tasks.length === 0) {
                    filterTasks(searchText, 0, 0, 0, 0, [], [selectedCategory], 0, pageOrdering)
                    .then((res) => {
                        addWidthToTask(res.data);
                        setTasks([...tasks, ...res.data.results]);
                        setNextPage(res.data.next)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            }
        }
        getTasksF();
    }, [bottomCount])
    const handleScroll = () => {
        if ((window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight) {
            setBottomCount(prevBottomCount => {
                let newBottomCount = prevBottomCount + 1;
                return newBottomCount;
            })
        }
    };
    const handleChangeString = (str) => {
        setTasks([]);
        setBottomCount(1);
        setNextPage('');
        setSearchText(str);
        getFilteredTasks(str, selectedCategory);
    }
    const handleChangeCategory = (category) => {
        setTasks([]);
        setBottomCount(1);
        setNextPage('');
        if (category === selectedCategory) {
            setSelectedCategory('');
            getFilteredTasks(searchText, '');
        } else {
            setSelectedCategory(category);
            getFilteredTasks(searchText, category);
        }
    }
    const getFilteredTasks = (str, category) => {
        const categoryArray = category ? [category] : [];
        filterTasks(str, 0, 0, 0, 0, [], categoryArray)
        .then((res) => {
            addWidthToTask(res.data);
            setTasks(res.data.results);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    const getAllTaskCount = () => {
        getAllTasksCountApi()
        .then((res) => {
            setTotalTasksCount(res.data);
        })
        .catch(() => {

        })
    }
  
    return (
        <div className="home-page">
            <div className="container">
                <div className="header1">
                    Всего на <span className="blue">devs</span>squad {totalTasksCount >= 0 && `${totalTasksCount} ${tasksEndingOfTheWord(totalTasksCount)} `}
                </div>
                <div className="header2">
                    {/* Выполняем задачи — <span className="blue">быстро|,</span><br/> */}
                    Выполняем задачи — <span className="blue">
                        <Typed
                            strings={['быстро', 'качественно', 'надёжно']}
                            typeSpeed={120}
                            backSpeed={140}
                            loop
                        />,</span><br/>
                    в срок и по приятной цене
                </div>
                <div className="header3">
                    { userStore.user?.id ? ( 
                        <Link to='/faq' key={1}>
                            <div className="header-link">Стать исполнителем</div>
                        </Link>
                    ) : (
                        <div className="header-link" onClick={() => modal.showModal(<LoginPage/>)}>
                            Стать исполнителем
                        </div>
                    )}
                    <Link to='/faq' key={2}>
                        <div className="header-link">Популярные вопросы</div>
                    </Link>
                    <Link to='contacts' key={3}>
                        <div className="header-link">Контакты</div>
                    </Link>
                    <Link to='/payment-service' key={4}>
                        <div className="header-link">Оплата и возвраты</div>
                    </Link>
                    <Link to='/service-rules' key={5}>
                        <div className="header-link">Правила сервиса ↗</div>
                    </Link>
                    {/* <div className="header-link">Служба поддержки</div> */}
                </div>
                <div className="row search-filter">
                    <div className="col-md-6">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">
                                    <img src={SearchImg} className="guy-img"/>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Найти услугу или заказ..."
                                    // name="searchText"
                                    value={searchText}
                                    onChange={ e => handleChangeString(e.target.value)}
                                />
                            </div>
                    </div>
                </div>
                <div className="selected-filters-block">
                    {
                        taskCategoriesCount.map((item, key) => {
                            return (
                                <button type="button" key={key}
                                        className={"category" + (selectedCategory === item.name ? " selected-category" : "")}
                                        onClick={() => {handleChangeCategory(item.name)}}
                                >
                                    {item.name} ({item.total_tasks})
                                </button>
                                )
                        })
                    }
                </div>
                <div className="main-second">
                    <div className="row">
                    {
                        tasks.map((item, key) => {
                            return (
                                <div className={`col-lg-${item.width}`} key={key}>
                                    {/* {item.Title} */}
                                    <TaskTemplate task={item}></TaskTemplate>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        </div>
    )
})

export { MyHomePage }