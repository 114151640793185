import { observer } from 'mobx-react-lite';
import { Markup } from 'react-render-markup';
import './index.scss';
import { useState, useEffect } from 'react';
import { getSubcategoryContent as getSubcategoryContentApi } from '@api/service-rules-api';


const PaymentServiceRulesPage = observer(() => {
    const [ content, setContent ] = useState('');
    const [ content2, setContent2 ] = useState('');

    useEffect(() => {
        const getSubcategoryContent = async () => {
            getSubcategoryContentApi(12)
            .then((res) => {
                if (res.data && res.data.content) {
                    setContent(res.data.content);
                } else {
                    setContent('');
                }
            })
            .catch((error) => {
                setContent('');
                console.log(error);
            });
            getSubcategoryContentApi(13)
            .then((res) => {
                if (res.data && res.data.content) {
                    setContent2(res.data.content);
                } else {
                    setContent2('');
                }
            })
            .catch((error) => {
                setContent2('');
                console.log(error);
            });
        }
        getSubcategoryContent();
    }, []);

    return (
        <div className="payment-service-rules-page">
            <div className="container">
                <div className="main">
                        <Markup markup={content} />
                        <Markup markup={content2} />
                        {/* {content} */}
                </div>
            </div>
        </div>
    )
})

export { PaymentServiceRulesPage }