import './index.scss'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

const NotFoundPage = observer(() => {
    return (
        <div className="not-found-page">
            <div className="container">
                <div className="error-code">
                    404
                </div>
                <div className="description">
                    Страница не найдена
                </div>
                <div className="to-main">
                    <Link to="/">
                        <button type="button" className="button">
                            Вернуться на главную
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
})

export { NotFoundPage }