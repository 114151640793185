import { observer } from 'mobx-react-lite';
import { Markup } from 'react-render-markup';
import './index.scss';
import { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { getCategories as getCategoriesApi, getSubcategoryContent as getSubcategoryContentApi } from '@api/service-rules-api';


const ServiceRulesPage = observer(() => {
    const [ categories, setCategories ] = useState([]);
    const [ content, setContent ] = useState('<h1><p><b>В этом разделе вы найдете всю основную информацию о сервисе Devssquad.</b></h1> </p>' +
        '<p>Используя меню слева, вы можете изучить юридическую информацию, правила работы сервиса и рекомендации для заказчиков и фрилансеров.</p>' +
        '<p>Надеемся, что эти знания помогут вам в долгом и продуктивном сотрудничестве с сервисом для IT-специалистов Devssquad.</p>' +
        '<p><b>Небольшая подсказка:</b> если вы не нашли необходимой информации в этом разделе, попробуйте заглянуть в <b>' + 
        '<a href="https://devssquad.com/faq" >FAQ</b></a>.</p>' +
        '<p>И там нет? Тогда будем рады ответить на все вопросы по почте: <b>info@devssquad.com</b> .</p>');
    const [ activeSubcategoryId, setActiveSubcategoryId ] = useState(0);
    
    useEffect(() => {
        const getCategories = async () => {
            getCategoriesApi()
            .then((res) => {
                console.log(res.data);
                setCategories(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
            // console.log(result.results);
        }
        getCategories();
    }, []);

    const getContent = (id) => {
        setActiveSubcategoryId(id);
        const getSubcategoryContent = async () => {
            getSubcategoryContentApi(id)
            .then((res) => {
                if (res.data && res.data.content) {
                    setContent(res.data.content);
                } else {
                    setContent('');
                }
            })
            .catch((error) => {
                setContent('');
                console.log(error);
            });
            // console.log(result.results);
        }
        getSubcategoryContent();
    }

    return (
        <div className="service-rules-page">
            <div className="container">
                <div className="main">
                    <div className="row gy-3">
                        <div className="col-lg-4">
                            <div className="left-block">
                            <Accordion defaultActiveKey="0">
                            {
                                categories.length > 0 && categories.map((c, i) => {
                                    return (
                                        <Accordion.Item eventKey={i} key={c.id}>
                                            <Accordion.Header>{c.name}</Accordion.Header>
                                            <Accordion.Body>
                                                {
                                                    c.subcategories.length > 0 && c.subcategories.map(s => {
                                                        return (
                                                            <div key={s.id} className={"subcategory " + (activeSubcategoryId === s.id ? "active-subcategory" : "")} 
                                                                onClick={() => getContent(s.id)}>{s.name}</div>
                                                        );
                                                    })
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            }
                            </Accordion>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="right-block">
                                <Markup markup={content} />
                                {/* {content} */}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
})

export { ServiceRulesPage }