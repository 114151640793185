import './index.scss';
import StarImg from '@assets/svg/star.svg';
import StarEmptyImg from '@assets/svg/star-empty.svg';
import { getReviews as getReviewsApi } from '@api/review-api.js';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getNextPageNumber } from '@utils/utils';

const ReviewComponent = ({userId}) => {
    const [ reviews, setReviews ] = useState([]);
    const [ nextPageNumber, setNextPageNumber ] = useState(null);

    const loadMoreReviews = () => {
        getReviewsApi(userId, nextPageNumber)
        .then((res) => {
            setReviews((prevReviews) => {
                return [ ...prevReviews, ...res.data.results];
            });
            setNextPageNumber(getNextPageNumber(res.data.next));
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        const getReviews = async () => {
        getReviewsApi(userId)
        .then((res) => {
            setReviews(res.data.results);
            setNextPageNumber(getNextPageNumber(res.data.next));
        })
        .catch((error) => {
            console.log(error);
        });
        }
        getReviews();
    }, [userId]);

    const renderStars = (r) => {
        let stars = []
        for (let i = 0; i < 5; i++) {
            if (i < r.rating) {
                stars.push(<img src={StarImg} alt="block" className="star-img" key={i}/>);
            } else {
                stars.push(<img src={StarEmptyImg} alt="block" className="star-img" key={i}/>);
            }
        }
        return (
            <div>
                {stars}
            </div>
        )
    }

    return (
        <div className="review-component">
            <div className="header">
                Отзывы
            </div>
            {
                reviews && reviews.length > 0 &&
                reviews.map((r, i) => {
                return (
                    <div className="review" key={i}>
                        <Link to={`/profile/${r.review_user.id}`}>
                            <div className="name">
                                <div className="first-name">
                                    {r?.review_user?.first_name && r.review_user.first_name.length > 8 ? `${r.review_user.first_name[0]}.` : r.review_user.first_name }
                                </div>
                                <div className="space">{' '}</div>
                                <div className="last-name">
                                    {r?.review_user?.last_name && `${r.review_user.last_name}`}
                                </div>
                            </div>
                        </Link>
                        <div className="stars">
                            {renderStars(r)}
                        </div>
                        <div className="description">
                            {r.description}
                        </div>
                    </div>
                )
                })
            }
            {nextPageNumber > 1 &&
                <div className="add-more">
                    <button
                        className="my-button2"
                        onClick={loadMoreReviews}
                    >
                        Загрузить еще
                    </button>
                </div>
            }
            {
                reviews && reviews.length == 0 &&
                <div className="review">
                    <div className="description no-reviews">
                        😐 У данного специалиста пока нет отзывов.
                    </div>
                </div>
            }
        </div>
    )
}

export { ReviewComponent }
