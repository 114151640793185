import config from '../config';
import instance from './axios-instance';

const STRAPI_API_URL = config.strapi.api_url + '/chat';

export const createRoom = (room) => {
    return instance.post(`${STRAPI_API_URL}/create-room/`, room)
}
export const createMessage = (body) => {
    return instance.post(`${STRAPI_API_URL}/create-message/`, body)
}
export const getMessages = (roomId, page) => {
    if (Number(page) > 1) {
        let params = {};
        params.page = page;
        return instance.get(`${STRAPI_API_URL}/messages/${roomId}/`, { params });
    }
    return instance.get(`${STRAPI_API_URL}/messages/${roomId}/`);
}

export const uploadFiles = (id, files) => {
    const formData = new FormData();
    formData.append("message", id.toString());
    for  (var i =  0; i <  files.length; i++)  {  
      formData.append("document",  files[i]);
    }
    return instance.post(`${STRAPI_API_URL}/message-documents/`, formData, { "Content-Type": "multipart/form-data" })
}

export const uploadUrls = (obj) => {
    return instance.post(`${STRAPI_API_URL}/message-urls/`, obj);
}