import { observer } from 'mobx-react-lite'
import './index.scss'
import XImg from '@assets/svg/x.svg'
import { RegisterPage } from '@Pages/RegisterPage'
import { Context } from '@/index'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form';
import { login } from '@api/user-api';

// --------------------------------
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// --------------------------------

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Введите корректный email')
    .required('Введите email'),
  password: Yup.string()
    .required('Введите пароль')
    .min(6, 'Пароль должен быть не менее 6 символов'),
})
const formOptions = { resolver: yupResolver(validationSchema) }

const LoginPage = observer(() => {
    const { modal, userStore } = useContext(Context)
    const [ loginError, setLoginError ] = useState('')
    const { register, handleSubmit, formState: { errors } } = useForm(formOptions)

    const onSubmit = ({
        email,
        password,
    }) => {
        login(email, password)
        .then((res) => {
            setLoginError('');
            userStore.fetchUser();
            modal.closeModal();
        //   setIsLoading(false)
        //   setIsSubmitted(true)
        //   userStore.setUser(res.data.user)
        //   userStore.setIsAuth(true)
        //   history.push('/')
        })
        .catch((error) => {
            console.log(error);
            const { data: { detail } } = error;
            switch(detail) {
                case 'No active account found with the given credentials':
                    setLoginError('😢 Введен неверный логин или пароль')
                    break;
                default:
                    setLoginError('😢 Произошла ошибка, проверьте правильность ввода данных')
                    break;
            }
            console.log(detail);
        //   setIsLoading(false)
        //   setIsSubmitted(false)
        })
    }

    return (
        <div className="login-page">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-modal">
                    Войти в личный кабинет 😎
                    <div className="x-block" onClick={() => modal.closeModal()}>
                        <img src={XImg} className="x"/>
                    </div>
                </div>
                <div className="form-control-block">
                    <label htmlFor="email" className="form-label">Адрес электронной почты</label>
                    <input
                        type="text"
                        className="form-control"
                        id="email" placeholder="Например, petrov@yandex.ru"
                        {...register("email")}
                        onFocus={() => loginError && setLoginError('')}
                    />
                    {errors.email?.message && (
                        <p className="error">{errors.email?.message}</p>
                    )}
                </div>
                <div className="form-control-block">
                    <label htmlFor="password" className="form-label">Пароль</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Введите пароль"
                        {...register("password")}
                        onFocus={() => loginError && setLoginError('')}
                    />
                    {errors.password?.message && (
                        <p className="error">{errors.password?.message}</p>
                    )}
                    {loginError && (
                        <p className="error">{loginError}</p>
                    )}
                </div>
                <div className="invalid-feedback">
                    Неправильные логин или пароль
                </div>
                <button type="submit" className="my-button">Войти</button>
                <button type="button" className="my-button register-button" onClick={() => modal.showModal(<RegisterPage />)}>Я первый раз на площадке 😶</button>
            </form>
        </div>
    )
})

export { LoginPage }