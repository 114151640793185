import { observer } from 'mobx-react-lite';
import AddTaskMiniImg from '@assets/svg/add-task-mini.svg';
import { Context } from '@/index';
import { useContext, useState, useEffect } from 'react';
import { getTasksForProfile as getTasksForProfileApi, getTask as getTaskApi } from '@api/task-api';
import { CreateTaskPage } from '@Pages/CreateTaskPage';
import { isOdd } from '@utils/utils';
import { Link } from 'react-router-dom';
import { EditTaskModal } from '@Components/EditTaskModal';

const ProfileTasks = observer(({userId, taskType = 1}) => {
    const { modal, userStore } = useContext(Context);
    const [ tasks, setTasks ] = useState([]);
    const [ tasksSlicer, setTasksSlicer] = useState(0);

    useEffect(() => {
        if (tasks.length > 0) {
            let slicer = isOdd(tasks.length) ? (tasks.length - 1) / 2 + 1 : tasks.length / 2;
            setTasksSlicer(slicer);
        }
    }, [tasks]);

    useEffect(() => {
        if(Number(userId) > 0) {
            getTasks();
        }
    }, [userId]);

    const editTaskHandler = (taskId) => {
        getTaskApi(taskId)
        .then((res) => {
            modal.showModal(<EditTaskModal taskId={taskId} task={res.data} programmingLanguage={JSON.stringify(res.data.programming_language)} callGetTask={getTasks} />);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const getTasks = () => {
        getTasksForProfileApi(userId, [], taskType)
        .then((res) => {
            console.log(res.data.results);
            setTasks(res.data.results);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const profileTasks = (task, i) => {
        return (
            <div className="task" key={`profile-tasks-${i}`}>
                <div className="task-description">
                    <div className="task-category-edit">
                        <div className="task-category">
                            {task.category.name}
                        </div>
                        {userStore && userStore?.user?.id && userStore.user.id === userId &&
                            <div
                                className="edit"
                                onClick={() => editTaskHandler(task.id)}
                            >
                                <span className="edit-icon"></span>
                            </div>
                        }
                    </div>
                    <Link to={`/tasks/${task.id}`}>
                        <div className="task-title">
                            {task.title}
                        </div>
                    </Link>
                    <div className="price">
                        {task.price > 9999 ? task.price.toLocaleString('ru-RU') : task.price } ₽
                    </div>
                </div>
                <div className="offers-count">
                    Предложений:{` ${task.total_responded_users}`}
                </div>
            </div>
        );
    }

    return (
        <div className="profile-tasks">
            <div className="row g-3 tasks">
                <div className="col-lg-6">
                    {tasks.slice(0, tasksSlicer).map((task, i) => profileTasks(task, i))}
                    {userStore && userStore?.user?.id && userStore.user.id === userId && (tasks.length === 0 || !isOdd(tasks.length)) &&
                        <div
                            className="add-task"
                            onClick={() =>  modal.showModal(<CreateTaskPage callback={getTasks} />)}
                        >
                            <button className="add">
                                <img className="add-task-img" alt="add task" src={AddTaskMiniImg} />
                            </button>
                            <div className="title">
                                Добавить {taskType === 1 ? 'заказ' : 'услугу' }
                            </div>
                        </div>
                    }
                </div>
                <div className="col-lg-6">
                    {tasks.slice(tasksSlicer).map((task, i) => profileTasks(task, i))}
                    {userStore && userStore?.user?.id && userStore.user.id === userId && (tasks.length > 0 && isOdd(tasks.length)) &&
                        <div
                            className="add-task"
                            onClick={() =>  modal.showModal(<CreateTaskPage callback={getTasks} />)}
                        >
                            <button className="add">
                                <img className="add-task-img" alt="add task" src={AddTaskMiniImg} />
                            </button>
                            <div className="title">
                                Добавить {taskType === 1 ? 'заказ' : 'услугу' }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
})

export { ProfileTasks }