import './index.scss';
import { observer } from 'mobx-react-lite';
import ChatWebSocketInstance from '@api/chat-websocket';
import { useContext, useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Context } from '@/index';
import { getMessages as getMessagesApi, createMessage as createMessageApi, uploadFiles as uploadFilesApi } from '@api/chat-api';
import { HireFreelancer } from '@Components/HireFreelancer';
import { DeclineFreelancer } from '@Components/DeclineFreelancer';
import ProfilePlaceholder from '@assets/img/profile-placeholder.jpg';
import StarSvg from '@assets/svg/star.svg';
import UserStatusGreenSvg from '@assets/svg/user-status-icon-green.svg';
import UserStatusGreySvg from '@assets/svg/user-status-icon-grey.svg';
import ArrowUpSvg from '@/assets/svg/arrow-up.svg';
import AttachmentSvg from '@assets/svg/attachment.svg';
import { PreviewAttachedFiles } from '@Components/PreviewAttachedFiles';
import { AttachedFilesFromUrlsHorizontal } from '@Components/AttachedFilesFromUrlsHorizontal';
import { AttachedLinks } from '@Components/AttachedLinks';
import config from '@/config';
import { formatDate, getNextPageNumber, profileTimeUTC } from '@utils/utils';
import { Link } from 'react-router-dom';

const ChatComponent = observer(({index, task, callGetTask, assigned = false}) => {
    const { modal, userStore, chatStore } = useContext(Context);
    // const [ runOnce, setRunOnce] = useState(false);
    const { register, errors, handleSubmit, control, setValue } = useForm();
    const [ selectedFiles, setSelectedFiles ] = useState([]);
    const [ nextPage, setNextPage ] = useState(1);
    const previewFilesElement = useRef();
    const [ freelancerTimeUTC, setFreelancerTimeUTC ] = useState(null);
    const [ managerTimeUTC, setManagerTimeUTC ] = useState(null);
     
    useEffect(() => {
        // if (!runOnce) {
            getMessages();
            
            // setRunOnce(true);
        // }
    }, [task]);

    useEffect(() => {
        if(assigned) {
            if(chatStore.rooms[index].responded_user.geodata?.utc_offset || chatStore.rooms[index].responded_user.geodata?.utc_offset === 0) {
                const interval = setInterval(() => {
                    const newTime = profileTimeUTC(chatStore.rooms[index].responded_user.geodata.utc_offset);
                    setFreelancerTimeUTC(newTime);
                }, 1000);
              
                return () => {
                    clearInterval(interval);
                };
            }
        }
    }, [chatStore.rooms[index]?.responded_user?.geodata?.utc_offset]);

    useEffect(() => {
        if(assigned) {
            if(task.created_user.geodata?.utc_offset || task.created_user.geodata?.utc_offset === 0) {
                const interval = setInterval(() => {
                    const newTime = profileTimeUTC(task.created_user.geodata.utc_offset);
                    setManagerTimeUTC(newTime);
                }, 1000);
              
                return () => {
                    clearInterval(interval);
                };
            }
        }
    }, [task.created_user?.geodata?.utc_offset]);

    useEffect( async () => {
        if(nextPage > 1) {
            const messagesReponse = await getMessagesApi(chatStore.rooms[index].id, nextPage);
            let { next, results } = messagesReponse.data;
            chatStore.setMessages(index, [ ...chatStore.getMessages(index), ...results ]);
            setNextPage(getNextPageNumber(next));
        }
    }, [nextPage]);

    const getMessages = async () => {
        const messagesReponse = await getMessagesApi(chatStore.rooms[index].id);
        let { next, results } = messagesReponse.data;
        chatStore.setMessages(index, results);
        setNextPage(getNextPageNumber(next));
        ChatWebSocketInstance.connect(chatStore.rooms[index].id);
        waitForSocketConnection(() => {
            ChatWebSocketInstance.addCallbacks(addMessageCallback.bind(this));
        });
    }

    const waitForSocketConnection = (callback) => {
        setTimeout(
          () => {
            // Check if websocket state is OPEN
            if (ChatWebSocketInstance.state() === 1) {
              console.log("Connection is made")
              callback();
              return;
            } else {
              console.log("wait for connection...")
              waitForSocketConnection(callback);
            }
        }, 100); // wait 100 milisecond for the connection...
    }

    const onSubmit = ({message}) => {
        const messageBody = {
            author: userStore.user.id,
            room: chatStore.rooms[index].id,
            content: message
        }
        createMessageApi(messageBody)
        .then((res) => {
            res.data.command = 'new_message';
            if (res && res.data && selectedFiles.length) {
                uploadFilesApi(res.data.id, selectedFiles)
                .then((uploadRes) => {
                    setSelectedFiles([]);
                    setValue("message", "");
                    res.data.document_urls = uploadRes.data.document_urls;
                    ChatWebSocketInstance.newChatMessage(res.data);
                })
                .catch((error) => {
                    setSelectedFiles([]);
                    setValue("message", "");
                    ChatWebSocketInstance.newChatMessage(res.data);
                })
            }  else {
                setValue("message", "");
                ChatWebSocketInstance.newChatMessage(res.data);
            }
            const receiverId = task.created_user.id === userStore.user.id ? chatStore.rooms[index].responded_user.id : task.created_user.id
            userStore.newChatMessage(receiverId, task.id);
            
        })
        .catch((error) => {
            console.log(error);
        })
        
    }

    const addMessageCallback = (message) => {
        const roomsIds = chatStore.rooms.map((room) => room.id);
        let roomIndex = roomsIds.indexOf(message.room);
        const messagesIds = chatStore.getMessages(roomIndex).map((m) => m.id);
        if (messagesIds.indexOf(message.id) === -1) {
            chatStore.addMessage(roomIndex, message);
        }
    }

    const renderMessages = () => {
        // CHECK CONDITION
        // const messageFilter = userStore.isFreelancer ? (message, messageIndex) => messageIndex > -1 : (message, messageIndex) => messageIndex > 0;
        const messageFilter = task.created_user?.id === userStore.user.id ? (message, messageIndex) => messageIndex > 0 : (message, messageIndex) => messageIndex > -1;
        return chatStore.getMessages(index)
            .filter(messageFilter)
            .map((message, i) => 
                <div key={i} className="message">
                    <div className="message-block">
                        
                            <div className="user-img">
                                {message.author === chatStore.rooms[index].responded_user.id ? (
                                    chatStore.rooms[index].responded_user.image ? (
                                        <Link to={`/profile/${chatStore.rooms[index].responded_user.id}`}>
                                            <img src={config.strapi.image_url + '/' + chatStore.rooms[index].responded_user.image} />
                                        </Link>
                                    ) : (
                                        <Link to={`/profile/${chatStore.rooms[index].responded_user.id}`}>
                                            <img src={ProfilePlaceholder} />
                                        </Link>
                                    )
                                ) : (
                                    task.created_user?.image ? (
                                        <Link to={`/profile/${task.created_user?.id}`}>
                                            <img src={config.strapi.image_url + '/' + `${task.created_user?.image}`} />
                                        </Link>
                                    ) : (
                                        <Link to={`/profile/${task.created_user?.id}`}>
                                            <img src={ProfilePlaceholder} />
                                        </Link>
                                    )
                                )}
                            </div>
                        <div className="message-content">
                            <div className="name-online-datetime">
                                <div className="name">
                                    {message.author === chatStore.rooms[index].responded_user.id ? (
                                        <Link to={`/profile/${chatStore.rooms[index].responded_user.id}`}>
                                            {`${chatStore.rooms[index].responded_user.first_name} ${chatStore.rooms[index].responded_user.last_name}`}
                                        </Link>
                                    ) : (
                                        <Link to={`/profile/${task.created_user?.id}`}>
                                            {`${task.created_user?.first_name} ${task.created_user?.last_name}`}
                                        </Link>
                                    )}
                                </div>
                                <div className="online">
                                    <img src={UserStatusGreySvg} />
                                </div>
                                <div className="datetime">
                                    {formatDate(new Date(message.created))}
                                </div>
                            </div>
                            <div className="message-text">
                                {message.content}
                                {/* ADD LINKS */}
                                {message.document_urls && message.document_urls.length > 0 &&
                                    <div className="files">
                                        <AttachedFilesFromUrlsHorizontal document_urls={message.document_urls} />
                                    </div>
                                }
                                {message.urls && message.urls.length > 0 &&
                                    <div className="urls">
                                        <AttachedLinks urls={message.urls} />
                                    </div>   
                                }
                            </div>
                        </div>
                    </div>
                </div>
        );
    }

    const firstMessage = () => {
        let message = chatStore.getMessages(index)[0];
        return (
            <>
                {message?.content && message.content}
                {message?.document_urls && message.document_urls.length > 0 &&
                    <div className="files">
                        <AttachedFilesFromUrlsHorizontal document_urls={message.document_urls} />
                    </div>
                }
                {message?.urls && message.urls.length > 0 &&
                    <div className="urls">
                        <AttachedLinks urls={message.urls} />
                    </div>   
                }
            </>
        );
    }

    // file related
    // Для ссылки на <input type="file" />
    const hiddenFileInput = useRef(null);

    const handleSelectFilesClick = (e) => {
        hiddenFileInput.current.click();
    };
    const selectFiles = (e) => {
        const newFiles = Array.from(e.target.files);
        const array = [...newFiles, ...selectedFiles];
        setSelectedFiles(array.slice(0,5));
        if (previewFilesElement.current) {
            previewFilesElement.current.addFiles(newFiles);
        }
    }
    const removeFileCallback = (array) => {
        setSelectedFiles(array);
    }

    return (
        <div className="chat-component">
            {assigned && 
                <div className="assigned-header">
                    <div className="assigned-header-title">
                        {task.created_user?.id === userStore.user.id ? (
                            'Чат с исполнителем'
                        ) : (
                            'Чат с заказчиком'
                        )}
                    </div>
                    <div className="assigned-header-geodata-time">
                        {task.created_user?.id === userStore.user.id ? (
                            `${freelancerTimeUTC ? freelancerTimeUTC : ''} ${chatStore.rooms[index].responded_user.geodata?.label && chatStore.rooms[index].responded_user.geodata.label}`
                        ) : (
                            `${managerTimeUTC ? managerTimeUTC : ''} ${task.created_user.geodata?.label && task.created_user.geodata?.label}`
                        )}
                    </div>
                </div>
            }
            <div className="header">
                <div className="user-info">
                    <div className="user-img">
                        {task?.created_user?.id === userStore.user?.id ? (
                            <Link to={`/profile/${chatStore.rooms[index].responded_user.id}`}>
                                <img src={chatStore.rooms[index].responded_user.image ? config.strapi.image_url + '/' + chatStore.rooms[index].responded_user.image : ProfilePlaceholder} />
                            </Link>
                        ) : (
                            <Link to={`/profile/${task?.created_user?.id}`}>
                                <img src={task.created_user?.image ? config.strapi.image_url + '/' + task.created_user?.image : ProfilePlaceholder} />
                            </Link>
                        )}
                    </div>
                    <div className="user-name-profession-rating">
                        <div className="name">
                            {task?.created_user?.id === userStore.user.id ? (
                                <Link to={`/profile/${chatStore.rooms[index].responded_user.id}`}>
                                    {`${chatStore.rooms[index].responded_user.first_name} ${chatStore.rooms[index].responded_user.last_name}`}
                                </Link>
                            ) : (
                                <Link to={`/profile/${task?.created_user?.id}`}>
                                    {`${task.created_user?.first_name} ${task.created_user?.last_name}`}
                                </Link>
                            )}
                        </div>
                        <div className="profession-rating">
                            <div className="profession">
                                {task?.created_user?.id === userStore.user.id ? (
                                    `${chatStore.rooms[index].responded_user.specialization ? chatStore.rooms[index].responded_user.specialization : 'Исполнитель'}`  
                                ) : (
                                    'Заказчик'
                                )}
                            </div>
                            <div className="rating">
                                <img src={StarSvg} />
                                <span>
                                    {task?.created_user?.id === userStore.user.id ? (
                                        `${chatStore.rooms[index].responded_user?.rating.toFixed(1)}`  
                                    ) : (
                                        `${task.created_user?.rating.toFixed(1)}`
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="user-status">
                    <div className="user-status-icon">
                        <img src={UserStatusGreenSvg} />
                    </div>
                    <div className="user-status-text">Свободен</div>
                </div>
            </div>
            {/* CHECK CONDITION */}
            {userStore.user && task?.created_user?.id === userStore.user.id &&
                <div className="first-message">
                    {firstMessage()}
                </div>
            }
            {userStore.user && !task.assigned_user && task.created_user?.id === userStore.user.id &&
                <div className="accept-decline-block">
                    <button
                        className="decline"
                        onClick={() => modal.showModal(
                            <DeclineFreelancer taskId={task.id} declinedUser={chatStore.rooms[index].responded_user} taskName={task.title} callGetTask={callGetTask} />
                        )}
                    >
                        Отклонить
                    </button>
                    <button
                        className="accept"
                        onClick={() => modal.showModal(
                            <HireFreelancer taskId={task.id} assignedUser={chatStore.rooms[index].responded_user} taskName={task.title} callGetTask={callGetTask} />
                        )}
                    >
                        Нанять
                    </button>
                </div>
            }
            <hr className={(task?.created_user?.id === userStore.user.id && chatStore.getMessages(index).length === 1) ? 'bottom-zero' : ''} />
            <div className="chat-history">
                {/* <ul ref={(el) => { messagesEnd = el; }}> */}
                <>
                { 
                    renderMessages() 
                }
                </>
                {/* </ul> */}
            </div>
            <div className="message-form">
                <form onSubmit={handleSubmit(onSubmit)} className='form'>
                    {task?.created_user?.id === userStore.user.id ? (
                        <img className="user-img" src={task.created_user?.image ? config.strapi.image_url + '/' + task.created_user?.image : ProfilePlaceholder} />
                    ) : (
                        <img className="user-img" src={chatStore.rooms[index].responded_user.image ? config.strapi.image_url + '/' + chatStore.rooms[index].responded_user.image : ProfilePlaceholder} />
                    )}
                    <input
                        type='text'
                        {...register("message", { required: true })}
                        placeholder='Ваше сообщение...'
                    />
                    <button className="attachment" type="button" onClick={handleSelectFilesClick}>
                        <img src={AttachmentSvg} />
                    </button>
                    <button className="submit" type="submit">
                        <img src={ArrowUpSvg} />
                    </button>
                </form>
                <div className="selected-files-block">
                    <input
                        type="file"
                        name="filename"
                        multiple="multiple"
                        ref={hiddenFileInput}
                        onChange={selectFiles}
                        accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, vnd.openxmlformats-officedocument.presentationml.presentation"
                    />
                    { 
                        selectedFiles?.length > 0 && 
                        <div className="files-block">
                            <div className="files">
                                {
                                    <PreviewAttachedFiles ref={previewFilesElement} files={selectedFiles} onChangeCallback={removeFileCallback} />
                                }
                            </div>
                        </div> 
                    }
                </div>
            </div>
        </div>
    );
})

export { ChatComponent }