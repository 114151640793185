import './index.scss'
import { observer } from 'mobx-react-lite'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import MastercardIcon from '@assets/svg/balance-mastercard.svg'; 
import VisaIcon from '@assets/svg/balance-visa.svg';
import MirIcon from '@assets/svg/balance-mir.svg';
import FourDigitsIcon from '@assets/svg/four-digits.svg';
import { deleteCreditCard as deleteCreditCardApi } from '@api/user-api';
import CardRadioButtonActiveImg from '@assets/svg/card-radio-button-active.svg';
import { toast } from 'react-toastify';
import { toastOptions } from '@shared/index'

const cardType = (type) => {
    switch (type) {
        case 'mastercard':
            return MastercardIcon;
        case 'visa':
            return VisaIcon;
        case 'mir':
            return MirIcon;
    }
}

const validationSchema = Yup.object().shape({
    cardId: Yup.string()
      .required('Не указан ID карты')
});

const DeleteCardModal = observer(({card ,callback = null}) => {
    const { modal } = useContext(Context);
    
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue } = useForm(formOptions);

    useEffect(() => {
        if (card.id) {
            setValue('cardId', card.id);
        }
    }, [card]);

    const onSubmit = (data) => {
        console.log(data.cardId);

        deleteCreditCardApi(data.cardId)
        .then(() => {
            if (callback) {
                callback();
            }
            toast.success('Карта удалена', toastOptions);
            modal.closeModal();
        })
        .catch(() => {
            if (callback) {
                callback();
            }
            toast.error('Ошибка удаления карты', toastOptions);
            modal.closeModal();
        });
    }

    return (
        <div className="delete-card-modal">
            <div className="header-modal delete-card-header">
                Подтвердите удаление
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x" alt="close" />
                </div>
            </div>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control-block">
                    <label htmlFor="cardNumber" className="form-label">
                        Вы уверены, что хотите удалить следующую банковскую карту?
                    </label>
                </div>
                <div className="card-number">
                    <div className="radio-button">
                        <img alt="card radio button" src={CardRadioButtonActiveImg} />
                    </div>
                    <div className="card-type">
                        <img alt="card type" src={cardType(card.type)} />
                    </div>
                    <div className="dots">
                        <img alt="card dots" src={FourDigitsIcon} />
                        <img alt="card dots" src={FourDigitsIcon} />
                        <img alt="card dots" src={FourDigitsIcon} />
                    </div>
                    <div className="card-digits">
                        {card.last_4_digits}
                    </div>
                </div>
                <div className="actions">
                    <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                        Закрыть
                    </button>
                    <button type='submit' className="confirm">
                        Удалить карту
                    </button>
                </div>
            </form>
        </div>
    )
})

export { DeleteCardModal }