import './index.scss'
import { observer } from 'mobx-react-lite'
import { useContext, useState, useEffect, useRef, createRef, forwardRef } from 'react'
import { filterTasks, addWidthToTask2, getAllTasksCount as getAllTasksCountApi } from '@api/task-api'
import SearchImg from '@assets/svg/search-24.svg'
import CrossImg from '@assets/svg/cross.svg'
import XBlueImg from '@assets/svg/x-blue-cross.svg'
import SortingImg from '@assets/svg/sorting.svg'
import ArrowDownImg from '@assets/svg/arrow-down.svg'
import NotFoundImg from '@assets/svg/not-found.svg'
import { TaskTemplate } from '@Components/TaskTemplate'
import { Context } from '@/index'
import { tasksEndingOfTheWord, daysEndingOfTheWordFilter, getNextPageNumber } from '@utils/utils';
import Dropdown from 'react-bootstrap/Dropdown';

const PAGE_SIZE = 10;

const PROGRAMMING_LANGUAGES_COUNT = 30;

const sortByOptions = {
    'date': 'Дата размещения',
    'price': 'Стоимость',
    'rating': 'Рейтинг исполнителя',
    'queue': 'Количество заказов в очереди',
    'duration': 'Срок выполнения заказа'
}

const TasksPage = observer(() => {
    const { programmingLanguagesStore } = useContext(Context);
    const [ tasks, setTasks ] = useState([]);
    const [ searchText, setSearchText ] = useState('');
    const [ selectedTaskType, setSelectedTaskType ] = useState(0);
    const [ selectedminPrice, setSelectedMinPrice ] = useState('');
    const [ selectedmaxPrice, setSelectedMaxPrice ] = useState('');
    const [ selectedminDeadline, setSelectedMinDeadline ] = useState('');
    const [ selectedmaxDeadline, setSelectedMaxDeadline ] = useState('');
    const [ selectedProgrammingLanguages, setSelectedProgrammingLanguages ] = useState([]);
    const [ totalTasksCount, setTotalTasksCount ] = useState('');
    const [ dateSort, setDateSort ] = useState(false);
    const [ bottomCount, setBottomCount ] = useState(1);
    const [ nextPageNumber, setNextPageNumber] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const programmingLanguagesRef = useRef([...Array(PROGRAMMING_LANGUAGES_COUNT)].map(() => createRef()));
    const [ selectedSortBy, setSelectedSortBy ] = useState('date');

    const CustomSortToggle = forwardRef(({ children, onClick }, ref) => (
        <div
            className="custom-sort-toggle-icon"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(e);
            }}
        >
            {children}
        </div>
      ));

    const clearFilters = () => {
        setSearchText('');
        setSelectedTaskType(0);
        setSelectedMinPrice('');
        setSelectedMaxPrice('');
        setSelectedMinDeadline('');
        setSelectedMaxDeadline('');
        setSelectedProgrammingLanguages([]);
        programmingLanguagesRef.current.forEach((item) => {
            item.current.checked = false;
        });
        getFilteredTasks('', '', '', '', '', [], [], 0, false, null, PAGE_SIZE);
    }

    useEffect(() => {
        const getTasksF = async () => {
            getFilteredTasks(searchText, selectedminPrice, selectedmaxPrice, selectedminDeadline, selectedmaxDeadline, selectedProgrammingLanguages, [], selectedTaskType, dateSort, nextPageNumber, PAGE_SIZE);
        }
        getTasksF();
        getAllTaskCount();
    }, []);

    useEffect(() => {
        if(nextPageNumber > 1) {
            getFilteredTasks(searchText, selectedminPrice, selectedmaxPrice, selectedminDeadline, selectedmaxDeadline, selectedProgrammingLanguages, [], selectedTaskType, dateSort, nextPageNumber, PAGE_SIZE);
        }
    }, [bottomCount]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if ((window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight) {
            setBottomCount(prevBottomCount => {
                let newBottomCount = prevBottomCount + 1;
                return newBottomCount;
            });
        }
    };

    const getAllTaskCount = () => {
        getAllTasksCountApi()
        .then((res) => {
            setTotalTasksCount(res.data);
        })
        .catch(() => {

        })
    }

    const handleDateSort = () => {
        setBottomCount(1);
        setNextPageNumber(null);
        setTasks([]);
        let newDateSort = !dateSort;
        setDateSort((prevState) => {
            return !prevState;
        });
        getFilteredTasks(searchText, selectedminPrice, selectedmaxPrice, selectedminDeadline, selectedmaxDeadline, selectedProgrammingLanguages, [], selectedTaskType, newDateSort, null, PAGE_SIZE);
    }

    const handleChange = ({
        str, minPrice, maxPrice, minDeadline, maxDeadline, taskType, sortBy
    }) => {
        setIsLoading(true);
        setBottomCount(1);
        setNextPageNumber(null);
        setTasks([]);
        if (str == undefined) {
            str = searchText;
        } else {
            setSearchText(str);
        }
        if (minPrice == undefined) {
            minPrice = selectedminPrice;
        } else {
            setSelectedMinPrice(minPrice);
        }
        if (maxPrice == undefined) {
            maxPrice = selectedmaxPrice;
        } else {
            setSelectedMaxPrice(maxPrice);
        }
        if (minDeadline == undefined) {
            minDeadline = selectedminDeadline * 24;
        } else {
            setSelectedMinDeadline(minDeadline);
        }
        if (maxDeadline == undefined) {
            maxDeadline = selectedmaxDeadline * 24;
        } else {
            setSelectedMaxDeadline(maxDeadline);
        }
        if (taskType == undefined) {
            taskType = selectedTaskType;
        } else {
            setSelectedTaskType(taskType);
        }
        if (sortBy == undefined) {
            sortBy = selectedSortBy;
        } else {
            setSelectedSortBy(sortBy);
        }
        
        getFilteredTasks(str, minPrice, maxPrice, minDeadline, maxDeadline, selectedProgrammingLanguages, [], taskType, dateSort, null, PAGE_SIZE);
    }

    const selectProgrammingLanguage = (category) => {
        setBottomCount(1);
        setNextPageNumber(null);
        setTasks([]);
        let programmingLanguages = [];
        if (selectedProgrammingLanguages.includes(category)) {
            programmingLanguages = selectedProgrammingLanguages.filter(v => {
                return v != category;
            });
            setSelectedProgrammingLanguages(programmingLanguages);
        } else {
            selectedProgrammingLanguages.push(category);
            programmingLanguages = [...selectedProgrammingLanguages];
            setSelectedProgrammingLanguages(programmingLanguages);
        }
        getFilteredTasks(searchText, selectedminPrice, selectedmaxPrice, selectedminDeadline, selectedmaxDeadline, programmingLanguages, [], selectedTaskType, dateSort, null, PAGE_SIZE);
    }

    const getFilteredTasks = (str, minPrice, maxPrice, minDeadline, maxDeadline, programmingLanguages, [], taskType, dateSort, pageNumber, pageSize) => {
        setIsLoading(true);
        filterTasks(str, minPrice, maxPrice, minDeadline, maxDeadline, programmingLanguages, [], taskType, dateSort, pageNumber, pageSize)
        .then((res) => {
            setNextPageNumber(getNextPageNumber(res.data.next));
            addWidthToTask2(res.data);
            setTasks((prevTasks) => [...prevTasks, ...res.data.results]);
            setIsLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
    }

    return (
        <div className="tasks-page">
            <div className="container">
                <div className="row search-filter">
                    <div className="col-md-12">
                        <div className="input-group">
                            <span className="input-group-text search-icon-input" id="basic-addon1">
                                <img src={SearchImg} />
                            </span>
                            <input type="text" className="form-control" placeholder="Что будем искать?" value={searchText} 
                                onChange={ e => handleChange({ str: e.target.value })}
                            />
                            {searchText && 
                                <span className="input-group-text clear-search-input" id="basic-addon2"
                                    onClick={(e) => {
                                        handleChange({ str: '' });
                                    }}
                                >   
                                    <img src={CrossImg} />
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <div className={(selectedProgrammingLanguages.length > 0 || selectedminPrice || selectedmaxPrice || selectedminDeadline || selectedmaxDeadline) ? 'selected-filters-block' : 'selected-filters-block-empty'}>
                    {
                        selectedProgrammingLanguages.map((pl) => {
                            return (
                                <button
                                    type="button"
                                    className="button"
                                    key={pl}
                                    onClick={() => {
                                        selectProgrammingLanguage(pl);
                                        let plItem = programmingLanguagesStore.items.find((item) => item.name === pl);
                                        programmingLanguagesRef.current[plItem.id-1].current.checked = false;
                                    }}
                                >
                                    {pl}
                                    <img src={XBlueImg} className="x-mark"/>
                                </button>
                            )
                        })
                    }
                    {selectedminPrice &&
                        <button
                            type="button"
                            className="button"
                            onClick={() => {
                                handleChange({ minPrice: '' });
                            }}
                        >
                            {`от ${selectedminPrice} ₽`}
                            <img src={XBlueImg} className="x-mark"/>
                        </button>
                    }
                    {selectedmaxPrice &&
                        <button
                            type="button"
                            className="button"
                            onClick={() => {
                                handleChange({ maxPrice: '' });
                            }}
                        >
                            {`до ${selectedmaxPrice} ₽`}
                            <img src={XBlueImg} className="x-mark"/>
                        </button>
                    }
                    {selectedminDeadline &&
                        <button
                            type="button"
                            className="button"
                            onClick={() => {
                                handleChange({ minDeadline: '' })
                            }}
                        >
                            {`от ${selectedminDeadline} ${daysEndingOfTheWordFilter(selectedminDeadline)}`}
                            <img src={XBlueImg} className="x-mark"/>
                        </button>
                    }
                    {selectedmaxDeadline &&
                        <button
                            type="button"
                            className="button"
                            onClick={() => {
                                handleChange({ maxDeadline: '' })
                            }}
                        >
                            {`от ${selectedmaxDeadline} ${daysEndingOfTheWordFilter(selectedmaxDeadline)}`}
                            <img src={XBlueImg} className="x-mark"/>
                        </button>
                    }
                </div>
                <div className="main-second row">
                    <div className="col-lg-3 filter-block">
                        <div className="margin-top-12">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
                                        checked={selectedTaskType == 0 ? true : false}
                                        onChange={ () => handleChange({ taskType: 0 })}/>
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Все
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" 
                                        checked={selectedTaskType == 1 ? true : false}
                                        onChange={ () => handleChange({ taskType: 1 })} />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    Заказы
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" 
                                        checked={selectedTaskType == 2 ? true : false}
                                        onChange={ () => handleChange({ taskType: 2 })}/>
                                <label className="form-check-label" htmlFor="flexRadioDefault3">
                                    Услуги
                                </label>
                            </div>
                        </div>
                        <div className="filter-header">
                            Стоимость
                        </div>
                        <div className="input-group margin-top-16">
                            <span className="input-group-text" id="cost-from-addon">
                                От
                            </span>
                            <input type="number" min="0" className="form-control" placeholder="1000 ₽" aria-label="1000 ₽" aria-describedby="cost-from-addon"
                                value={selectedminPrice}
                                onChange={ e => handleChange({ minPrice: e.target.value })}
                            />
                            {selectedminPrice && 
                                <span className="input-group-text clear-input"
                                    onClick={(e) => {
                                        handleChange({ minPrice: '' });
                                    }}
                                >   
                                    <img src={CrossImg} />
                                </span>
                            }
                        </div>
                        <div className="input-group margin-top-8">
                            <span className="input-group-text" id="cost-from-addon">
                                До
                            </span>
                            <input type="number" min="0" className="form-control" placeholder="1 000 000 ₽" aria-label="1 000 000 ₽" aria-describedby="cost-from-addon"
                                value={selectedmaxPrice}
                                onChange={ e => handleChange({ maxPrice: e.target.value })}
                            />
                            {selectedmaxPrice && 
                                <span className="input-group-text clear-input"
                                    onClick={(e) => {
                                        handleChange({ maxPrice: '' });
                                    }}
                                >   
                                    <img src={CrossImg} />
                                </span>
                            }
                        </div>
                        <div className="filter-header">
                            Срок выполнения
                        </div>
                        <div className="input-group margin-top-16">
                            <span className="input-group-text" id="cost-from-addon">
                                От
                            </span>
                            <input type="text" className="form-control" placeholder="1 дня" aria-label="1 дня" aria-describedby="cost-from-addon"
                                value={selectedminDeadline}
                                onChange={ e => handleChange({ minDeadline: e.target.value })}
                            />
                            {selectedminDeadline && 
                                <span className="input-group-text clear-input"
                                    onClick={(e) => {
                                        handleChange({ minDeadline: '' });
                                    }}
                                >   
                                    <img src={CrossImg} />
                                </span>
                            }
                        </div>
                        <div className="input-group margin-top-8">
                            <span className="input-group-text" id="cost-from-addon">
                                До
                            </span>
                            <input type="text" className="form-control" placeholder="14 дней" aria-label="14 дней" aria-describedby="cost-from-addon"
                                value={selectedmaxDeadline}
                                onChange={ e => handleChange({ maxDeadline: e.target.value })}
                            />
                            {selectedmaxDeadline && 
                                <span className="input-group-text clear-input"
                                    onClick={(e) => {
                                        handleChange({ maxDeadline: '' });
                                    }}
                                >   
                                    <img src={CrossImg} />
                                </span>
                            }
                        </div>
                        <div className="filter-header">
                            Язык
                        </div>
                        <div className="margin-top-16">
                        {
                            programmingLanguagesStore.items.map((pl) => {
                                return (
                                    <div className="form-check" key={pl.id}>
                                        <input className="form-check-input" type="checkbox" value={pl.id} id={pl.id} 
                                            ref={programmingLanguagesRef.current[pl.id-1]}
                                            onChange={ () => {selectProgrammingLanguage(pl.name)}}
                                        />
                                        <div className="d-flex justify-content-between">
                                            <label className="form-check-label" htmlFor={pl.id}>
                                                {pl.name}
                                            </label>
                                            <label className="form-check-label gray" htmlFor={pl.id}>
                                                {pl.total_tasks}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })
                        }
                            
                        </div>


                        <div className="filter-header">
                            Уровень квалификации
                        </div>
                        <div className="margin-top-16">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value=""/>
                                <div className="d-flex justify-content-between">
                                    <label className="form-check-label">
                                        Junior
                                    </label>
                                    <label className="form-check-label gray">
                                        3
                                    </label>
                                </div>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value=""/>
                                <div className="d-flex justify-content-between">
                                    <label className="form-check-label">
                                        Middle
                                    </label>
                                    <label className="form-check-label gray">
                                        3
                                    </label>
                                </div>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value=""/>
                                <div className="d-flex justify-content-between">
                                    <label className="form-check-label">
                                        Middle+/Senior
                                    </label>
                                    <label className="form-check-label gray">
                                        3
                                    </label>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-9">
                        <div className="row justify-content-between main-second-info">
                            <div className="col-lg-6">
                                <div className="total-tasks">
                                    Всего на devssquad {totalTasksCount >= 0 && `${totalTasksCount} ${tasksEndingOfTheWord(totalTasksCount)} `}
                                </div>
                            </div>
                            <div className="col-lg-6 text-end">
                                <div
                                    className="date-ascending"
                                >
                                    <img
                                        onClick={handleDateSort}
                                        src={SortingImg} className="date-ascending-image"
                                    />
                                    {sortByOptions[selectedSortBy]}
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomSortToggle} id="dropdown-custom-components">
                                            <img src={ArrowDownImg} className="date-ascending-image"/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                active={selectedSortBy === 'date' ? true : false}
                                                onClick={
                                                    () => {
                                                        handleChange({ sortBy: 'date'});
                                                    }
                                                }
                                            >
                                                Дата размещения
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                active={selectedSortBy === 'price' ? true : false}
                                                onClick={
                                                    () => {
                                                        handleChange({ sortBy: 'price'});
                                                    }
                                                }
                                            >
                                                Стоимость
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                active={selectedSortBy === 'rating' ? true : false}
                                                onClick={
                                                    () => {
                                                        handleChange({ sortBy: 'rating'});
                                                    }
                                                }
                                            >
                                                Рейтинг исполнителя
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                active={selectedSortBy === 'queue' ? true : false}
                                                onClick={
                                                    () => {
                                                        handleChange({ sortBy: 'queue'});
                                                    }
                                                }
                                            >
                                                Количество заказов в очереди
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                active={selectedSortBy === 'duration' ? true : false}
                                                onClick={
                                                    () => {
                                                        handleChange({ sortBy: 'duration'});
                                                    }
                                                }
                                            >
                                                Срок выполнения заказа
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        {tasks.length > 0 || isLoading ? (
                            tasks.map((item, key) => {
                                return (
                                    <div className={`col-lg-${item.width}`} key={key}>
                                        {/* {item.Title} */}
                                        <TaskTemplate task={item}></TaskTemplate>
                                    </div>
                                )
                            })
                        ) : (
                            <div className="not-found">
                                <div className="not-found-image">
                                    <img src={NotFoundImg} alt="not found" />
                                </div>
                                <div className="not-found-title">
                                    Ничего не смогли найти по вашему запросу
                                </div>
                                <div className="not-found-description">
                                    Давайте попробуем изменить поисковый запрос или настройки фильтров?
                                </div>
                                <div className="not-found-clear-filters">
                                    <button
                                        className="clear-filters-button"
                                        onClick={clearFilters}
                                    >
                                        Сбросить фильтры
                                    </button>
                                </div>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export { TasksPage }