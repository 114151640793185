import { makeAutoObservable } from 'mobx'

export default class ChatStore {
  constructor() {
    this._rooms =  [];
    makeAutoObservable(this);
  }

  get rooms() {
    return this._rooms;
  }
  
  setRooms(rooms) {
    this._rooms = rooms;
  }

  getMessages(i) {
    return this._rooms[i].messages || [];
  }

  setMessages(i, messages) {
    this._rooms[i].messages = messages;
  }

  addMessage(i, message) {
    this._rooms[i].messages.push(message);
  }
}
