import { observer } from 'mobx-react-lite';
import './index.scss';
import AttachmentIcon from '@assets/svg/attachment.svg';
import AddIcon from '@assets/svg/add.svg';
import XMini from '@assets/svg/x-mini.svg';
import { useState, useRef, useEffect } from 'react';
import { PreviewAttachedFiles } from '@Components/PreviewAttachedFiles';

const FILES_SIZE_LIMIT = 1000 * 1024;

const AttachFilesAndLinks = observer(({
    files = [],
    links = [],
    onChangeCallback,
    label = "Прикрепите портфолио или ссылки на дополнительные материалы",
    editMode = false,
}) => {
    const [ selectedFiles, setSelectedFiles ] = useState(files);
    const [ selectedLinks, setSelectedLinks ] = useState(links);
    const [ isSizeLimit, setIsSizeLimit ] = useState(false);
    const previewFilesElement = useRef();

    useEffect(() => {
        if(editMode) {
            files.forEach((file) => {
                selectFiles([file]);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, editMode]);
    const checkFilesSizeLimit = (files) => {
        let filesSize = files
            .map((file) => { return file.size; })
            .reduce((sum, fileSize) => { return sum + fileSize; }, 0);
        filesSize > FILES_SIZE_LIMIT ? setIsSizeLimit(true) : setIsSizeLimit(false);
        return filesSize > FILES_SIZE_LIMIT;
    }
    const selectFiles = (files) => {
        const newFiles = Array.from(files);
        const array = [...newFiles, ...selectedFiles];
        setSelectedFiles(array.slice(0,5));
        onChangeCallback(array, selectedLinks, checkFilesSizeLimit(array));
        if (previewFilesElement.current) {
            previewFilesElement.current.addFiles(newFiles);
        }
    }
    const removeFileCallback = (array) => {
        setSelectedFiles(array);
        onChangeCallback(array, selectedLinks, checkFilesSizeLimit(array));
    }
    const addLink = () => {
        if (selectedLinks.length < 5) {
            const array = [...selectedLinks, ''];
            setSelectedLinks(array);
            onChangeCallback(selectedFiles, array, isSizeLimit);
        }
    }
    const removeLinkHandler = (e) => {
        const fileId = e.target.getAttribute('id');
        const array = [...selectedLinks];
        array.splice(fileId, 1);
        setSelectedLinks(array);
        onChangeCallback(selectedFiles, array, isSizeLimit);
    }
    const changeLink = (index, value) => {
        const array = [...selectedLinks];
        array[index] = value;
        setSelectedLinks(array);
        onChangeCallback(selectedFiles, array, isSizeLimit);
    }

    // Для ссылки на <input type="file" />
    const hiddenFileInput = useRef(null);

    const handleSelectFilesClick = (e) => {
        hiddenFileInput.current.click();
    };


    return (
        <div className="attach-files-and-links-component">
            <div className="form-control-attachments-and-links-block">
                <label htmlFor="description" className="form-label">
                    { label }
                </label>
                <div className="attachments-and-links-buttons">
                    <div className="selected-files-block">
                        <button type="button" className="button" onClick={handleSelectFilesClick}>
                            <img src={AttachmentIcon} alt="attachment"/>
                            Прикрепить файлы
                        </button>
                        <input
                            type="file"
                            name="filename"
                            multiple="multiple"
                            ref={hiddenFileInput}
                            onChange={(e) => { selectFiles(e.target.files) }}
                            accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, vnd.openxmlformats-officedocument.presentationml.presentation"
                        />
                        { 
                            selectedFiles?.length > 0 && 
                            <div className="files-block">
                                <div className={`${isSizeLimit ? 'files-count-size-error' : 'files-count'}`}>
                                    {isSizeLimit && <span className="size-error">Объём файлов не может превышать 1MB</span>}<span>{selectedFiles.length}/5</span>
                                </div>
                                <div className="files">
                                    {
                                        <PreviewAttachedFiles ref={previewFilesElement} files={selectedFiles} onChangeCallback={removeFileCallback} />
                                    }
                                </div>
                            </div> 
                        }
                    </div>
                    <div className="selected-links-block">
                        <button type="button" className="button" onClick={addLink}>
                            <img src={AddIcon} alt="add"/>
                            Добавить ссылку
                        </button>
                        { 
                            selectedLinks?.length > 0 && 
                            <div className="links-block">
                                <div className="links-count">
                                    <span>{selectedLinks.length}/5</span>
                                </div>
                                <div className='links'>
                                    {
                                        selectedLinks?.map((link, index) =>
                                            <div key={`link_${index}`} className="link">
                                                <input placeholder="http://" id={`link_${index}`} value={link} onChange={(e) => {changeLink(index, e.target.value)}} />
                                                {
                                                    selectedLinks?.length === index + 1 && 
                                                    <button id={index} className="remove-link-block" onClick={removeLinkHandler}>
                                                        <img id={index} src={XMini} alt="remove" />
                                                    </button> 
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div> 
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})

export { AttachFilesAndLinks }