import './index.scss'
import { observer } from 'mobx-react-lite'
import DoubleTick from '@assets/svg/header/notifications/double-tick.svg';
import NotificationUserOne from '@assets/img/header/notifications/userAvatarOne.png';
import NotificationUserTwo from '@assets/img/header/notifications/userAvatarTwo.png';
import NotificationUserThree from '@assets/img/header/notifications/userAvatarThree.png';

const exampleNotification = [
    {
        id: 1,
        time: '23:15',
        img: NotificationUserOne,
        name: 'Александра Петрова',
        text: 'откликнулась на Ваш заказ'
    },
    {
        id: 2,
        time: '23:15',
        img: NotificationUserTwo,
        name: 'Андрей Синявин',
        text: 'написал Вам личное сообщение'
    },
    {
        id: 3,
        time: '23:15',
        img: NotificationUserThree,
        name: 'Сергей Воробьев',
        text: 'откликнулся на Ваш заказ'
    },
];

const NotificationPage = observer(() => {
    return (
        <div className="notification-page">
            <div className="title">
                Уведомления
            </div>
            <div className="read-all-block">
                <div className="read-all">
                    <img src={DoubleTick} /><span>Прочитать всё</span>
                </div>
            </div>
            <div className="day">
                <div className="day-title">Четверг, 11 августа, 2022</div>
                <div className="notifications">
                    {exampleNotification && exampleNotification.map((notification, index) =>
                        <div key={`notification_${index}`} className="notification">
                            <div className="time">{notification.time}</div>
                            <div className="user-image">
                                <img src={notification.img} />
                            </div>
                            <div className="message">
                                <span className="name">{notification.name}</span>{' '}
                                <span>{notification.text}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="day">
                <div className="day-title">Среда, 10 августа, 2022</div>
                <div className="notifications">
                    {exampleNotification && exampleNotification.map((notification, index) =>
                        <div key={`notification_${index}`} className="notification">
                            <div className="time">{notification.time}</div>
                            <div className="user-image">
                                <img src={notification.img} />
                            </div>
                            <div className="message">
                                <span className="name">{notification.name}</span>{' '}
                                <span>{notification.text}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
})

export {NotificationPage}