import './index.scss'
import LogoImg from '@assets/svg/logo.svg'
import MastercardImg from '@assets/svg/mastercard.svg'
// import VisaImg from '@assets/svg/visa.svg'
import VisaImg from '@assets/svg/visaLogoNew.svg'
import MirImg from '@assets/svg/mir.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
 return (
  <footer className="footer">
   <div className="container">
    <div className="image-block">
     <img src={LogoImg} className="logo-img" />
    </div>
    <div className="links">
     <span className="link">
      <Link to="/service-rules" className="mylink">
       Правила сервиса
      </Link>
     </span>{' '}
     <span className="link">
      <Link to="/policy" className="mylink">
       Политика конфиденциальности
      </Link>
     </span>{' '}
     <span className="link">
      <Link to="/terms" className="mylink">
       Пользовательское соглашение
      </Link>
     </span>{' '}
     <span className="link">
      <Link to="/faq" className="mylink">
       FAQ
      </Link>
     </span>{' '}
     <span className="link">
      <Link to="/contacts" className="mylink">
       Контакты
      </Link>
     </span>{' '}
     <span className="link">© Devssquad, 2023</span>
    </div>
    <div className="footer-requisites">
     <p className="requisite-footer">ООО «Софтейл»</p>
     <p className="requisite-footer">ИНН: 9719033996</p>
     <p className="requisite-footer">КПП: 771901001</p>
     <p className="requisite-footer">ОГРН: 1227700848418</p>
    </div>
    <div className="payment-images">
     <img src={MastercardImg} className="payment-img" />
     <img src={VisaImg} className="payment-img-visa" />
     <img src={MirImg} className="payment-img" />
    </div>
   </div>
  </footer>
 )
}

export { Footer }
