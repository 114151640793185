import './index.scss';
import path from 'path'
import config from '@/config';
import { observer } from 'mobx-react-lite';
import XImg from '@assets/svg/x.svg';
import { Context } from '@/index';
import { useContext, useState, useRef, useEffect } from 'react';
import { AttachFilesAndLinks } from '@Components/AttachFilesAndLinks';
import { updatePortfolio as updatePortfolioApi, uploadPortfolioFiles as uploadPortfolioFilesApi, uploadPortfolioUrls as uploadPortfolioUrlsApi } from '@api/user-api';
// ----------------
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// ----------------
import { toast } from 'react-toastify';
import { toastOptions } from '@shared/index';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Введите название работы')
        .min(2, 'Название должно быть не менее 2 символов')
        .max(60, 'Название должно быть не более 60 символов'),
    description: Yup.string()
        .required('Введите описание')
        .min(16, 'Описание должно быть не менее 16 символов')             
        .max(1200, 'Описание должно быть не более 1200 символов'),               
})
const formOptions = { resolver: yupResolver(validationSchema), shouldUnregister: true }

const EditProjectsModal = observer(({ project, callback }) => {
    const { modal } = useContext(Context);
    const { register, watch, reset, handleSubmit, formState: { errors } } = useForm(formOptions);
    const [ nameCharacters,  setNameCharacters ] = useState(0);
    const [ descriptionCharacters,  setDescriptionCharacters ] = useState(0);
    const [ isDisabled, setIsDisabled] = useState(false);
    const [ attachmentFiles, setAttachmentFiles ] = useState([]);

    const selectedFilesRef = useRef([]);
    const selectedLinksRef = useRef([]);
    const [ isUploading, setIsUploading ] = useState(false);
    const buttonSpinner = useRef(null);

    useEffect(() => {
        if(isUploading) {
            setIsDisabled(true);
            buttonSpinner.current.style.display = "inline-block";
        } else {
            setIsDisabled(false);
            buttonSpinner.current.style.display = "none";
        }
    }, [isUploading]);

    const onFilesOrLinksChange = (files, links, isSizeLimit) => {
        selectedFilesRef.current = files;
        selectedLinksRef.current = links;
        setIsDisabled(isSizeLimit);
    }

    useEffect(() => {
        const subscription = watch((value) => {
            let { name, description } = value;
            if (name || description) {
                setNameCharacters(name.length);
                setDescriptionCharacters(description.length);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        console.log(project);
        project.document_urls.map((documentUrl) => {
            fetch( `${config.strapi.image_url}${documentUrl}`)
                .then(res => { return res.blob(); })
                .then(blob => {
                    let file = new File([blob], path.basename(documentUrl), { type: blob.type });
                    setAttachmentFiles(files => [...files, file]);
                });
        });
        let defaultValues = {};
        defaultValues.name = project.name;
        defaultValues.description = project.description;
        reset({ ...defaultValues });
        setNameCharacters(project.name.length);
        setDescriptionCharacters(project.description.length);
    }, []);

    const onSubmit = (obj) => {
        setIsUploading(true);
        updatePortfolioApi(project.id, obj)
        .then( async (res) => {
            const [
                uploadPortfolioUrlsResult,
                uploadPortfolioFilesResult
            ] = await Promise.allSettled([
                (res && res.data && selectedLinksRef.current.length) ? uploadPortfolioUrlsApi({portfolio: res.data.id, url: selectedLinksRef.current}) : Promise.resolve(),
                (res && res.data && selectedFilesRef.current.length) ? uploadPortfolioFilesApi(res.data.id, selectedFilesRef.current) : Promise.resolve(),
            ]);
            if (uploadPortfolioUrlsResult.status === 'fulfilled' && uploadPortfolioUrlsResult.status === 'fulfilled') {
                toast.success('Проект сохранен в портфолио', toastOptions);
            } else {
                toast.error('Проект сохранен в портфолио с ошибками', toastOptions);
            }
            if (uploadPortfolioUrlsResult.status === 'rejected') {
                toast.error('Произошла ошибка добавления ссылок в проект', toastOptions);
            }
            if (uploadPortfolioFilesResult.status === 'rejected') {
                toast.error('Произошла ошибка добавления файлов в проект', toastOptions);
            }
            setIsUploading(false);
            callback();
            modal.closeModal();
        })
        .catch(() => {
            setIsUploading(false);
            callback();
            toast.error('Произошла ошибка добавления проекта в Портфолио', toastOptions);
            modal.closeModal();
        });
    }

    return (
        <div className="edit-projects-modal">
            <div className="header-modal">
                Редактировать работу в портфолио
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x"/>
                </div>
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control-block">
                    <label htmlFor="name" className="form-label">
                        Название работы
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="name" 
                        placeholder=""
                        {...register("name")}
                    />
                    <div className="tip">
                        {`${nameCharacters}/60`}
                    </div>
                    {errors.name?.message && (
                        <p className="error">{errors.name?.message}</p>
                    )}
                </div>
                <div className="form-control-block">
                    <label htmlFor="description" className="form-label">
                        Описание
                    </label>
                    <textarea
                        className="form-control"
                        id="description"
                        rows="2" 
                        placeholder="" 
                        {...register("description")}
                    >
                    </textarea>
                    <div className="tip">
                        {`${descriptionCharacters}/1200`}
                    </div>
                    {errors.description?.message && (
                        <p className="error">{errors.description?.message}</p>
                    )}
                </div>
                <AttachFilesAndLinks
                    onChangeCallback={onFilesOrLinksChange}
                    label="Прикрепите файлы или ссылки на дополнительные материалы"
                    files={attachmentFiles}
                    links={project.urls}
                    editMode={true}
                />
                <div className="actions">
                    <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                        Отменить
                    </button>
                    <button type="submit" className="my-button first-button" disabled={isDisabled}>
                        <span ref={buttonSpinner} className="button-custom-spinner spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Сохранить
                    </button>
                </div>
            </form>
        </div>
    )
})

export { EditProjectsModal }