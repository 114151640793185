import './index.scss';
import { observer } from 'mobx-react-lite';
import { TaskHistoryList } from '@Components/TaskHistoryList';
import { useState, useEffect, useContext } from 'react';
import { Context } from '@/index';
import { getTasksForProfile as getTasksForProfileApi, assignedToMeTasks as assignedToMeTasksApi } from '@api/task-api';

const TaskHistoryPage = observer(() => {
    const { userStore } = useContext(Context);
    const [ tasks, setTasks ] = useState([]);
    const [ taskStatus, setTaskStatus ] = useState('');

    useEffect(() => {
        getTasks();
    }, [taskStatus]);

    const getTasks = () => {
        console.log('getTasks');
        if (userStore.isFreelancer) {
            assignedToMeTasksApi(taskStatus, 1)
            .then((res) => {
                setTasks(res.data.results);
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
            getTasksForProfileApi(userStore.user.id, taskStatus, 1)
            .then((res) => {
                setTasks(res.data.results);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }

    const taskStatusOptions = [
        { label: 'Все', value: '' },
        { label: 'Новые', value: 'new' },
        { label: 'В работе', value: 'in_progress' },
        { label: 'Отданы на проверку', value: 'submission' },
        { label: 'Завершенные', value: 'done' },
        { label: 'Закрытые', value: 'closed' },
    ];

    return (
        <div className="task-history-page">
            <h1 className="title">
                {userStore.isFreelancer ? 'Взятые мной заказы' : 'Мои заказы'}
            </h1>
            <div className="row tabs">
                {taskStatusOptions.map((taskStatusOption, index) => {
                    return (
                        <div
                            key={index}
                            className={`tab col-md-2 ${taskStatus === taskStatusOption.value ? 'active' : ''}`}
                            onClick={() => {setTaskStatus(taskStatusOption.value)}}
                        >
                            {taskStatusOption.label}
                        </div>
                    );
                })}
            </div>
            <TaskHistoryList tasks={tasks} />
        </div>
    )
})

export { TaskHistoryPage }