import './index.scss'
import { observer } from 'mobx-react-lite'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext, useState } from 'react'
import { createReview as createReviewApi } from '@api/review-api';
import { StarRating } from '@Components/StarRating';

const FeedbackModal = observer(({ userId, reviewUserId, callback }) => {
    const { modal } = useContext(Context);
    const [ rating, setRating ] = useState(0);
    const [ description, setDescription ] = useState('');

    const createReview = () => {
        if (rating > 0) {
            const obj = {
                review_user: reviewUserId,
                rating: rating,
                description: description
            }
            createReviewApi(userId, obj)
            .then((res) => {
                callback();
                modal.closeModal();
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }
    
    return (
        <div className="feedback-modal">
            <div className="header-modal">
                Оставить отзыв
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x"/>
                </div>
            </div>
            <div className="subtitle">
                Оцените Ваш опыт работы с Заказчиком.
            </div>
            <StarRating rating={rating} setRating={setRating} />
            <div className="subtitle-textarea">
                Сообщение (опционально)
            </div>
            <div className="feedback">
                <textarea
                    placeholder="Отзывы оставляются по желанию, но они укрепляют доверие и дают возможность другим 
                    участникам сервиса узнать больше о будущем заказчике или исполнителе."
                    className="feedback-textarea"
                    value={description}
                    onChange={ e => setDescription(e.target.value)}
                />
            </div>
            <div className="actions">
                <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                    Закрыть
                </button>
                <button type='button' className="confirm" onClick={createReview}>
                    Отправить отзыв
                </button>
            </div>
        </div>
    )
})

export { FeedbackModal }