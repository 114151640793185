import './index.scss'
import { observer } from 'mobx-react-lite'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
// import MailIcon from '../../assets/img/contacts-mail.png'
import MailIconSvg from '../../assets/svg/contacts-mail.svg'
// import PhoneIcon from '../../assets/img/contacts-phone.png'
import PhoneIconSvg from '../../assets/svg/contacts-phone.svg'
import LogoVk from '../../assets/svg/logo-vk-24.svg'
import LogoTg from '../../assets/svg/logo-tg-24.svg'

import useWindowDimensions from '@hooks/useWindowDimensions'

const ContactsPage = observer(() => {
 const { width } = useWindowDimensions()
 return (
  <div className="contacts-page container">
   <h1 className="title">Контакты</h1>
   <div className="contacts">
    <div className="information">
     <div className="requisites">
      <h2>Реквизиты</h2>
      <p>
       {' '}
       <strong>ООО «Софтейл»</strong>
      </p>
      <p>ИНН: 9719033996</p>
      <p>КПП: 771901001</p>
      <p>ОГРН: 1227700848418</p>
     </div>
     <div className="address">
      <h2>Адрес</h2>
      <p>
       105187, Россия, г. Москва, Окружной пр-д, д. 15, к. 2, 5 этаж, пом. I,
       комната 27А
      </p>
     </div>
     <div className="feedback">
      <h2>Обратная связь</h2>
      <div className="email">
       <img src={MailIconSvg} />
       <a href="mailto:info@devssquad.com">info@devssquad.com</a>
      </div>
      <div className="phone">
       <img src={PhoneIconSvg} />
       <a href="tel:+79857387961" target="_blank">
        +7 (985) 738-79-61
       </a>
      </div>
      <div className="vk">
       <img src={LogoVk} />
       <a href="https://vk.com/devssquad" target="_blank">
        vk.com/devssquad
       </a>
      </div>
      <div className="tg">
       <img src={LogoTg} />
       <a href="https://t.me/devs_squad" target="_blank">
        t.me/devs_squad
       </a>
      </div>
     </div>
    </div>
    <div className="map">
     <YMaps>
      <Map
       width={`${width <= 767 ? (width <= 470 ? '240px' : '320px') : '512px'}`}
       height={`${width <= 767 ? (width <= 470 ? '240px' : '320px') : '512px'}`}
       defaultState={{ center: [55.788717, 37.741908], zoom: 16 }}
      >
       <Placemark geometry={[55.788717, 37.741908]} />
      </Map>
     </YMaps>
    </div>
   </div>
  </div>
 )
})

export { ContactsPage }
