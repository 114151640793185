import { observer } from 'mobx-react-lite'
import './index.scss'
import Modal from 'react-bootstrap/Modal';
import { Context } from '@/index'
import { useContext } from 'react'


const ModalComponent = observer(({ children }) => {
    const { modal } = useContext(Context)

    return (
        <Modal 
            show={modal.isOpen} 
            onHide={() => modal.closeModal()}
            size="lg"
            centered
        >
            <Modal.Body>{children}</Modal.Body>
        </Modal>
        
    )
})

export default ModalComponent