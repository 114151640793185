import './index.scss';
import { observer } from 'mobx-react-lite';
import XImg from '@assets/svg/x.svg';
import { Context } from '@/index';
import { useContext, useRef, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { SELECT_STYLES_PL } from '@shared/index';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Select from 'react-select';
import { updateProfile as updateProfileApi } from '@api/user-api';
import { toast } from 'react-toastify';
import { toastOptions } from '@shared/index'

const validationSchema = Yup.object().shape({
    programming_languages: Yup.array()
        .required('Выберите язвки программирования')              
})

const formOptions = { resolver: yupResolver(validationSchema), shouldUnregister: true }

const EditProgrammingLanguagesModal = observer(({ savedProgrammingLanguages = [], callback }) => {
    const { modal, programmingLanguagesStore } = useContext(Context);
    const [ programmingLanguages, setProgrammingLanguages ] = useState([]);
    const [menuIsOpen, setMenuIsOpen] = useState();
    const {
        register,
        unregister,
        // errors,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm(formOptions);

    const onSubmit = (data) => {
        const programmingLanguagesIds = data.programming_languages.map((item) => item.id);
        const updateProfile = { 
            programming_language: programmingLanguagesIds       
        };
        updateProfileApi(updateProfile) 
        .then(() => {
            callback();
            toast.success('Языки программирования успешно сохранены', toastOptions);
            modal.closeModal();
        }) 
        .catch(() => {
            callback();
            toast.error('Произошла ошибка изменения языков программирования', toastOptions);
            modal.closeModal();
        })
    }

    const onInputChange = (inputValue, { action, prevInputValue }) => {
        if (action === 'input-change') return inputValue;
        if (action === 'menu-close') {
            if (prevInputValue) setMenuIsOpen(true);
            else setMenuIsOpen(undefined);
        }
        return prevInputValue;
      };

    return (
        <div className="edit-programming-languages-modal">
            <div className="header-modal">
                Редактировать языки программирования
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x"/>
                </div>
            </div>
            <div className="subtitle">
                Укажите список языков программирования, разделяя их запятой
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="programming_languages"
                    control={control}
                    rules={{ required: true }}
                    render = {({ field: { onChange, onBlur, value, ref } })=> (
                        <Select
                            isMulti
                            fullWidth
                            isClearable
                            isSearchable
                            placeholder="Выберите языки программирования"
                            styles={SELECT_STYLES_PL}
                            options={programmingLanguagesStore.items}
                            defaultValue={savedProgrammingLanguages}
                            getOptionLabel={(t) => t.name}
                            getOptionValue={(t) => t.id}
                            onBlur={onBlur}
                            onInputChange={onInputChange}
                            menuIsOpen={menuIsOpen}
                            onChange={(o) => {
                                setProgrammingLanguages(o);
                                onChange(o);
                            }}
                            isOptionDisabled={() => programmingLanguages.length >= 12}
                        />
                    )}
                />
                {errors.programming_languages?.message && (
                    <div className="error">{errors.programming_languages?.message}</div>
                )}
                <div className="tip">
                    Максимум 12 языков
                </div>
                <div className="actions">
                    <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                        Отменить
                    </button>
                    <button type='submit' className="confirm">
                        Сохранить
                    </button>
                </div>
            </form>
        </div>
    )
})

export { EditProgrammingLanguagesModal }