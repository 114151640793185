import { observer } from 'mobx-react-lite';
import './index.scss';
import ProfilePlaceholderImg from '@assets/img/profile-placeholder.jpg';
import StarImg from '@assets/svg/star.svg';
import { Context } from '@/index';
import { useParams, useLocation } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import config from '@/config';
import { ProfileReviewComponent } from '@Components/ProfileReviewComponent';
import { ProfileTasks } from '@Components/ProfileTasks';
import PencilBlackImg from '@assets/svg/pencil-black.svg';
import FileProjectImg from '@assets/svg/file-project.svg';
import LinkProjectImg from '@assets/svg/link-project.svg';
import { filesEndingOfTheWord, linksEndingOfTheWord } from '@utils/utils';
import { EditProgrammingLanguagesModal } from '@Components/EditProgrammingLanguagesModal';
import { EditSkillsModal } from '@Components/EditSkillsModal';
import { AddProjectsModal } from '@Components/AddProjectsModal';
import { EditProjectsModal } from '@Components/EditProjectsModal';
import { EditBioModal } from '@Components/EditBioModal';
import { EditExperienceModal } from '@Components/EditExperienceModal';
import { PortfolioProjectModal } from '@Components/PortfolioProjectModal';
import { DeletePortfolioProjectModal } from '@Components/DeletePortfolioProjectModal';
import { formatDateYear, profileTimeUTC, getNumberRatingReviews, isOdd } from '@utils/utils';
import { getUserInfo as getUserInfoApi } from '@api/user-api';

const ProfilePage = observer(() => {
    const { userStore, modal } = useContext(Context);
    const [ currentUserId, setCurrentUserId ] = useState(null);
    const [ userRole, setUserRole ] = useState('freelancer');
    const [ profileData, setProfileData ] = useState({});
    const [ timeUTC, setTimeUTC ] = useState(null);
    const [ portfoliosSlicer, setPortfoliosSlicer] = useState(0);
    let { id } = useParams();
    const location = useLocation();
    useEffect(() => {
        if(profileData?.geodata?.utc_offset || profileData?.geodata?.utc_offset === 0) {
            const interval = setInterval(() => {
                const newTime = profileTimeUTC(profileData.geodata.utc_offset);
                setTimeUTC(newTime);
            }, 1000);
          
            return () => {
                clearInterval(interval);
            };
        }
    }, [profileData.geodata?.utc_offset]);
    useEffect(() => {
        if (profileData?.portfolios) {
            let slicer = isOdd(profileData.portfolios.length) ? (profileData.portfolios.length - 1) / 2 + 1 : profileData.portfolios.length / 2;
            setPortfoliosSlicer(slicer);
        }
    }, [profileData.portfolios]);
    useEffect(() => {
        getUserInfo();
    }, [id]);

    useEffect(() => {
        if(userStore?.user?.id) {
            setCurrentUserId(Number(userStore.user.id));
        }
    }, [userStore?.user?.id]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let role = params.get('role');
        if (role) {
            switch (role) {
                case 'freelancer':
                    setUserRole('freelancer');
                    break;
                case 'manager':
                    setUserRole('manager');
                    break;
                default:
                    setUserRole('freelancer');
                    break;
            }
        }
    }, [location]);

    const getUserInfo = () => {
        getUserInfoApi(id)
        .then((res) => {
            console.log(res.data);
            setProfileData(res.data);
        })
        .catch((err) => {

        })
    }

    const projectTemplate = (project) => {
        return (
            <div
                className="project"
                key={`project-${project.id}`}
                onClick={
                    () => modal.showModal(<EditProjectsModal project={project} callback={getUserInfo} />)
                }
            >
                <div className="project-title-header">
                    <div className="project-title">
                        {project.name}
                    </div>
                    <div
                        className="remove-project"
                        onClick={(e) => {
                            e.stopPropagation();
                            modal.showModal(<DeletePortfolioProjectModal
                                project={project}
                                callback={getUserInfo}
                            />)
                        }}
                    >
                        <span className="bin-icon"></span>
                    </div>
                </div>
                <div className="project-description">
                    {project.description.length > 100 ? `${project.description.slice(0, 100)}...` : project.description}
                </div>
                <div className="project-files-links">
                    <div className="files-links">
                        <img src={FileProjectImg} />
                        {`${project.document_urls.length} ${filesEndingOfTheWord(project.document_urls.length)}`}
                    </div>
                    <div className="files-links">
                        <img src={LinkProjectImg} />
                        {`${project.urls.length} ${linksEndingOfTheWord(project.urls.length)}`}
                    </div>
                </div>
            </div>
        );
    }

    const projectTemplateWithoutAccess = (project) => {
        return (
            <div
                className="project"
                key={`project-${project.id}`}
                onClick={
                    () => modal.showModal(<PortfolioProjectModal project={project} callback={getUserInfo} />)
                }
            >
                <div className="project-title-header">
                    <div className="project-title">
                        {project.name}
                    </div>
                </div>
                <div className="project-description">
                    {project.description.length > 100 ? `${project.description.slice(0, 100)}...` : project.description}
                </div>
                <div className="project-files-links">
                    <div className="files-links">
                        <img src={FileProjectImg} />
                        {`${project.document_urls.length} ${filesEndingOfTheWord(project.document_urls.length)}`}
                    </div>
                    <div className="files-links">
                        <img src={LinkProjectImg} />
                        {`${project.urls.length} ${linksEndingOfTheWord(project.urls.length)}`}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="profile-page">
            <div className="container">
                <div className="row">
                    {currentUserId && id && currentUserId === Number(id) ? (<>
                        <div className="col-md-4">
                            <div className="white-box profile-block">
                                <div className="profile-img-block">
                                    <img className="profile-img" alt="profile"
                                        src={userStore.user && userStore.user.image ? config.strapi.image_url + '/' + userStore.user.image : ProfilePlaceholderImg} />
                                </div>
                                <div className="name">
                                    {userStore && userStore?.user && userStore.user.first_name + ' ' + userStore.user.last_name}
                                </div>
                                <div className="reviews">
                                    <img src={StarImg} alt="block" className="star-img"/>
                                    {profileData?.rating >= 0 && profileData.rating.toFixed(1)}
                                    {profileData?.number_rating >= 0 && ` (${profileData.number_rating} ${getNumberRatingReviews(profileData.number_rating)})`}
                                </div>
                                <hr/>
                                <div className="city">
                                    {profileData?.geodata?.label && profileData.geodata.label}{timeUTC && ` — ${timeUTC}`}
                                </div>
                                <Link to="/edit-profile/1">
                                    <button className="edit-profile">Редактировать профиль</button>
                                </Link>
                            </div>
                        </div>
                        { userStore.user && userStore.isFreelancer ? (<>
                            <div className="col-md-8">
                                <div className="white-box freelancer-resume">
                                    {/* Programming Languages */}
                                    <div className="header">
                                        <div className="header-title">Языки программирования</div>
                                        <div
                                            className="edit"
                                            onClick={() => modal.showModal(<EditProgrammingLanguagesModal
                                                savedProgrammingLanguages={profileData?.programming_language}
                                                callback={getUserInfo}
                                            />)}>
                                            <span className="edit-icon"></span>
                                        </div>
                                    </div>
                                    {profileData?.programming_language && profileData?.programming_language?.length > 0 ? (
                                        <div className="grey-boxes">
                                            {profileData.programming_language.map((programmingLanguage, index) =>
                                                <div
                                                    key={`programming-language-${index}`}
                                                    className="grey-box"
                                                >
                                                    {programmingLanguage.name}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="empty">
                                            Вы еще не указали ни одного языка. По языкам программирования заказчики подбирают нужного специалиста для заказа.{' '} 
                                            <span
                                                className="add"
                                                onClick={() => modal.showModal(<EditProgrammingLanguagesModal 
                                                    callback={getUserInfo}
                                                />)}
                                            >
                                                Добавить
                                            </span>
                                    </div>
                                    )}
                                    {/* Skills */}
                                    <div className="header">
                                        <div className="header-title">Навыки</div>
                                        <div
                                            className="edit"
                                            onClick={() => modal.showModal(<EditSkillsModal savedSkills={profileData?.skills} callback={getUserInfo} />)}
                                        >
                                            <span className="edit-icon"></span>
                                        </div>
                                    </div>
                                    {profileData?.skills && profileData?.skills?.length > 0 ? (
                                        <div className="grey-boxes">
                                            {profileData.skills.map((skill, index) =>
                                                <div
                                                    key={`skill-${index}`}
                                                    className="grey-box"
                                                >
                                                    {skill}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="empty">
                                            Вы еще не указали навыки. По списку навыков заказчиков сможет понять, что вы — лучший кандидат для заказа.{' '} 
                                            <span
                                                className="add"
                                                onClick={() => modal.showModal(<EditSkillsModal callback={getUserInfo} />)}
                                            >
                                                Добавить
                                            </span>
                                        </div>
                                    )}
                                    {/* Projects */}
                                    <div className="header">
                                        <div className="header-title">Портфолио</div>
                                        <div
                                            className="edit"
                                            onClick={() => modal.showModal(<AddProjectsModal callback={getUserInfo} />)}
                                        >
                                            <span className="edit-icon"></span>
                                        </div>
                                    </div>
                                    {profileData?.portfolios && profileData?.portfolios?.length > 0 ? (
                                        <div className="projects row g-3">
                                            <div className="col-lg-6">
                                                {profileData.portfolios.slice(0, portfoliosSlicer).map((project) => projectTemplate(project))}
                                            </div>
                                            <div className="col-lg-6">
                                                {profileData.portfolios.slice(portfoliosSlicer).map((project) => projectTemplate(project))}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="empty">
                                        У вас нет работ в портфолио. Хорошее портфолио позволяет вам выделиться на фоне конкурентов и блеснуть своими навыками.{' '} 
                                        <span
                                            className="add"
                                            onClick={() => modal.showModal(<AddProjectsModal callback={getUserInfo} />)}
                                        >
                                            Добавить
                                        </span>
                                    </div>
                                    )}
                                    {/* Bio */}
                                    <div className="header">
                                        <div className="header-title">О себе</div>
                                        <div
                                            className="edit"
                                            onClick={() => modal.showModal(<EditBioModal savedAboutMe={profileData?.about_me} callback={getUserInfo} />)}
                                        >
                                            <span className="edit-icon"></span>
                                        </div>
                                    </div>
                                    {profileData?.about_me && profileData?.about_me?.length > 0 ? (
                                        <div className="bio">{profileData.about_me}</div>
                                    ) : (
                                        <div className="empty">
                                        Кратко напишите о себе — что вас увлекает, какие области вам наиболее интересны, и т.д.{' '} 
                                        <span
                                            className="add"
                                            onClick={() => modal.showModal(<EditBioModal callback={getUserInfo} />)}
                                        >
                                            Добавить
                                        </span>
                                    </div>
                                    )}
                                    {/* Experience */}
                                    {profileData?.work_experiences && profileData?.work_experiences?.length > 0 ? (
                                        <>
                                            <div className="header">
                                                <div className="header-title">Опыт работы</div>
                                                <div
                                                    className="edit"
                                                    onClick={() => modal.showModal(<EditExperienceModal 
                                                        savedExperiences={profileData?.work_experiences} 
                                                        callback={getUserInfo} 
                                                    />)}
                                                >
                                                    <span className="edit-icon"></span>
                                                </div>
                                            </div>
                                            <div className="experiences">
                                                {profileData.work_experiences.map((experience, index) =>
                                                    <div
                                                        key={`experience-${index}`}
                                                        className="experience"
                                                    >
                                                        <div className="company-position">
                                                            <div className="company">
                                                                {experience.company}
                                                            </div>
                                                            <div className="position">
                                                                {experience.position}
                                                            </div>
                                                        </div>
                                                        <div className="start-end">
                                                            {`${formatDateYear(experience.start_date)} - ${formatDateYear(experience.end_date)}`}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="header">
                                                <div className="header-title">Опыт работы</div>
                                                <div onClick={() => modal.showModal(<EditExperienceModal 
                                                    savedExperiences={[{
                                                        company: '',
                                                        position: '',
                                                        duty: '',
                                                        start_date: [''],
                                                        end_date: ['']
                                                    }]} 
                                                    callback={getUserInfo} 
                                                />)}>
                                                    <img src={PencilBlackImg} />
                                                </div>
                                            </div>
                                            <div className="empty">
                                                Вы еще не указывали ваш опыт работы.{' '} 
                                                <span
                                                    className="add"
                                                    onClick={() => modal.showModal(<EditExperienceModal
                                                        savedExperiences={[{
                                                            company: '',
                                                            position: '',
                                                            duty: '',
                                                            start_date: [''],
                                                            end_date: ['']
                                                        }]}
                                                    callback={getUserInfo} />)}
                                                >
                                                    Добавить
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="white-box tasks-block">
                                    <div className="header">
                                        Размещенные услуги
                                    </div>
                                    {userStore && userStore?.user && <ProfileTasks userId={userStore.user.id} taskType={2} />}
                                </div>
                                <div className="white-box reviews-block">
                                    {userStore && userStore?.user && <ProfileReviewComponent userId={userStore.user.id} />}
                                </div>
                            </div>
                        </>) : (
                            <div className="col-md-8">
                                <div className="white-box tasks-block">
                                    <div className="header">
                                        Размещенные заказы
                                    </div>
                                    {userStore && userStore?.user && <ProfileTasks userId={userStore.user.id} taskType={1} />}
                                </div>
                                <div className="white-box reviews-block">
                                    {userStore && userStore?.user && <ProfileReviewComponent userId={userStore.user.id} />}
                                </div>
                            </div>
                        )} 
                    </>) : (<>
                        <div className="col-md-4">
                            <div className="white-box profile-block">
                                <div className="profile-img-block">
                                    <img className="profile-img" alt="profile"
                                        src={profileData && profileData.image ? config.strapi.image_url + '/' + profileData.image : ProfilePlaceholderImg} />
                                </div>
                                <div className="name">
                                    {profileData && profileData.first_name}{profileData && ` ${profileData.last_name}`}
                                </div>
                                <div className="reviews">
                                    <img src={StarImg} alt="block" className="star-img"/>
                                    {profileData?.rating >= 0 && profileData.rating.toFixed(1)}
                                    {profileData?.number_rating >= 0 && ` (${profileData.number_rating} ${getNumberRatingReviews(profileData.number_rating)})`}
                                </div>
                                <hr/>
                                <div className="city">
                                    {profileData?.geodata?.label && profileData.geodata.label}{timeUTC && ` — ${timeUTC}`}
                                </div>
                            </div>
                        </div>
                        {userRole && userRole === 'freelancer' ? (
                            <div className="col-md-8">
                                <div className="white-box freelancer-resume">
                                    {/* Programming Languages */}
                                    <div className="header">
                                        <div className="header-title">Языки программирования</div>
                                    </div>
                                    {profileData?.programming_language && profileData?.programming_language?.length > 0 ? (
                                        <div className="grey-boxes">
                                            {profileData.programming_language.map((programmingLanguage, index) =>
                                                <div
                                                    key={`programming-language-${index}`}
                                                    className="grey-box"
                                                >
                                                    {programmingLanguage.name}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="empty">
                                            Исполнитель еще не указал ни одного языка.
                                    </div>
                                    )}
                                    {/* Skills */}
                                    <div className="header">
                                        <div className="header-title">Навыки</div>
                                    </div>
                                    {profileData?.skills && profileData?.skills?.length > 0 ? (
                                        <div className="grey-boxes">
                                            {profileData.skills.map((skill, index) =>
                                                <div
                                                    key={`skill-${index}`}
                                                    className="grey-box"
                                                >
                                                    {skill}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="empty">
                                            Исполнитель еще не указал навыки.
                                        </div>
                                    )}
                                    {/* Projects */}
                                    <div className="header">
                                        <div className="header-title">Портфолио</div>
                                    </div>
                                    {profileData?.portfolios && profileData?.portfolios?.length > 0 ? (
                                        <div className="projects row g-3">
                                            <div className="col-lg-6">
                                                {profileData.portfolios.slice(0, portfoliosSlicer).map((project) => projectTemplateWithoutAccess(project))}
                                            </div>
                                            <div className="col-lg-6">
                                                {profileData.portfolios.slice(portfoliosSlicer).map((project) => projectTemplateWithoutAccess(project))}
                                            </div>
                                        </div>    
                                    ) : (
                                        <div className="empty">
                                            Исполнитель еще не добавил работы в портфолио.
                                    </div>
                                    )}
                                    {/* Bio */}
                                    <div className="header">
                                        <div className="header-title">О себе</div>
                                    </div>
                                    {profileData?.about_me && profileData?.about_me?.length > 0 ? (
                                        <div className="bio">{profileData.about_me}</div>
                                    ) : (
                                        <div className="empty">
                                            Исполнитель еще не указал информацию о себе.
                                        </div>
                                    )}
                                    {/* Experience */}
                                    <div className="header">
                                        <div className="header-title">Опыт работы</div>
                                    </div>
                                    {profileData?.work_experiences && profileData?.work_experiences?.length > 0 ? (
                                        <div className="experiences">
                                            {profileData.work_experiences.map((experience, index) =>
                                                <div
                                                    key={`experience-${index}`}
                                                    className="experience"
                                                >
                                                    <div className="company-position">
                                                        <div className="company">
                                                            {experience.company}
                                                        </div>
                                                        <div className="position">
                                                            {experience.position}
                                                        </div>
                                                    </div>
                                                    <div className="start-end">
                                                        {`${formatDateYear(experience.start_date)} - ${formatDateYear(experience.end_date)}`}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="empty">
                                            Исполнитель еще не указывал свой опыт работы.
                                        </div>
                                    )}
                                </div>
                                <div className="white-box tasks-block">
                                    <div className="header">
                                        Размещенные услуги
                                    </div>
                                    {profileData && profileData?.id && <ProfileTasks userId={profileData.id} taskType={2} />}
                                </div>
                                <div className="white-box reviews-block">
                                    {profileData && profileData?.id && <ProfileReviewComponent userId={profileData.id} />}
                                </div>
                            </div>
                        ) : (
                            <div className="col-md-8">
                                <div className="white-box tasks-block">
                                    <div className="header">
                                        Размещенные заказы
                                    </div>
                                    {profileData && profileData?.id && <ProfileTasks userId={profileData.id} taskType={1} />}
                                </div>
                                <div className="white-box reviews-block">
                                    {profileData && profileData?.id && <ProfileReviewComponent userId={profileData.id} />}
                                </div>
                            </div>
                        )}
                    </>)}                
                </div>
            </div>
        </div>
    )
})

export { ProfilePage }