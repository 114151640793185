import { observer } from 'mobx-react-lite'
import './index.scss'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { createRoom as createRoomApi, createMessage as createMessageApi, uploadFiles as uploadFilesApi, uploadUrls as uploadUrlsApi } from '@api/chat-api';
import { AttachFilesAndLinks } from '@Components/AttachFilesAndLinks';

// --------------------------------
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// --------------------------------

const validationSchema = Yup.object().shape({
    description: Yup.string()
        .required('Поле обязательно к заполнению'),
})
const formOptions = { resolver: yupResolver(validationSchema) }

const RespondModal = observer(({ createdUser, taskId, callGetTask }) => {
    const { modal, userStore } = useContext(Context);
    const { register, handleSubmit, formState: { errors } } = useForm(formOptions);
    const selectedFilesRef = useRef([]);
    const selectedLinksRef = useRef([]);

    const onSubmit = ({
        description,
    }) => {
        const roomBody = {
            name: taskId + '' + userStore.user.id,
            created_user: createdUser,
            responded_user: userStore.user.id,
            task: taskId
        }
        createRoomApi(roomBody)
        .then((res) => {
            if (res && res.data) {
                const messageBody = {
                    author: userStore.user.id,
                    room: res.data.id,
                    content: description
                }
                createMessageApi(messageBody)
                .then((res) => {
                    if (res && res.data && selectedLinksRef.current.length) {
                        uploadUrlsApi({message: res.data.id, url: selectedLinksRef.current})
                        .then(() => {
                            console.log('uploadUrl success');
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    }
                    if (res && res.data && selectedFilesRef.current.length) {
                        uploadFilesApi(res.data.id, selectedFilesRef.current)
                        .then(() => {
                            callGetTask();
                            modal.closeModal();
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                    }  else {
                        callGetTask();
                        modal.closeModal();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            }
            userStore.newRespondedUser(createdUser, taskId);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const onFilesOrLinksChange = (files, links) => {
        selectedFilesRef.current = files;
        selectedLinksRef.current = links;
    }

    return (
        <div className="respond-modal">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-modal">
                    Отклик на заказ
                    <div className="x-block" onClick={() => modal.closeModal()}>
                        <img src={XImg} className="x"/>
                    </div>
                </div>
                <div className="form-control-block">
                    <label htmlFor="description" className="form-label">Сопроводительное письмо</label>
                    <textarea type="text" className="form-control" id="description" rows="4"
                        placeholder="Напишите информацию о себе, своих услугах, условиях работы." 
                        {...register("description")} />
                    {
                        errors.description?.message && (
                        <p className="error">{errors.description?.message}</p>
                    )}
                </div>
                <AttachFilesAndLinks onChangeCallback={onFilesOrLinksChange} />
                <div className="invalid-feedback">
                    Что то пошло не так
                </div>
                <button type="submit" className="my-button">Откликнуться</button>
            </form>
        </div>
    )
})

export { RespondModal }