import config from '../config';
import instance from './axios-instance';

const STRAPI_API_URL = config.strapi.api_url + '/review';

export const getReviews = (userId, page, reviewUserId) => {
    let params = {};
    if (reviewUserId) {
        params.review_user__id = reviewUserId;
    }
    if (Number(page) > 1) {
        params.page = page;
        return instance.get(`${STRAPI_API_URL}/user/${userId}/`, { params });
    }
    return instance.get(`${STRAPI_API_URL}/user/${userId}/`, { params });
}

export const createReview = (userId, obj) => {
    return instance.post(`${STRAPI_API_URL}/user/${userId}/create/`, obj)
}