import { observer } from 'mobx-react-lite';
import './index.scss';
import UserImg from '@assets/svg/user.svg';
import { Link } from 'react-router-dom';
import PencilDropdownImg from '@assets/svg/header/dropdown/pencil.svg';
import PaymentDropdownImg from '@assets/svg/header/dropdown/payment.svg';
import PasswordDropdownImg from '@assets/svg/header/dropdown/password.svg';
import NotificationsDropdownImg from '@assets/svg/header/dropdown/notifications.svg';
import { useParams } from "react-router-dom";
import { EditProfileComponent } from '@Components/EditProfileComponent';
import { EditPasswordComponent } from '@Components/EditPasswordComponent';
import { EditBalanceComponent } from '@Components/EditBalanceComponent';
import { Context } from '@/index';
import { useContext } from 'react';

const EditProfilePage = observer(() => {
    const { userStore } = useContext(Context);
    let { id } = useParams();

    return (
        <div className="edit-profile-page">
            <div className="container">
                <div className="row row-cols-1 row-cols-lg-2">
                    <div className="col-lg-4">
                        <div className="white-box-menu edit-menu-block">
                            <div className="menu-item">
                                <Link to={`/profile/${userStore && userStore?.user?.id && userStore.user.id}`}>
                                    <div className="item">
                                        <img src={UserImg}/>
                                        <span>Профиль</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link to="/edit-profile/1">
                                    <div
                                        className={`item ${id == 1 && 'item-selected'}`}
                                    >
                                        <img src={PencilDropdownImg}/>
                                        <span>Редактировать профиль</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link to="/edit-profile/2">
                                    <div
                                        className={`item ${id == 2 && 'item-selected'}`}
                                    >
                                        <img src={PasswordDropdownImg}/>
                                        <span>Пароль</span>
                                    </div>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link to="/edit-profile/3">
                                    <div
                                        className={`item ${id == 3 && 'item-selected'}`}
                                    >
                                        <img src={PaymentDropdownImg}/>
                                        <span>Баланс</span>
                                    </div>
                                </Link>
                            </div>
                            {/* <div className="menu-item">
                                <div className="item">
                                    <img src={NotificationsDropdownImg}/>
                                    <span>Настройки уведомлений</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {userStore && userStore?.user?.id &&
                        <div className="col-lg-8">
                            {
                                id == 1 && <EditProfileComponent />
                            }
                            {
                                id == 2 && <EditPasswordComponent />
                            }
                            {
                                id == 3 && <EditBalanceComponent />
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
})

export { EditProfilePage }