import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createContext } from 'react'

// Stores
import ModalStore from './store/ModalStore';
import FreezeStore from './store/FreezeStore';
import TasksCategoriesStore from './store/TasksCategoriesStore';
import ProgrammingLanguagesStore from './store/ProgrammingLanguagesStore';
import UserStore from './store/UserStore';
import ChatStore from './store/ChatStore';
// import 'bootstrap/dist/css/bootstrap.css'

export const Context = createContext(null)

ReactDOM.render(
  <React.StrictMode>
    <Context.Provider
      value={{
        modal: new ModalStore(),
        freeze: new FreezeStore(),
        tasksCategoriesStore: new TasksCategoriesStore(),
        programmingLanguagesStore: new ProgrammingLanguagesStore(),
        userStore: new UserStore(),
        chatStore: new ChatStore(),
      }}
    >
      <App />
    </Context.Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
