import './index.scss';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import path from 'path';
import WordIcon from '@assets/img/ms-word.png';
import ExcelIcon from '@assets/img/ms-excel.png';
import PowerPointIcon from '@assets/img/ms-powerpoint.png';
import PDFIcon from '@assets/img/adobe-pdf.png';
import FileIcon from '@assets/img/file-icon.png';
import config from '@/config';
import { formatBytes, trimFileName } from '@utils/utils';

const AttachedFilesFromUrls = observer(({ document_urls }) => {
    const [ attachmentFiles, setAttachmentFiles ] = useState([]);
    const getIconAndStyle = (document) => {
        let filename = path.basename(document);
        let extension = path.extname(filename).replace('.', '');
        switch (extension) {
            case 'doc': 
            case 'docx':
                return [WordIcon, 'preview-file'];
            case 'xls': 
            case 'xlsx':
                return [ExcelIcon, 'preview-file'];
            case 'ppt': 
            case 'pptx':
                return [PowerPointIcon, 'preview-file'];
            case 'pdf': 
                return [PDFIcon, 'preview-file'];
            case 'png': 
            case 'jpg':
            case 'jpeg':
            case 'webp':
                return [`${config.strapi.image_url}/${document}`, 'preview-image'];
            default:
                return [FileIcon, 'preview-file'];
        }
    }

    useEffect(() => {
        document_urls.forEach((documentUrl) => {
            fetch( `${config.strapi.image_url}${documentUrl}`)
                .then(res => { return res.blob(); })
                .then(blob => {
                    let file = new File([blob], path.basename(documentUrl), { type: blob.type });
                    setAttachmentFiles(files => [...files, file]);
                }
            );
        });
    }, []);

    return (
        <div className="attached-files-from-urls">
            <div className="files">
                {document_urls && document_urls.map((document, index) =>
                <div className="file" key={`document_urls_${index}`}>
                    <a
                        href={`${config.strapi.image_url}${document}`}
                        target="_blank"
                        rel="noreferrer"
                        title={path.basename(document)}
                        download
                    >
                        <div className="file-bg-for-img">
                            <img
                                src={getIconAndStyle(document)[0]}
                                className={getIconAndStyle(document)[1]}
                                alt={document}
                            />
                        </div>
                        <div className="file-name">{trimFileName(path.basename(document))}</div>
                        <div className="file-size">
                            {attachmentFiles && attachmentFiles[index] && formatBytes(attachmentFiles[index].size)}
                        </div>
                    </a>
                </div>
                )}
            </div>
        </div>
    );
})

export { AttachedFilesFromUrls }