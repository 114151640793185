import { observer } from 'mobx-react-lite'
import './index.scss'
import XImg from '@assets/svg/x.svg'
import { LoginPage } from '@Pages/LoginPage'
import { Context } from '@/index'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { register as registerApi } from '@api/user-api'

// --------------------------------
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// --------------------------------
const validationSchema = Yup.object().shape({
 first_name: Yup.string()
  .required('Введите имя')
  .min(2, 'Имя должно быть не менее 2 символов'),
 last_name: Yup.string()
  .required('Введите фамилию')
  .min(2, 'Фамилия должна быть не менее 2 символов'),
 radio: Yup.string().required('Выберите роль').nullable(true),
 email: Yup.string()
  .email('Введите корректный email')
  .required('Введите email'),
 password: Yup.string()
  .required('Введите пароль')
  .min(6, 'Пароль должен быть не менее 6 символов'),
 password2: Yup.string()
  .required('Введите пароль')
  .min(6, 'Пароль должен быть не менее 6 символов'),
 t_and_c: Yup.bool().oneOf([true], 'Требуется согласие'),
})

const formOptions = { resolver: yupResolver(validationSchema) }

const RegisterPage = observer(() => {
 const { modal, userStore } = useContext(Context)
 const [isUniqueEmailError, setIsUniqueEmailError] = useState(false)
 const [isDifferentPasswordsError, setIsDifferentPasswordsError] =
  useState(false)
 const {
  register,
  handleSubmit,
  formState: { errors },
 } = useForm(formOptions)

 const onSubmit = ({
  first_name,
  last_name,
  radio,
  email,
  password,
  password2,
 }) => {
  console.log(radio)
  if (password !== password2) {
   setIsDifferentPasswordsError(true)
   return
  }
  if (radio === 'freelancer') {
   userStore.setIsFreelancer(true)
  } else {
   userStore.setIsFreelancer(false)
  }
  registerApi(email, password, first_name, last_name)
   .then((res) => {
    console.log(res)
    userStore.fetchUser()
    modal.closeModal()
    //   setIsLoading(false)
    //   setIsSubmitted(true)
    //   userStore.setUser(res.data.user)
    //   userStore.setIsAuth(true)
    //   history.push('/')
   })
   .catch((error) => {
    console.log(error)
    setIsUniqueEmailError(true)
    //   setIsLoading(false)
    //   setIsSubmitted(false)
   })
 }

 return (
  <div className="register-page">
   <div className="header-modal">
    Стать участником площадки 😎
    <div className="x-block" onClick={() => modal.closeModal()}>
     <img src={XImg} className="x" />
    </div>
   </div>
   <form className="form" onSubmit={handleSubmit(onSubmit)}>
    <div className="form-control-block">
     <label className="form-label">Представьтесь, пожалуйста</label>
     <div className="row">
      <div className="col-md-6">
       <input
        type="text"
        className="form-control"
        placeholder="Например, Пётр"
        {...register('first_name')}
       />
       {errors.first_name?.message && (
        <p className="error">{errors.first_name?.message}</p>
       )}
      </div>
      <div className="col-md-6 gap-for-inputs">
       <input
        type="text"
        className="form-control"
        placeholder="Например, Петров"
        {...register('last_name')}
       />
       {errors.last_name?.message && (
        <p className="error">{errors.last_name?.message}</p>
       )}
      </div>
     </div>
    </div>
    <div className="form-control-block">
     <label className="form-label">Выберите роль</label>
     <div className="radios">
      <div className="form-check">
       <input
        className="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault1"
        {...register('radio')}
        value="manager"
       />
       <label className="form-check-label" htmlFor="flexRadioDefault1">
        Заказчик
       </label>
      </div>
      <div className="form-check second-radio">
       <input
        className="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault2"
        {...register('radio')}
        value="freelancer"
       />
       <label className="form-check-label" htmlFor="flexRadioDefault2">
        Фрилансер
       </label>
      </div>
      {errors.radio?.message && (
       <p className="error">{errors.radio?.message}</p>
      )}
     </div>
     <div className="gray-text">
      После регистрации Вы можете изменить свой тип учетной записи
     </div>
    </div>
    <div className="form-control-block">
     <label htmlFor="email" className="form-label">
      Какой адрес использовать в качестве рабочего?
     </label>
     <input
      type="text"
      className={`form-control ${isUniqueEmailError && 'input-error'}`}
      id="email"
      placeholder="Например, petrov@yandex.ru"
      {...register('email')}
      onFocus={() => setIsUniqueEmailError && setIsUniqueEmailError(false)}
     />
     {errors.email?.message && <p className="error">{errors.email?.message}</p>}
     {isUniqueEmailError && (
      <p className="error">🤔 Такой адрес электронной почты уже используется</p>
     )}
    </div>
    <div className="form-control-block">
     <label htmlFor="email" className="form-label">
      Пароль
     </label>
     <div className="row">
      <div className="col-md-6">
       <input
        type="password"
        className="form-control"
        placeholder="Придумайте пароль"
        {...register('password')}
        onFocus={() =>
         isDifferentPasswordsError && setIsDifferentPasswordsError(false)
        }
       />
       {errors.password?.message && (
        <p className="error">{errors.password?.message}</p>
       )}
       {isDifferentPasswordsError && (
        <p className="error">😢 Пароли должны совпадать</p>
       )}
      </div>
      <div className="col-md-6 gap-for-inputs">
       <input
        type="password"
        className={`form-control ${isDifferentPasswordsError && 'input-error'}`}
        placeholder="Подтвердите пароль"
        {...register('password2')}
        onFocus={() =>
         isDifferentPasswordsError && setIsDifferentPasswordsError(false)
        }
       />
       {errors.password2?.message && (
        <p className="error">{errors.password2?.message}</p>
       )}
      </div>
     </div>
    </div>
    <div className="form-check">
     <input
      className="form-check-input"
      type="checkbox"
      value="true"
      id="flexCheckDefault"
      {...register('t_and_c')}
     />
     <label className="form-check-label" htmlFor="flexCheckDefault">
      Нам необходимо ваше согласие на{' '}
      <a href="/policy" target="_blank" className="underline">
       обработку персональных данных
      </a>{' '}
      в соответствии с{' '}
      <a href="/terms" target="_blank" className="underline">
       пользовательсим соглашением
      </a>
     </label>
     {errors.t_and_c?.message && (
      <p className="error">{errors.t_and_c?.message}</p>
     )}
    </div>
    <div className="invalid-feedback">Проверьте на заполнение все поля</div>
    <button type="submit" className="my-button first-button">
     Зарегистрироваться
    </button>
    <button
     type="button"
     className="my-button second-button"
     onClick={() => modal.showModal(<LoginPage />)}
    >
     Я уже участник площадки 😶
    </button>
   </form>
  </div>
 )
})

export { RegisterPage }
