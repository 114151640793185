import './index.scss';
import { observer } from 'mobx-react-lite';
import XImg from '@assets/svg/x.svg';
import { Context } from '@/index';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { SELECT_STYLES, YEARS_START, YEARS_END, toastOptions } from '@shared/index';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
    updateWorkExperiences as updateWorkExperiencesApi,
    getUserInfo as getUserInfoApi
} from '@api/user-api';
import { formatDateYear } from '@utils/utils';
import { toast } from 'react-toastify';

const EditExperienceModal = observer(({ savedExperiences = [], callback }) => {
    const { modal, userStore } = useContext(Context);
    const [ workExperiences, setWorkExperiences ] = useState([]);
    const [ experiencesCount, setExperiencesCount ] = useState(1);
    const [ watchedExperiences, setWatchedExperiences ] = useState({});
    const yupShape = {};
    for (let i = 0; i < experiencesCount; i ++) {
        yupShape[`company_${i}`] = Yup.string()
            .required('Укажите компанию')
            .min(2, 'Название компании должно быть не менее 2 символов')
            .max(60, 'Название компании должно быть не более 60 символов');
        yupShape[`position_${i}`] = Yup.string()
            .required('Укажите должность')
            .min(6, 'Название должности должно быть не менее 6 символов')             
            .max(60, 'Название должности должно быть не более 60 символов');
        yupShape[`duty_${i}`] = Yup.string()
            .required('Укажите чем вы занимались в компании')
            .min(16, 'Информация о том, чем вы занимались в компании должна быть не менее 16 символов')             
            .max(120, 'Информация о том, чем вы занимались в компании должна быть не более 120 символов');
        yupShape[`start_date_${i}`] = Yup.object()
            .required('Выберите начало периода');
        yupShape[`end_date_${i}`] = Yup.object()
            .required('Выберите конец периода');
    }
    const validationSchema = Yup.object().shape(yupShape);
    const formOptions = { resolver: yupResolver(validationSchema), shouldUnregister: true }
    const { register, reset, watch, control, handleSubmit, formState: { errors } } = useForm(formOptions);

    useEffect(() => {
        setWorkExperiences(savedExperiences);
    }, []);
    useEffect(() => {
        const subscription = watch((value) => {
            setWatchedExperiences(value);
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    useEffect(() => {
        console.log(workExperiences.length);
        setExperiencesCount(workExperiences.length);
        if(workExperiences.length === 0) {
            let defaultValues = {};
            defaultValues[`company_0`] = '';
            defaultValues[`position_0`] = '';
            defaultValues[`duty_0`] = '';
            defaultValues[`start_0`] = '';
            defaultValues[`end_date_0`] = '';
            reset({ ...defaultValues }); 
        }
        let defaultValues = {};
        workExperiences.forEach((experience, index) => {
            let start_date = YEARS_START.filter(item => Number(formatDateYear(experience.start_date)) === Number(item.value));
            let end_date = YEARS_END.filter(item => Number(formatDateYear(experience.start_date)) === Number(item.value));
            defaultValues[`company_${index}`] = experience.company;
            defaultValues[`position_${index}`] = experience.position;
            defaultValues[`duty_${index}`] = experience.duty;
            defaultValues[`start_date_${index}`] = start_date[0];
            defaultValues[`end_date_${index}`] = end_date[0];
            reset({ ...defaultValues });
        });
    }, [workExperiences]);

    const onSubmit = (about) => {
        const experiences = [];
        for (const [key, value] of Object.entries(about)) {
            let groupIndex = key.charAt(key.length-1);
            let parameter = key.slice(0, key.length-2);
            if(experiences[groupIndex]) {
                experiences[groupIndex][parameter] = value;
            } else {
                experiences[groupIndex] = {};
                experiences[groupIndex][parameter] = value;
            }
        }
        for (let i = 0; i < experiences.length; i++) {
            let start_date = new Date(parseInt(experiences[i].start_date.value), 1);
            experiences[i].start_date = start_date.toISOString();
            let end_date = new Date(parseInt(experiences[i].end_date.value), 1);
            experiences[i].end_date = end_date.toISOString();
        }
        updateWorkExperiencesApi({work_experiences: experiences})
        .then(() => {
            callback();
            toast.success('Опыт работы успешно сохранен', toastOptions);
            modal.closeModal();
        })
        .catch(() => {
            callback();
            toast.error('Произошла ошибка изменения опыта работы', toastOptions);
            modal.closeModal();
        })
    }

    const removeExperience = (e) => {
        const id = e.target.getAttribute("name");
        if(id) {
            setWorkExperiences(workExperiences.filter(item => item.id !== Number(id)));
        } else {
            setExperiencesCount((prevCount) => {
                return prevCount - 1;
            });
        }
    }

    const addMore = (e) => {
        e.preventDefault();
        if(Object.keys(watchedExperiences).length > 0) {
            const experiences = [];
            for (const [key, value] of Object.entries(watchedExperiences)) {
                let groupIndex = key.charAt(key.length-1);
                let parameter = key.slice(0, key.length-2);
                if(experiences[groupIndex]) {
                    experiences[groupIndex][parameter] = value;
                } else {
                    experiences[groupIndex] = {};
                    experiences[groupIndex][parameter] = value;
                }
            }
            for (let i = 0; i < experiences.length; i++) {
                if (experiences[i].start_date?.value && 
                    experiences[i].end_date?.value && 
                    experiences[i].company && 
                    experiences[i].position && 
                    experiences[i].duty) {
                    let start_date = new Date(parseInt(experiences[i].start_date.value), 1);
                    experiences[i].start_date = start_date.toISOString();
                    let end_date = new Date(parseInt(experiences[i].end_date.value), 1);
                    experiences[i].end_date = end_date.toISOString();
                } else {
                    toast.error('Для добавления заполните все поля', toastOptions);
                    return;
                }
            }
            setWatchedExperiences({});
            updateWorkExperiencesApi({work_experiences: experiences})
            .then(() => {
                getUserInfoApi(userStore.user.id)
                .then((response) => {
                    setWorkExperiences(response.data.work_experiences);
                    callback();
                    setExperiencesCount((prevCount) => {
                        return prevCount + 1;
                    });
                })
                .catch(() => {
                    toast.error('Произошла ошибка добавления опыта работы', toastOptions);
                });
            })
            .catch(() => {
                callback();
                toast.error('Произошла ошибка добавления опыта работы', toastOptions);
            });    
        } else {
            if(experiencesCount === workExperiences.length) {
                setExperiencesCount((prevCount) => {
                    return prevCount + 1;
                });
            } else {
                toast.error('Для добавления заполните все поля', toastOptions);
                return;
            }
        }
          
    };

    const renderExperienceCount = (savedExperiences) => {
        const experiences = [];
        if(experiencesCount === 0) {
            return <>{experiences}</>;
        }
        for(let i = 0; i < experiencesCount; i++) {
            if (savedExperiences[i]) {
                let start_date = YEARS_START.filter(item => Number(formatDateYear(savedExperiences[i].start_date)) === Number(item.value));
                let end_date = YEARS_END.filter(item => Number(formatDateYear(savedExperiences[i].start_date)) === Number(item.value));
                experiences.push(renderExperience(i, savedExperiences[i].id, start_date[0], end_date[0]));
            } else {
                experiences.push(renderExperience(i));
            }
            
        }
        return <>{experiences}</>;
    }

    const renderExperience = (index, id = null, startDate = '', endDate = '') => {
        return (
            <div key={`experience_${index}`} className="experience">
                <div className="form-control-block">
                    <div className="experience-header">
                        <label htmlFor="company" className="form-label">
                            Компания
                        </label>
                        <div
                            className="experience-remove"
                            name={id}
                            onClick={removeExperience}
                        >
                            Удалить
                        </div>
                    </div>
                    <input
                        className="form-control"
                        id="company"
                        placeholder="" 
                        {...register(`company_${index}`)}
                    />
                    {errors[`company_${index}`]?.message && (
                        <p className="error">{errors[`company_${index}`]?.message}</p>
                    )}
                </div>
                <div className="form-control-block">
                    <label htmlFor="position" className="form-label">
                        Должность
                    </label>
                    <input
                        className="form-control"
                        id="position"
                        placeholder="" 
                        {...register(`position_${index}`)}
                    />
                    {errors[`position_${index}`]?.message && (
                        <p className="error">{errors[`position_${index}`]?.message}</p>
                    )}
                </div>
                <div className="form-control-block">
                    <label htmlFor="duty" className="form-label">
                        Чем вы занимались в компании?
                    </label>
                    <input
                        className="form-control"
                        id="duty"
                        placeholder="" 
                        {...register(`duty_${index}`)}
                    />
                    {errors[`duty_${index}`]?.message && (
                        <p className="error">{errors[`duty_${index}`]?.message}</p>
                    )}
                </div>
                <div className="form-control-block">
                    <label className="form-label">
                        Период
                    </label>
                    <div className="row">
                        <div className="col-1-custom-left">
                            c
                        </div>
                        <div className="col-5-custom padding-left-zero">
                            <Controller
                                name={`start_date_${index}`}
                                control={control}
                                rules={{ required: true }}
                                render = {({ field: { onChange, onBlur, value, ref } })=> (
                                    <Select
                                        fullWidth
                                        placeholder="Выберите дату"
                                        styles={SELECT_STYLES}
                                        options={YEARS_START}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        defaultValue={startDate}
                                    />
                                )}
                            />
                            {errors[`start_date_${index}`]?.message && (
                                <div className="error">{errors[`start_date_${index}`]?.message}</div>
                            )}
                        </div>
                        <div className="col-1-custom">
                            по
                        </div>
                        <div className="col-5-custom-right padding-left-zero">
                            <Controller
                                name={`end_date_${index}`}
                                control={control}
                                rules={{ required: true }}
                                render = {({ field: { onChange, onBlur, value, ref } })=> (
                                    <Select
                                        fullWidth
                                        placeholder="Выберите дату"
                                        styles={SELECT_STYLES}
                                        options={YEARS_END}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        defaultValue={endDate}
                                    />
                                )}
                            />
                            {errors[`end_date_${index}`]?.message && (
                                <div className="error">{errors[`end_date_${index}`]?.message}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="edit-experience-modal">
            <div className="header-modal">
                Добавить опыт работы
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x"/>
                </div>
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                {
                    renderExperienceCount(workExperiences)
                }
                <div className="add-more">
                    <button
                        onClick={addMore}
                    >
                        Добавить еще
                    </button>
                </div>
                <div className="actions">
                    <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                        Отменить
                    </button>
                    <button type='submit' className="confirm">
                        Сохранить
                    </button>
                </div>
            </form>
        </div>
    )
})

export { EditExperienceModal }