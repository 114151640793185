import './index.scss';
import { observer } from 'mobx-react-lite';
import XImg from '@assets/svg/x.svg';
import { Context } from '@/index';
import { useContext, useRef, useState, useEffect } from 'react';
import { updateSkills as updateSkillsApi } from '@api/user-api';
import { toast } from 'react-toastify';
import { toastOptions } from '@shared/index';

const EditSkillsModal = observer(({ savedSkills = [], callback }) => {
    const { modal } = useContext(Context);
    const [ inputValue, setInputValue ] = useState('');
    const [ skills, setSkills ] = useState([]);
    const [ limit, setLimit ] = useState(false);
    const inputRef = useRef(null);

    const handleClick = () => {
        inputRef.current.focus();
    }

    useEffect(() => {
        setSkills(savedSkills);
    }, []);

    useEffect(() => {
        if(skills.length > 14) {
            setLimit(true);
        } else {
            setLimit(false);
        }

    }, [skills])

    const handleOnChange = (e) => {
        setInputValue(e.target.value);
        let { value } = e.target;
        if(value.charAt(value.length-1) === ',') {
            let newSkill = value.slice(0, value.length-1);
            if(newSkill) {
                setSkills((prevState) => {
                    return [...prevState, newSkill];
                })
            }
            setInputValue('');
        }
    }

    const removeSkill = (e) => {
        const name = e.target.getAttribute('name');
        setSkills(skills.filter(item => item !== name));
    }

    const onSubmit = () => {
        updateSkillsApi({name: skills})
        .then(() => {
            callback();
            toast.error('Навыки успешно созранены', toastOptions);
            modal.closeModal();
        })
        .catch(() => {
            callback();
            toast.error('Произошла ошибка изменения раздела Навыки', toastOptions);
            modal.closeModal();
        })
    }

    return (
        <div className="edit-skills-modal">
            <div className="header-modal">
                Редактировать навыки
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x"/>
                </div>
            </div>
            <div className="subtitle">
                Укажите список навыков, разделяя их запятой
            </div>
            <div
                className="skills"
                onClick={handleClick}
            >
                {skills.map((skill, index) => 
                    <div
                        key={`skill_${index}`}
                        className="skill"
                    >
                        {skill}
                        <div
                            name={skill}
                            className="remove-skill"
                            onClick={(e) => removeSkill(e)}
                        >
                            <img 
                                name={skill}
                                src={XImg}
                            />
                        </div>
                    </div>
                )}
                <input
                    ref={inputRef}
                    type="text"
                    className="hidden-input"
                    value={inputValue}
                    onChange={handleOnChange}
                    disabled={limit}
                />
            </div>
            <div className="tip">
                Максимум 15 навыков
            </div>
            <div className="actions">
                <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                    Отменить
                </button>
                <button type='button' className="confirm" onClick={onSubmit}>
                    Сохранить
                </button>
            </div>
        </div>
    )
})

export { EditSkillsModal }