import { observer } from 'mobx-react-lite'
import './index.scss'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext } from 'react'
import { changeTaskStatus as changeTaskStatusApi } from '@api/task-api';

const ConfirmCloseTaskModal = observer(({taskId, taskName, callGetTask}) => {
    const { modal } = useContext(Context);

    const confirmCloseTask = () => {
        const object = {
            task_status: 'closed'
        }
        changeTaskStatusApi(taskId, object)
        .then(() => {
            callGetTask();
            modal.closeModal();
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <div className="confirm-close-task-modal">
            <div className="header-modal">
                Подтвердите закрытие заказа
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x" alt="close"/>
                </div>
            </div>
            <div className="description">
                Вы собираетесь закрыть заказ 
                <span className='description-bold'> {taskName}</span>
                , который находится на стадии выполнения. Вы уверены, что хотите его завершить?
            </div>
            <div className="row">
                <div className="col-md-6">
                    <button type="button" className="my-button2" onClick={() => modal.closeModal()}>Закрыть</button>
                </div>
                <div className="col-md-6">
                    <button type="button" className="my-button red-button" onClick={confirmCloseTask}>Закрыть заказ</button>
                </div>
            </div>
            
        </div>
    )
})

export { ConfirmCloseTaskModal }