import './index.scss';
import { observer } from 'mobx-react-lite';
import Table from 'react-bootstrap/Table';
import StatusIconGreen from '@assets/svg/user-status-icon-green.svg';
import StatusIconGrey from '@assets/svg/user-status-icon-grey.svg';
import StatusIconYellow from '@assets/svg/user-status-icon-yellow.svg';
import { formatDate } from '@utils/utils';
import { useNavigate } from 'react-router-dom'

const TaskHistoryList = observer(({ tasks }) => {
    let navigate = useNavigate();

    const getStatusIcon = (status) => {
        switch (status) {
            case 'closed':
                return StatusIconGrey;
            case 'in_progress':
                return StatusIconYellow;
            default:
                return StatusIconGreen;
        }
    }
    const getStatusText = (status) => {
        switch (status) {
            case 'new':
                return 'Новый';
            case 'in_progress':
                return 'В работе';
            case 'submission':
                return 'Отправлен на проверку';
            case 'done':
                return 'Закончен';
            case 'closed':
                return 'Закрыт';
            default:
                return 'Новый';
        }
    }

    const goToTask = (id) => {
        navigate(`/tasks/${id}`, { replace: true });
    };

    return (
        <div className="task-history-list">
            <Table>
                <thead>
                    <tr className="border-bottom-white">
                        <th className="header-title">Название</th>
                        <th className="header-title desktop-only">Размещен</th>
                        <th className="header-title">Сумма заказа</th>
                        <th className="header-title desktop-only">Срок сдачи</th>
                        <th className="header-title">Статус</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks && tasks.map((task, index) =>
                        <tr className="task" key={`task_${index}`} onClick={() => {goToTask(task.id)}}>
                            <td className="task-title">{task.title}</td>
                            <td className="task-created desktop-only">{formatDate(new Date(task.created))}</td>
                            <td className="task-price">{task.price > 0 ? task.price.toLocaleString('ru-RU') : ''} ₽</td>
                            <td className="task-deadline desktop-only">{task.deadline} часа</td>
                            <td className="task-status">
                                <img src={getStatusIcon(task.task_status)} />
                                {getStatusText(task.task_status)}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
})

export { TaskHistoryList }