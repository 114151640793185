import './index.scss'
import { observer } from 'mobx-react-lite'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext, useEffect } from 'react'
import { AttachedFilesFromUrls } from '@Components/AttachedFilesFromUrls';
import { AttachedLinks } from '@Components/AttachedLinks';


const PortfolioProjectModal = observer(({ project }) => {
    const { modal } = useContext(Context);

    return (
        <div className="portfolio-project-modal">
            <div className="header-modal portfolio-project-header">
                {project.name}
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x" alt="close" />
                </div>
            </div>
            <div className="description">
                {project.description}
            </div>
            {
                project.document_urls && project.document_urls.length > 0 &&
                <div className="attached-files">
                    <div className="attached-files-header">
                        Прикреплённые файлы
                    </div>
                    <AttachedFilesFromUrls document_urls={project.document_urls} />
                </div>
            }
            {
                project.urls && project.urls.length > 0 &&
                <div className="attached-links-block">
                    <div className="attached-links-header">
                        Прикреплённые ссылки
                    </div>
                    <AttachedLinks urls={project.urls} />
                </div>
            }
        </div>
    )
});

export { PortfolioProjectModal }