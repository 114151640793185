import './index.scss'
import { observer } from 'mobx-react-lite'
import InternalServerErrorPageImg from '@assets/img/500-error.png'


const InternalServerErrorPage = observer(() => {

    return (
        <div className="internal-server-error-page">
            <div className="container">
                <div className="error-title">
                    Упс... Кое-что сломалось, но мы уже начали чинить
                </div>
                <div className="error-image">
                    <img src={InternalServerErrorPageImg} />
                </div>
            </div>
        </div>
    )
})

export { InternalServerErrorPage }