import { observer } from 'mobx-react-lite'
import './index.scss'
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Context } from '@/index'
import { useContext } from 'react'

const ModalComponent = observer(() => {
    const { freeze } = useContext(Context);

    return (
        <Modal 
            show={freeze.isFreeze} 
            onHide={() => freeze.closeFreeze()}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>
                <div className="freeze-modal">
                    <div className="message-spinner">
                        <div className="spinner">
                            <Spinner animation="border" />
                        </div>
                        <div className="message">
                            Подождите, Ваша транзакция обрабатывается.<br />
                            {' '}<span className="bold">Не обновляйте страницу.</span>{' '}
                            При необходимости Вы будете перенаправлены на страницу банка.
                        </div>
                    </div>
                    {freeze.redirect &&
                        <div className="redirect">
                            <div
                                className="confirm"
                                onClick={() => {
                                    let redirectUrl = freeze.redirect;
                                    window.open(redirectUrl, '_blank');
                                    freeze.setRedirect(null);
                                }}
                            >
                                Переход на страницу банка
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>   
    )
})

export default ModalComponent