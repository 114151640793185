import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom';
import StarImg from '@assets/svg/star.svg';
import ProfilePlaceholderImg from '@assets/img/profile-placeholder.jpg';
import { ChatComponent } from '@Components/Chat';
import { RespondModal } from '@Components/RespondModal';
import './index.scss'
import { useParams } from "react-router-dom";
import { getTask } from '@api/task-api';
import { likeTask as likeTaskApi, removeLikeTask as removeLikeTaskApi, blockTask as blockTaskApi, removeBlockTask as removeBlockTaskApi } from '@api/task-api';
import { getReviews as getReviewsApi } from '@api/review-api.js';
import { useState, useEffect, useContext } from 'react';
import { Context } from '@/index';
import { AttachedFilesFromUrls } from '@Components/AttachedFilesFromUrls';
import { AttachedLinks } from '@Components/AttachedLinks';
import { SubmitTaskModal } from '@Components/SubmitTaskModal';
import { ConfirmSubmitTaskModal } from '@Components/ConfirmSubmitTaskModal';
import { ConfirmCloseTaskModal } from '@Components/ConfirmCloseTaskModal';
import { FeedbackModal } from '@Components/FeedbackModal';
import { ReviewComponent } from '@Components/ReviewComponent';
import { LoginPage } from '@Pages/LoginPage';
import { EditTaskModal } from '@Components/EditTaskModal';
import { getNumberRatingReviews, taskDeadlineCalculation } from '@utils/utils';
import config from '@/config';
import { toast } from 'react-toastify';
import { toastOptions } from '@shared/index';

const TaskPage = observer(() => {
    const { modal, chatStore, userStore } = useContext(Context);
    let { id } = useParams();
    const [ task, setTask] = useState({});
    const [ showLeaveReviewButton, setShowLeaveReviewButton] = useState(false);
    const [ isLiked, setIsLiked ] = useState(false);
    const [ isBlocked, setIsBlocked ] = useState(false);

    useEffect(() => {
        getTaskF();
    }, [id]);

    const likeTask = (e) => {
        e.preventDefault();
        if (isLiked) {
            setIsLiked(false);
            removeLikeTaskApi(task.id)
            .then(() => {
                console.log('success');
            })
            .catch((err) => {
                console.log(err);
            });
        } else {
            setIsLiked(true);
            likeTaskApi(task.id)
            .then(() => {
                console.log('success');
            })
            .catch((err) => {
                let { data, status } = err.response;
                if (status === 401 && data.detail === "Authentication credentials were not provided.") {
                    modal.showModal(<LoginPage/>);
                } else {
                    console.log(err);
                }
            });
        }
    }
    const blockTask = (e) => {
        e.preventDefault();
        if(isBlocked) {
            setIsBlocked(false);
            removeBlockTaskApi(task.id)
            .then(() => {
                console.log('success');
            })
            .catch((err) => {
                console.log(err);
            });
        } else {
            setIsBlocked(true);
            blockTaskApi(task.id)
            .then(() => {
                console.log('success');
            })
            .catch((err) => {
                let { data, status } = err.response;
                if (status === 401 && data.detail === "Authentication credentials were not provided.") {
                    modal.showModal(<LoginPage/>);
                } else {
                    console.log(err);
                }
            });
        }
    }

    const getTaskF = async () => {
        const result = await getTask(id);
        // console.log(result);
        setTask(result.data);
        if (result.data) {setIsLiked(result.data.liked);}
        if (userStore.user && result.data.task_type === 1) {
            if (userStore.user && result.data.created_user.id === userStore.user.id) {
                chatStore.setRooms(result.data.rooms);
            } else {
                const foundRoom = result.data.rooms.find(r => r.responded_user.id === userStore.user.id);
                if (foundRoom) {
                    const roomArrayWithOneElement = [foundRoom];
                    chatStore.setRooms(roomArrayWithOneElement);
                }
                
            }
        }
        // Показывать ли кнопку Оставить отзыв
        const tempTask = result.data;
        if (tempTask && tempTask.created_user && userStore.user && tempTask.assigned_user) {
            let response = {};
            if (tempTask.created_user.id === userStore.user.id) {
                response = await getReviewsApi(tempTask.assigned_user.id, 0, tempTask.created_user.id);
            } else {
                response = await getReviewsApi(tempTask.created_user.id, 0, tempTask.assigned_user.id);
            }
            if (response && response.data && response.data.count === 0) {
                setShowLeaveReviewButton(true);
            }
        }
    }

    const submittedReview = () => {
        setShowLeaveReviewButton(false);
        toast.success('Спасибо за оставленный отзыв', toastOptions);
    }

    const showButtons = () => {
        if (task && task.created_user && userStore.user) {
            // Хозяин задания
            if (task.created_user.id === userStore.user.id) {
                const submission = task.task_status === 'submission';
                const done = task.task_status === 'done';
                const closed = task.task_status === 'closed';
                return (
                    <div className="buttons">
                        {
                            (submission || done || closed) &&
                            <button type="button" 
                                    className={"my-button " + 
                                        (done || closed ? 'already-responded-button' : 'margin-bottom-16') +
                                        (done ? ' margin-bottom-16' : '')
                                    } disabled={done || closed ? true : false}
                                    onClick={
                                        () => modal.showModal(<ConfirmSubmitTaskModal taskId={id} callGetTask={getTaskF} />)
                                    }>
                                {submission && 'Принять заказ'}
                                {done && 'Заказ выполнен'}
                                {closed && 'Заказ закрыт'}
                            </button>
                        }
                        {
                            !closed && !done &&
                            <button type="button" className="my-button red-button"
                                    onClick={
                                        () => modal.showModal(<ConfirmCloseTaskModal taskId={id} taskName={task.title} callGetTask={getTaskF} />)
                                    }>
                                {task.task_type === 1 ? 'Закрыть заказ' : 'Закрыть услугу'}
                            </button>
                        }
                        {
                            done && task.assigned_user && showLeaveReviewButton &&
                            <button type="button" className="my-button"
                                    onClick={
                                        () => modal.showModal(<FeedbackModal userId={task.assigned_user.id} reviewUserId={task.created_user.id} callback={submittedReview} />)
                                    }>
                                Оставить отзыв
                            </button>
                        }
                    </div>
                )
            }
            if (task.task_type === 1) {
                if (task.assigned_user && task.assigned_user.id === userStore.user.id) {
                    const new_status = task.task_status === 'new';
                    const submitted = task.task_status === 'submission';
                    const done = task.task_status === 'done';
                    const closed = task.task_status === 'closed';
                    const in_progress = task.task_status === 'in_progress';
                    return (
                        <div className="buttons">
                            {
                                (new_status || submitted || in_progress) &&
                                <button type="button" className={"my-button" + (submitted ? ' already-responded-button' : '')} disabled={submitted ? true : false}
                                    onClick={
                                        () => modal.showModal(<SubmitTaskModal taskId={id} callGetTask={getTaskF} />)
                                    }>
                                        {
                                            submitted ? '👍 Работа отправлена на проверку' : 'Сдать работу'
                                        }
                                </button>
                            }
                            {
                                (done || closed) &&
                                <button type="button" className="my-button already-responded-button margin-bottom-16" disabled>
                                    {
                                        done ? 'Заказ выполнен' : 'Заказ закрыт'
                                    }
                                </button>
                            }
                            {
                                (done || closed) && showLeaveReviewButton &&
                                <button type="button" className="my-button"
                                    onClick={
                                        () => modal.showModal(<FeedbackModal userId={task.created_user.id} reviewUserId={task.assigned_user.id} callback={submittedReview} />)
                                    }>
                                        Оставить отзыв
                                </button>
                            }
                        </div>
                    )
                } else {
                    const foundRoom = task.rooms.find(r => r.responded_user.id === userStore.user.id);
                    if (task.assigned_user?.id && userStore.user.id !== task.assigned_user.id) {
                        return (
                            <div className="buttons">
                                <button type="button" className="my-button already-responded-button" disabled={true}>
                                    Заказчик уже выбрал исполнителя
                                </button>
                            </div>
                        )
                    } else {
                        return (
                            <div className="buttons">
                                <button type="button" className={"my-button" + (foundRoom ? ' already-responded-button' : '')} disabled={foundRoom ? true : false}
                                    onClick={
                                        () => modal.showModal(<RespondModal createdUser={task.created_user.id} taskId={id} callGetTask={getTaskF} />)
                                    }>
                                        {
                                            foundRoom ? 'Заявка отправлена' : 'Откликнуться'
                                        }
                                        
                                </button>
                            </div>
                        )
                    }
                }
            } else {
                if (userStore.isFreelancer) {
    
                } else {
                    
                }
            }
        }
        if (task && !userStore.user) {
            return (
                <div className="buttons">
                    <button type="button" className="my-button"
                        onClick={
                            () => modal.showModal(<LoginPage />)
                        }>
                            Откликнуться
                    </button>
                </div>
            )
        }
    }

    return (
        <div className="task-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        {
                            userStore.user && task.assigned_user && chatStore.rooms.length > 0 &&
                            <ChatComponent index={0} task={task} callGetTask={getTaskF} assigned={true}></ChatComponent>
                        }
                        <div className="white-box main-block">
                            <div className="category-block d-flex justify-content-between">
                                <div className="category d-flex">
                                    {
                                        task.task_type === 1 && task.task_status === 'new' && task.assigned_user &&
                                        <div className="category-type">
                                            Заказ в работе
                                        </div>
                                    }
                                    
                                    {task.category?.name}
                                </div>
                                <div className="like-block-block">
                                    {
                                        task.created_user && userStore.user && task.created_user.id === userStore.user.id ?
                                        <span className="edit-icon" onClick={() => {modal.showModal(<EditTaskModal taskId={id} task={task} programmingLanguage={JSON.stringify(task.programming_language)} callGetTask={getTaskF} />)}}></span>
                                        :
                                        <span className={"heart " + (isLiked ? 'heart-clicked' : '')} onClick={likeTask}></span>
                                        // <span className="like-icon"></span>
                                    }
                                    
                                    {
                                        task.created_user && userStore.user && task.created_user.id !== userStore.user.id && 
                                        <span className={"block " + (isBlocked ? 'block-clicked' : '')} onClick={blockTask}></span>
                                        // <span className="block-icon"></span>
                                    }
                                    
                                </div>
                            </div>
                            <div className="name">
                                {task.title}
                            </div>
                            <div className="description">
                                {task.description}
                            </div>
                            {
                                task.document_urls && task.document_urls.length > 0 &&
                                <div>
                                    <div className="attached-files-header">
                                        Прикреплённые файлы
                                    </div>
                                    <AttachedFilesFromUrls document_urls={task.document_urls} />
                                </div>
                            }
                            {
                                task.urls && task.urls.length > 0 &&
                                <div className="attached-links">
                                    <div className="attached-links-header">
                                        Прикреплённые ссылки
                                    </div>
                                    <AttachedLinks urls={task.urls} />
                                </div>
                            }
                            <div className="show-button-block">
                                {
                                    showButtons()
                                }
                            </div>
                            
                        </div>
                        {
                            userStore.user && !task.assigned_user &&
                            chatStore.rooms.map((room, i) => {
                                if (room) {
                                    return (
                                        <div key={i}>
                                            <ChatComponent index={i} task={task} callGetTask={getTaskF}></ChatComponent>
                                        </div>
                                    )
                                }
                            })
                        }
                        
                    </div>
                    <div className="col-md-4">
                        <div className="white-box price-block">
                            <div className="deadline">
                                {`${taskDeadlineCalculation(task.deadline)} на выполнение`}
                            </div>
                            <div className="price">
                                {task.price > 9999 ? task.price.toLocaleString('ru-RU') : task.price} ₽
                            </div>
                            <div className="buttons">
                                {
                                    showButtons()
                                }
                            </div>
                        </div>
                        <Link to={task.created_user?.id && `/profile/${task.created_user.id}?role=${task.task_type === 1 ? 'manager' : 'freelancer'}`}>
                            <div className="white-box profile-block">
                                <div className="profile-img-block">
                                    <img src={task?.created_user && task?.created_user?.image ? config.strapi.image_url + '/' + task.created_user.image : ProfilePlaceholderImg} alt="profile" className="profile-img"/>
                                </div>
                                <div className="name">
                                    {task.created_user?.first_name} {task.created_user?.last_name}
                                </div>
                                <div className="boss">
                                    {task.task_type === 1 && 'Заказчик'}
                                    {task.task_type === 2 && (task.created_user?.specialization ? task.created_user.specialization  : 'Исполнитель')}
                                </div>
                                <div className="reviews">
                                    <img src={StarImg} alt="block" className="star-img"/>
                                    {task.created_user?.rating >= 0 && task.created_user.rating.toFixed(1)}
                                    {task?.created_user?.number_rating >= 0 && ` (${task.created_user.number_rating} ${getNumberRatingReviews(task.created_user.number_rating)})`}
                                </div>
                                <div className="line d-flex justify-content-between">
                                    <div className="line-left">Опубликованных заказов</div>
                                    <div className="line-right">{task?.created_user?.total_tasks}</div>
                                </div>
                                <div className="line d-flex justify-content-between">
                                    <div className="line-left">Завершенных заказов</div>
                                    <div className="line-right">{task?.created_user?.total_done_tasks}</div>
                                </div>
                                <div className="line d-flex justify-content-between">
                                    <div className="line-left">Активных заказов</div>
                                    <div className="line-right">{task?.created_user?.total_in_progress_tasks}</div>
                                </div>
                            </div>
                        </Link>
                        {
                            task && task.created_user &&
                            <div className="white-box reviews-block">
                                <ReviewComponent userId={task.created_user.id} />
                            </div>
                        }
                        
                        
                    </div>
                </div>
            </div>
        </div>
    )
})

export { TaskPage }