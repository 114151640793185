import { observer } from 'mobx-react-lite'
import './index.scss'


const UsersPage = observer(() => {

    return (
        <div className="users-page">
            <div className="container">
            </div>
        </div>
    )
})

export { UsersPage }