import './index.scss';
import { observer } from 'mobx-react-lite';

const AttachedLinks = observer(({ urls }) => {
    return (
        <div className="attached-links">
            <div className="links">
                {urls && urls.map((url, index) =>
                    <div className="link" key={`url_${index}`}>
                        <a href={url} target="_blank" rel="noreferrer">{`${index + 1}. ${url}`}</a>
                    </div>
                )}
            </div>
        </div>
    );
})

export { AttachedLinks }