import config from '../config';
import instance from './axios-instance';

const STRAPI_API_URL = config.strapi.api_url + '/payment';

export const sendMoney = (payload) => {
    return instance.post(`${STRAPI_API_URL}/`, payload)
};

export const getTransactionStatus = (transactionId) => {
    return instance.get(`${STRAPI_API_URL}/transactions/${transactionId}/`)
};

export const receiveMoney = (payload) => {
    return instance.post(`${STRAPI_API_URL}/payout/`, payload)
};

export const getMyTransactions = (page) => {
    if (Number(page) > 1) {
        let params = {};
        params.page = page;
        return instance.get(`${STRAPI_API_URL}/my-transactions/`, { params });
    }
    return instance.get(`${STRAPI_API_URL}/my-transactions/`);
}