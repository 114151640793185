import { makeAutoObservable } from 'mobx';
import { getProgrammingLanguagesCount as getProgrammingLanguagesCountApi } from '@api/task-api';

export default class ProgrammingLanguagesStore {
  constructor() {
    this._isLoading = false
    this._programmingLanguages =  []
    this.getProgrammingLanguages()
    makeAutoObservable(this)
  }

  get items() {
    return this._programmingLanguages
  }

  get isLoading() {
    return this._isLoading
  }

  setItems(items) {
    this._programmingLanguages = items
  }

  setIsLoading(bool) {
    this._isLoading = bool
  }

  getProgrammingLanguages = async () => {
    this.setIsLoading(true)
    await getProgrammingLanguagesCountApi()
      .then((res) => {
        this.setItems(res.data)
        this.setIsLoading(false)
      })
      .catch((error) => {
        this.setItems([])
        this.setIsLoading(false)
      })
  }
}
