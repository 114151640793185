import { makeAutoObservable } from 'mobx'
import { getTaskCategories } from '@api/task-api'

export default class TasksCategoriesStore {
  constructor() {
    this._isLoading = false
    this._taskCategories =  []
    this.getTasksCategories()
    makeAutoObservable(this)
  }

  get items() {
    return this._taskCategories;
  }

  get isLoading() {
    return this._isLoading
  }

  setItems(tasks) {
    this._taskCategories = tasks
  }

  setIsLoading(bool) {
    this._isLoading = bool
  }

  getTasksCategories = async () => {
    this.setIsLoading(true)
    await getTaskCategories()
      .then((res) => {
        this.setItems(res.data)
        this.setIsLoading(false)
      })
      .catch((error) => {
        this.setItems([])
        this.setIsLoading(false)
      })
  }
}
