import { observer } from 'mobx-react';
import LogoImg from '@assets/svg/logo.svg';
import SearchImg from '@assets/svg/header/search-header.svg';
import PlusImg from '@assets/svg/header/plus-header.svg';
import HeartImg from '@assets/svg/header/heart-header.svg';
import BookImg from '@assets/svg/header/book-header.svg';
import NotificationsImg from '@assets/svg/header/notifications-header.svg';
import NotificationsDropdownImg from '@assets/svg/header/dropdown/notifications.svg';
import UserTypeImg from '@assets/svg/header/user-type.svg';
import ExitDropdownImg from '@assets/svg/header/dropdown/exit.svg';
import PasswordDropdownImg from '@assets/svg/header/dropdown/password.svg';
import PaymentDropdownImg from '@assets/svg/header/dropdown/payment.svg';
import PencilDropdownImg from '@assets/svg/header/dropdown/pencil.svg';
import ProfilePlaceholder from '@assets/img/profile-placeholder.jpg';
import UserImg from '@assets/svg/user.svg';
import './index.scss';
import { LoginPage } from '@Pages/LoginPage';
import { CreateTaskPage } from '@Pages/CreateTaskPage';
import { Context } from '@/index';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react';
import config from '@/config';
import { BalancePopup } from '@Components/BalancePopup';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { NotificationsPopup } from '@Components/NotificationsPopup';
import { numberWithSpaces } from '@utils/utils';

const Header = observer(() => {
    const { modal, userStore } = useContext(Context);
    const [ isSticky, setIsSticky] = useState(false);
    let navigate = useNavigate();
    const balancePopup = useRef(null);
    const navbarRef = useRef(null);

    const handleScroll = () => {
        if (window.pageYOffset >= 250) {
            setIsSticky(true);
        } else {
            if(window.pageYOffset < 150) {
                setIsSticky(false);
            } 
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleHeartClick = () => {
        if (userStore.user) {
            navigate("/favorite", { replace: true });
        } else {
            modal.showModal(<LoginPage/>);
        }
    };

    const handleBookClick = () => {
        if (userStore.user) {
            navigate("/task-history", { replace: true });
        } else {
            modal.showModal(<LoginPage/>);
        }
    };

    const handleNotificationsClick = () => {
        if (userStore.user) {
            navigate("/notification", { replace: true });
        } else {
            modal.showModal(<LoginPage/>);
        }
    };

    return (
        <header className="header-area overlay">
            <nav ref={navbarRef} className={"navbar navbar-expand navbar-dark " + (isSticky ? "fixed-top" : "")}>
                <div className="container">
                    <a href="/" className="navbar-brand">
                        <img src={LogoImg} />
                    </a>
                    
                    <div id="main-nav" className="collapse navbar-collapse">
                        <ul className="navbar-nav first ms-md-auto">
                            <OverlayTrigger
                                key="tasks"
                                placement="bottom"
                                overlay={(props) => (
                                    <Tooltip
                                        {...props}
                                        id="tooltip-bottom"
                                    >
                                        Поиск
                                    </Tooltip>
                                )}
                            >
                                <li>
                                    <Link to="/tasks" key="search" className="nav-item nav-link">
                                        <span className="helper"></span>
                                        <img src={SearchImg} className="img"/>
                                    </Link>
                                </li>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key="create"
                                placement="bottom"
                                overlay={(props) => (
                                    <Tooltip
                                        {...props}
                                        id="tooltip-bottom"
                                    >
                                        {userStore.isFreelancer ? "Создать услугу" : "Создать заказ"}
                                    </Tooltip>
                                )}
                            >
                                <li>
                                    <a className="nav-item nav-link" onClick={() => userStore.user ? modal.showModal(<CreateTaskPage />) : modal.showModal(<LoginPage/>)}>
                                        <span className="helper"></span>
                                        <img src={PlusImg} className="img"/>
                                    </a>
                                </li>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key="favorite"
                                placement="bottom"
                                overlay={(props) => (
                                    <Tooltip
                                        {...props}
                                        id="tooltip-bottom"
                                    >
                                        Избранное
                                    </Tooltip>
                                )}
                            >
                                <li>
                                    <a className="nav-item nav-link" onClick={handleHeartClick}>
                                        <span className="helper"></span>
                                        <img src={HeartImg} className="img"/>
                                    </a>
                                </li>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key="task-history"
                                placement="bottom"
                                overlay={(props) => (
                                    <Tooltip
                                        {...props}
                                        id="tooltip-bottom"
                                    >
                                        История заказов
                                    </Tooltip>
                                )}
                            >
                                <li className="diplay-phone-none">
                                    <a className="nav-item nav-link" onClick={handleBookClick}>
                                        <span className="helper"></span>
                                        <img src={BookImg} className="img"/>
                                    </a>
                                </li>
                            </OverlayTrigger>
                            <li className="diplay-phone-none">
                                <div className="nav-item nav-link notifications-block">
                                    <a onClick={handleNotificationsClick}>
                                        <span className="helper"></span>
                                        <img src={NotificationsImg} className="img"/>
                                    </a>
                                    <NotificationsPopup />
                                </div>
                            </li>
                            {userStore.user && (
                                <li className="diplay-phone-none">
                                    <div ref={balancePopup} className="nav-item nav-link balance-block">
                                        <Link to="/edit-profile/3">
                                            <span className="helper"></span>
                                            <span className="balance">{numberWithSpaces(userStore.user.balance)} ₽</span>
                                        </Link>
                                        <ul className="dropdown">
                                            <BalancePopup balancePopupRef={balancePopup}/> 
                                        </ul>
                                    </div>
                                </li>
                            )}
                        </ul>
                        <ul className="navbar-nav">
                            <li>
                                {
                                    userStore.user ?
                                    <div className="nav-item nav-link registered-block">
                                        <Link to={"/profile/" + userStore.user.id} className="flex-link">
                                            <img className="user-img" src={userStore.user && userStore.user.image ? config.strapi.image_url + '/' + userStore.user.image : ProfilePlaceholder} />
                                            <span className="diplay-phone-none user-first-name-last-name">
                                                {
                                                    userStore && userStore.user && userStore.user.first_name + 
                                                    (userStore.user.last_name.length > 0 ? ` ${userStore.user.last_name[0]}.` : '')
                                                }
                                            </span>
                                        </Link>
                                        <ul className="dropdown">
                                            <li><Link to={"/profile/" + userStore.user.id}  className="dropdown-item" >
                                                <img src={UserImg}/>
                                                <span>Профиль</span>
                                            </Link></li>
                                            <li><Link to="/edit-profile/1" className="dropdown-item" >
                                                <img src={PencilDropdownImg}/>
                                                <span>Редактировать профиль</span>
                                            </Link></li>
                                            <li className="mobile-only"><Link to="/task-history" className="dropdown-item">
                                                <img src={BookImg}/>
                                                <span>История заказов</span>
                                            </Link></li>
                                            <li className="mobile-only"><Link to="/notification" className="dropdown-item">
                                                <img src={NotificationsImg}/>
                                                <span>Уведомления</span>
                                            </Link></li>
                                            <li><Link to="/edit-profile/2" className="dropdown-item" >
                                                <img src={PasswordDropdownImg}/>
                                                <span>Пароль</span>
                                            </Link></li>
                                            <li><Link to="/edit-profile/3" className="dropdown-item" >
                                                <img src={PaymentDropdownImg}/>
                                                <span>Баланс</span>
                                            </Link></li>
                                            {/* <li><Link to="/edit-profile/4" className="dropdown-item" >
                                                <img src={NotificationsDropdownImg}/>
                                                <span>Настройки уведомлений</span>
                                            </Link></li> */}
                                            <hr/>
                                            <li><div className="user-type" onClick={(e) => {e.preventDefault()}}>
                                                <div>
                                                    <img src={UserTypeImg}/>
                                                    <span>{userStore.isFreelancer ? 'Исполнитель' : 'Заказчик'}</span>
                                                </div>
                                                <div>
                                                    <input type="checkbox" checked={userStore.isFreelancer} onChange={() => {}} />
                                                    <label onClick={() => userStore.setIsFreelancer(!userStore.isFreelancer)}></label>
                                                </div>
                                            </div></li>    
                                            <hr/>
                                            <li><a className="dropdown-item" onClick={(e) => {e.preventDefault(); userStore.logout(); navigate('/')}}>
                                                <img src={ExitDropdownImg}/>
                                                <span>Выйти</span>
                                            </a></li>
                                        </ul>
                                    </div>
                                    :
                                    <a className="nav-item nav-link" onClick={() => modal.showModal(<LoginPage />)}>
                                        <img src={UserImg} className="img"/>
                                    </a>
                                }
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>
        </header>
    )
})

export { Header }