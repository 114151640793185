import './index.scss'
import { observer } from 'mobx-react-lite'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { deleteUser as deleteUserApi } from '@api/user-api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { toastOptions } from '@shared/index';

const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Введите пароль'),
});

const DeleteAccountModal = observer(() => {
    const { modal, userStore } = useContext(Context);
    const [ deleteUserError, setDeleteUserError ] = useState('');
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, watch, register, formState: { errors } } = useForm(formOptions);
    let navigate = useNavigate();

    useEffect(() => {
        const subscription = watch((value) => {
            const { password } = value;
            if(password || password === '') {
                setDeleteUserError('');
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit = (data) => {
        console.log(data);
        deleteUserApi(data)
        .then(() => {
            toast.success('Аккаунт удален', toastOptions);
            userStore.logout();
            navigate('/');
            modal.closeModal();
        })
        .catch((err) => {
            console.log(err.response);
            setDeleteUserError(err.response.data[0]);
            toast.error('Ошибка удаления аккаунт', toastOptions);
        });
    }

    return (
        <div className="delete-account-modal">
            <div className="header-modal delete-account-header">
                Подтвердите удаление
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x" alt="close" />
                </div>
            </div>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control-block">
                    <label className="form-label">
                        Нам очень жаль, что Вы хотите удалить свой аккаунт. Как только Вы нажмете кнопку ниже все данные будут безвозвратно удалены без возможности восстановления.
                    </label>
                    <div className="confirmation">
                        Для завершения, пожалуйста, введите пароль от учетной записи.
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <input 
                                type="password" 
                                className="form-control" 
                                placeholder=""
                                {...register("password")}
                            />
                            {errors.password?.message && (
                                <p className="error">{errors.password?.message}</p>
                            )}
                            {deleteUserError && (
                                <p className="error">{deleteUserError}</p>
                            )}
                            
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                        Закрыть
                    </button>
                    <button type='submit' className="confirm">
                        Удалить аккаунт
                    </button>
                </div>
            </form>
        </div>
    )
})

export { DeleteAccountModal }