import './index.scss';
import { Context } from '@/index';
import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AddCardModal } from '@Components/AddCardModal';
import { DeleteCardModal } from '@Components/DeleteCardModal';
import { WithdrawDepositModal } from '@Components/WithdrawDepositModal';
import Table from 'react-bootstrap/Table';
import StarImg from '@assets/img/star.png';
import WithdrawImg from '@assets/svg/withdraw.svg';
import DepositImg from '@assets/svg/deposit.svg';
import FourDigitsBlackImg from '@assets/svg/four-digits-black.svg';
import CardRadioButtonImg from '@assets/svg/card-radio-button.svg';
import CardRadioButtonActiveImg from '@assets/svg/card-radio-button-active.svg';
import FourDigitsIcon from '@assets/svg/four-digits.svg';
import MastercardIcon from '@assets/svg/balance-mastercard.svg'; 
import VisaIcon from '@assets/svg/balance-visa.svg';
import MirIcon from '@assets/svg/balance-mir.svg';
import { getMyTransactions as getMyTransactionsApi } from '@api/payment-api';
import { updateCreditCard as updateCreditCardApi } from '@api/user-api';
import { formatDate, numberWithSpaces } from '@utils/utils';
import { useLocation } from 'react-router-dom';
import { DepositSuccessModal } from '@Components/DepositSuccessModal';
import { DepositFailedModal } from '@Components/DepositFailedModal';
import { toast } from 'react-toastify';
import { toastOptions } from '@shared/index';

const cardType = (type) => {
    switch (type) {
        case 'mastercard':
            return MastercardIcon;
        case 'visa':
            return VisaIcon;
        case 'mir':
            return MirIcon;
    }
}

const getOperationType = (type) => {
    switch (type) {
        case false:
            return (
                <>
                    <img src={DepositImg} />
                    Пополнение
                </>
            );
        case true:
            return (
                <>
                    <img src={WithdrawImg} />
                    Вывод
                </>
            );
    }
} 

const EditBalanceComponent = observer(() => {
    const { userStore, modal } = useContext(Context);
    const [ selectedCard, setSelectedCard ] = useState(0);
    const [ selectedCardId, setSelectedCardId ] = useState(null);
    const [ myTransactions, setMyTransactions ] = useState([]);
    const [ bankCardNames, setBankCardNames ] = useState({});
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let status = params.get('status');
        let transactionId = params.get('transactionId');
        if (status) {
            switch (status) {
                case 'success':
                    modal.showModal(<DepositSuccessModal transactionId={transactionId} />);
                    break;
                case 'failed':
                    modal.showModal(<DepositFailedModal transactionId={transactionId} />);
                    break;
            }
        }
    }, [location]);

    useEffect(() => {
        if(userStore.user.credit_cards) {
            let cards = {};
            userStore.user.credit_cards.forEach((item) => {
                cards[item.id] = item.bank_name || 'Карта банка';
            })
            setBankCardNames(cards);
            const cardIdsArray = userStore.user.credit_cards.map(card => card.id);
            if (cardIdsArray.indexOf(selectedCardId) === -1) {
                let minCardId = Math.min(...cardIdsArray);
                setSelectedCardId(minCardId);
                setSelectedCard(0);
            }
        }
    }, [userStore.user]);

    useEffect(() => {
        if(userStore.user.credit_cards) {
            const cardIdsArray = userStore.user.credit_cards.map(card => card.id);
            let minCardId = Math.min(...cardIdsArray);
            setSelectedCardId(minCardId);
        }
    }, []);

    const getMyTransactions = async () => {
        getMyTransactionsApi()
        .then((res) => {
            setMyTransactions(res.data.results);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        getMyTransactions();
    }, []);

    return (
        <div className="edit-balance-component container">
            <div className="title">
                Баланс
            </div>
            <div className="actions">
                <div className="amount">
                    <img src={StarImg} />
                    Ваш баланс составляет<span>{numberWithSpaces(userStore.user.balance)} ₽</span>
                </div>
                <div className="buttons">
                    <button 
                        className="my-button custom-padding"
                        onClick={() => modal.showModal(<WithdrawDepositModal deposit="true" selectedCardIndex={selectedCard} selectedCardId={selectedCardId} callback={() => { getMyTransactions();userStore.fetchUser(); }} />)}
                    >
                        Пополнить
                    </button>
                    <button
                        className="my-button3 custom-padding"
                        onClick={() => modal.showModal(<WithdrawDepositModal selectedCardIndex={selectedCard} selectedCardId={selectedCardId} callback={() => { getMyTransactions();userStore.fetchUser(); }} />)}
                    >
                        Вывести
                    </button>
                    
                </div>
            </div>
            <div className="saved-cards-title-block">
                <div className="saved-cards-title">
                    Сохраненные карты
                </div>
                <div className="add-card" onClick={() => {modal.showModal(<AddCardModal callback={userStore.fetchUser} />)}}>
                    Добавить
                </div>
            </div>
            <div className="cards">
                <div className="row g-3 row-cols-1 row-cols-xl-2">
                    {userStore.user.credit_cards && userStore.user.credit_cards
                        .slice()
                        .sort((a, b) => a.id > b.id ? 1 : -1)
                        .map((card, index) => 
                        <div className="col" key={`balance-card-${index}`}>
                            <div
                                className={`card${selectedCard === index ? ' card-selected' : ''}`}
                                onClick={() => {
                                    setSelectedCard(index);
                                    setSelectedCardId(card.id);
                                }}
                            >
                                <div className="card-edit-pencil" onClick={() => { modal.showModal(<DeleteCardModal card={card} callback={userStore.fetchUser} />) }}>
                                    <span className="bin-icon"></span>
                                </div>
                                <div className="radio-button">
                                    <img src={selectedCard === index ? CardRadioButtonActiveImg : CardRadioButtonImg} />
                                </div>
                                <div className="bank-name-card-number">
                                    <div className="bank-name">
                                        <input
                                            name={card.id}
                                            maxLength="16"
                                            value={`${bankCardNames[card.id] ? bankCardNames[card.id] : ''}`}
                                            onChange={(e) => {
                                                let cards = bankCardNames;
                                                cards[card.id] = e.target.value;
                                                setBankCardNames({ ...cards });
                                            }}
                                            onBlur={(e) => {
                                                let cardId = e.target.getAttribute('name');
                                                let savedBankName = userStore.user.credit_cards.filter(card => card.id === Number(cardId))[0].bank_name;
                                                if(savedBankName !== bankCardNames[cardId] && bankCardNames[cardId] !== 'Карта банка') {
                                                    updateCreditCardApi(cardId, {bank_name: bankCardNames[cardId]})
                                                    .then(() => {
                                                        toast.success('Название карты сохранено', toastOptions);
                                                        userStore.fetchUser();
                                                    })
                                                    .catch((err) => {
                                                        toast.error('Ошибка сохранения названия карты', toastOptions);
                                                        userStore.fetchUser();
                                                        console.log(err);
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="card-number-date">
                                        <div className="card-number">
                                            <div className="card-type">
                                                <img src={cardType(card.type)} />
                                            </div>
                                            <div className="dots">
                                                <img src={FourDigitsIcon} />
                                                <img src={FourDigitsIcon} />
                                                <img src={FourDigitsIcon} />
                                            </div>
                                            <div className="card-digits">
                                                {card.last_4_digits}
                                            </div>
                                        </div>
                                        <div className="date">
                                            {/* 12/12 */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="title">
                История операций
            </div>
            <div className="balance-history">
                <Table>
                    <thead>
                        <tr className="operations-header">
                            <th className="header-type">Вид операции</th>
                            <th className="header-date">Дата</th>
                            <th className="header-details">Детали</th>
                            <th className="header-amount">Сумма</th>
                        </tr>
                    </thead>
                    <tbody>
                        {myTransactions && myTransactions.map((operation, index) =>
                            <tr className="operation" key={`operation_${index}`}>
                                <td className="operation-type">{getOperationType(operation.payout)}</td>
                                <td className="operation-date">{formatDate(new Date(operation.created))}</td>
                                <td className="operation-details"><img src={FourDigitsBlackImg} />
                                    {operation.credit_card ? operation.credit_card.last_4_digits : operation.last_4_digits }
                                </td>
                                <td className={`operation-amount ${operation.status === 'error' ? 'error' : '' } ${operation.payout === true ? 'withdraw' : 'deposit' }`}>
                                    {operation.amount}  ₽
                                    {/* {operation.status === 'error' ? <img src={ErrorImg} /> : ''}
                                    {operation.status === 'wait' ? <img src={WaitImg} /> : ''} */}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
})

export { EditBalanceComponent }