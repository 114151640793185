import axios from 'axios';
import { refreshToken as refreshTokenApi, logout } from '@api/user-api';

const instance = axios.create();

// request interceptor
instance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['authorization'] = 'Bearer ' + token
      }
      // config.headers['Content-Type'] = 'application/json';
      return config
    },
    error => {
      Promise.reject(error)
    }
);

// response interceptor
instance.interceptors.response.use(
    response => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
  
      if ( 
        error && error.response &&
        error.response.status === 401 &&
        originalRequest.url.includes('account/api/token/')
      ) {
        // router.push('/login')
        return Promise.reject(error)
      }
  
      if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
          try {
            const rs = await refreshTokenApi(refreshToken);
            const { access } = rs.data;
            localStorage.setItem("token", access);
            instance.defaults.headers.common['authorization'] = 'Bearer ' + access;
  
            return instance(originalRequest);
          } catch (_error) {
            logout();

            // delete error.config.headers.authorization;
            // delete error.request._header;
            // return instance(originalRequest);
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
  
            return Promise.reject(_error);
          }
        }
      }
      if (error && error.response && error.response.status === 401 && originalRequest._retry) {
        logout();
      }
      return Promise.reject(error)
    }
);

export default instance;