import './index.scss'
import { observer } from 'mobx-react'
import { Context } from '@/index'
import { useContext } from 'react'
import { formatDate } from '@utils/utils'
import { Link } from 'react-router-dom'
import DoubleTick from '@assets/svg/header/notifications/double-tick.svg'
import UnreadMark from '@assets/svg/header/notifications/unread-mark.svg'
import SeparatorSvg from '@assets/svg/header/notifications/separator.svg'
import NotificationUserOne from '@assets/img/header/notifications/userAvatarOne.png'
import NotificationUserTwo from '@assets/img/header/notifications/userAvatarTwo.png'
import NotificationUserThree from '@assets/img/header/notifications/userAvatarThree.png'

const NotificationsPopup = observer(() => {
 const { userStore } = useContext(Context)

 return (
  <ul className="dropdown">
   <div className="notification-header">
    <h3>Уведомления</h3>
    <div className="read-all">
     {/* <img src={DoubleTick} className="img" />
     <span>Прочитать всё</span> */}
    </div>
   </div>
   <div className="notification-list">
    {userStore.notifications.map((n, index) => {
     return (
      <Link to={`/tasks/${n.task}`} key={index}>
       <div className="notification">
        <div className="notification-content">
         <div className="notification-status">
          <img src={UnreadMark} className={n.read ? 'read' : ''} />
         </div>
         <div className="notification-body">
          <div>
           <div className="notification-info">
            <span className="name">
             {n.from_user.first_name} {n.from_user.last_name}
            </span>{' '}
            {n.message}
           </div>
           <div className="notification-date">
            {formatDate(new Date(n.created))}
            {/* 11 августа, 2022 в 09:43 */}
           </div>
          </div>
          <img src={NotificationUserOne} className="img" />
         </div>
        </div>
        <div className="separator">
         <img src={SeparatorSvg} />
        </div>
       </div>
      </Link>
     )
    })}
   </div>
   <div className="open-all">
    {/* Открыть все уведомления */}У Вас нет новых уведомлений
   </div>
  </ul>
 )
})

export { NotificationsPopup }
