import './index.scss';
import { Context } from '@/index';
import { useState, useContext, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import MastercardIcon from '@assets/svg/balance-mastercard.svg'; 
import VisaIcon from '@assets/svg/balance-visa.svg'; 
import VisaWhiteIcon from '@assets/svg/balance-visa-white.svg'; 
import MirIcon from '@assets/svg/balance-mir.svg';
import MirWhiteIcon from '@assets/svg/balance-mir-white.svg';
import FourDigitsIcon from '@assets/svg/four-digits.svg';
import FourDigitsWhiteIcon from '@assets/svg/four-digits-white.svg';
import TickIcon from '@assets/svg/tick.svg';
import AddNewCardImg from '@assets/svg/add-card.svg';
import { AddCardModal } from '../AddCardModal';
import { sendMoney as sendMoneyApi, receiveMoney as receiveMoneyApi, getTransactionStatus as getTransactionStatusApi} from '@api/payment-api';
import usePooling from "@hooks/usePooling";
import config from '@/config';
import { numberWithSpaces } from '@utils/utils';
import { toast } from 'react-toastify';
import { toastOptions } from '@shared/index';
import { DepositDeclinedModal } from '@Components/DepositDeclinedModal';
import { DepositSuccessModal } from '@Components/DepositSuccessModal';

const cardType = (type, isWhite = false) => {
    switch (type) {
        case 'mastercard':
            return MastercardIcon;
        case 'visa':
            if (isWhite) {
                return VisaWhiteIcon;
            }
            return VisaIcon;
        case 'mir':
            if (isWhite) {
                return MirWhiteIcon;
            }
            return MirIcon;
    }
}

const BalancePopup = observer(({ balancePopupRef }) => {
    const { modal, userStore, freeze } = useContext(Context);
    const [ isWithdraw, setIsWithdraw ] = useState(false);
    const [ selectedCard, setSelectedCard ] = useState(0);
    const [ selectedCardId, setSelectedCardId ] = useState(null);
    const [ hoverCard, setHoverCard ] = useState(0);
    const [ isDisabled, setIsDisabled ] = useState(true);
    const [ transactionId, setTransactionId ] = useState(null);
    const [ isPooling, setIsPooling ] = useState(false);
    const [ isRedirect, setIsRedirect ] = useState(false);

    const validationSchemaSavedCardWithdraw = Yup.object().shape({
        amount: Yup.number()
            .typeError('Введите число')
            .required('Введите сумму')
            .min(500, 'Сумма должна быть не менее 500₽')
            .max(1000000, 'Сумма должна быть не более 1000000₽')
            .test(
                'test-credit-card-amount',
                '😥 На Вашем счете недостаточно средств',
                amount => {
                    if (!amount) {
                        return false
                    }
                    if (amount > userStore.user.balance) {
                        return false
                    }
                    return true
                }
            ),            
    });
    
    const validationSchemaSavedCardDeposite = Yup.object().shape({
        amount: Yup.number()
            .typeError('Введите число')
            .required('Введите сумму')
            .min(500, 'Сумма должна быть не менее 500₽')
            .max(1000000, 'Сумма должна быть не более 1000000₽'),            
    })
    
    const formOptions = { resolver: yupResolver(isWithdraw ? validationSchemaSavedCardWithdraw : validationSchemaSavedCardDeposite) }
    const { register, watch, handleSubmit, formState: { errors }, setValue } = useForm(formOptions);
    const withdrawButtonSpinner = useRef(null);
    const depositButtonSpinner = useRef(null);

    useEffect(() => {
        if(userStore.user.credit_cards && userStore.user.credit_cards.length > 0) {
            const sortedCardsArray = userStore.user.credit_cards
                .slice()
                .sort((a, b) => a.id > b.id ? 1 : -1);
            setSelectedCardId(sortedCardsArray[0].id);
        }
    }, []);

    useEffect(() => {
        const subscription = watch((value) => {
            const { amount } = value;
                amount ? setIsDisabled(false) : setIsDisabled(true);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if(isPooling) {
            setIsDisabled(true);
            freeze.showFreeze();
            if(isWithdraw) {
                withdrawButtonSpinner.current.style.display = "inline-block";
            } else {
                depositButtonSpinner.current.style.display = "inline-block";
            }
        } else {
            setIsDisabled(false);
            freeze.closeFreeze();
            if(isWithdraw) {
                withdrawButtonSpinner.current.style.display = "none";
            } else {
                depositButtonSpinner.current.style.display = "none";
            }
        }
    }, [isPooling, isWithdraw]);

    const onSubmit = (data) => {
        setValue('amount', '');
        if(selectedCardId) {
            if (!isWithdraw) {
                
                const payload = {
                    credit_card: selectedCardId,
                    amount: data.amount,
                };
                
        
                sendMoneyApi(payload)
                .then((res) => {
                    setTransactionId(res.data.TransactionId);
                    setIsPooling(true);
                })
                .catch((error) => {
                    console.log(error);
                });
            } else {
                const payload = {
                    credit_card: selectedCardId,
                    amount: data.amount,
                };
        
                receiveMoneyApi(payload)
                .then((res) => {
                    userStore.fetchUser();
                    toast.success(`Выведено ${numberWithSpaces(data.amount)} ₽`, toastOptions);
                })
                .catch((error) => {
                    userStore.fetchUser();
                    toast.error(`Ошибка вывода средств`, toastOptions);
                    console.log(error);
                });
            }
        } else {
            toast(` Добавьте карту для осуществления операций`, toastOptions);
        }
        
    }

    usePooling(
        async () => {
          const transaction = await getTransactionStatusApi(transactionId).catch(
            (error) => {
                setIsPooling(false);
                modal.showModal(<DepositDeclinedModal />);
                console.log(error);
            }
          );
    
          switch (transaction.data.TransactionState) {
            case "success":
                setIsPooling(false);
                setIsRedirect(false);
                freeze.setRedirect(null);
                modal.showModal(<DepositSuccessModal />);
                break;
    
            case "wait_for_3ds":
                const { AcsUrl, PaReq, MD } = transaction.data.StateDetails;
                // if (!openNewTab) {
                const formData = [
                    {
                    name: "PaReq",
                    value: PaReq,
                    },
                    {
                    name: "MD",
                    value: MD,
                    },
                    {
                    name: "TermUrl",
                    value: config.strapi.api_url + '/payment/wait_for_3ds/',
                    },
                ];
    
                const form = document.createElement("form");
        
                form.action = AcsUrl;
                form.method = "POST";
                // form.target = "_blank";
        
                formData.forEach(({ name, value }) => {
                    const input = document.createElement("input");
        
                    input.type = "hidden";
                    input.name = name;
                    input.value = value;
        
                    form.appendChild(input);
                });
        
                document.body.appendChild(form);
        
                form.submit();
        
                document.body.removeChild(form);
        
                //   setOpenNewTab(true);
                // }
                break;
        
            case "declined":
                setIsPooling(false);
                setIsRedirect(false);
                modal.showModal(<DepositDeclinedModal />);
                break;

            case "redirect":
                if(!isRedirect) {
                    setIsRedirect(true);
                    let redirectUrl = transaction.data.StateDetails.RedirectUrl;
                    let decodedRedirectUrl = decodeURIComponent(redirectUrl);
                    freeze.setRedirect(decodedRedirectUrl);
                    const form = document.createElement("form");
                    form.action = decodedRedirectUrl;
                    form.method = "GET";
                    document.body.appendChild(form);
                    form.submit();
                    document.body.removeChild(form);
                }
                break;
    
            default:
                // setIsPooling(false);
                break;
          }
          // console.log(transaction);
        },
        isPooling ? 1000 : null
    );

    return (
        <div className="balance-popup">
            <div className="flow">
                <button
                    className={`${isWithdraw ? '' : 'selected'}`}
                    onClick={() => setIsWithdraw(false)}
                >
                    Пополнить
                </button>
                <button
                    className={`${isWithdraw ? 'selected' : ''}`}
                    onClick={() => setIsWithdraw(true)}
                >
                    Вывести
                </button>
            </div>
            <span className="where">
                {isWithdraw ? 'Куда хотите вывести?' : 'Как хотите пополнить?'}
            </span>
            <div className="cards">
                {userStore.user.credit_cards && userStore.user.credit_cards
                    .slice()
                    .sort((a, b) => a.id > b.id ? 1 : -1)
                    .map((card, index) =>
                    <div
                        key={`balance-popup-card-${index}`}
                        className={`${selectedCard === index ? 'card-selected' : 'card'}`}
                        onClick={() => {
                            setSelectedCard(index);
                            setSelectedCardId(card.id);
                        }}
                        onMouseEnter={() => setHoverCard(index)}
                        onMouseLeave={() => setHoverCard(selectedCard)}
                    >
                        <div className="card-content">
                            <div className="card-type">
                                <img alt="card type" src={`${cardType(card.type, selectedCard === index || hoverCard === index)}`} />
                            </div>
                            <div className="dots">
                                <img alt="four dots" src={`${selectedCard === index ? FourDigitsWhiteIcon : FourDigitsIcon }`} className="dot" />
                                <img alt="four dots" src={`${selectedCard === index ? FourDigitsWhiteIcon : FourDigitsIcon }`} className="dot" />
                                <img alt="four dots" src={`${selectedCard === index ? FourDigitsWhiteIcon : FourDigitsIcon }`} className="dot" />
                            </div>
                            <div className="card-digits">
                                {card.last_4_digits}
                            </div>
                        </div>
                        <div className="card-checkmark">
                            {selectedCard === index && <img alt="tick" src={TickIcon} />}
                        </div>
                    </div>
                )}
                <div
                    className="add-new-card"
                    onClick={() => modal.showModal(<AddCardModal callback={userStore.fetchUser} />)}
                >
                    <img alt="add new card" src={AddNewCardImg} />
                    Добавить карту
                </div>
            </div>
            <span className="what-amount">На какую сумму?</span>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <input
                    type="number"
                    className="amount"
                    placeholder="1000 ₽"
                    {...register('amount')}
                    onFocus={() => {
                        balancePopupRef.current.classList.add('balance-block-on-input-focus');
                    }}
                    onBlur={() => {
                        balancePopupRef.current.classList.remove('balance-block-on-input-focus');
                    }}
                />
                {errors.amount?.message && (
                    <span className="error">{errors.amount?.message}</span>
                )}
                {isWithdraw ? (
                    <button
                        type="submit"
                        className="action"
                        disabled={isDisabled}
                    >
                        <span ref={withdrawButtonSpinner} className="button-custom-spinner spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Вывести
                    </button>
                ) : (
                    <button
                        type="submit"
                        className="action"
                        disabled={isDisabled}
                    >
                        <span ref={depositButtonSpinner} className="button-custom-spinner spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Пополнить
                    </button>
                )}
                
            </form>
        </div>
    );
})

export { BalancePopup }