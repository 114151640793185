import './index.scss';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { changePassword as changePasswordApi } from '@api/user-api';
import { toastOptions } from '@shared/index';

const validationSchema = Yup.object().shape({
    password_old: Yup.string()
        .required('Введите старый пароль')
        .min(6, 'Пароль должно быть не менее 6 символов'),
    password_new: Yup.string()
        .required('Введите новый пароль')
        .min(6, 'Пароль должна быть не менее 6 символов'),  
    password2_new: Yup.string()
        .required('Повторите новый пароль')
        .min(6, 'Пароль должна быть не менее 6 символов'),  
})

const formOptions = { resolver: yupResolver(validationSchema) };

const EditPasswordComponent = observer(() => {
    const [ isDisplaySecondPassword, setIsDisplaySecondPassword ] = useState(false)
    const [ isDifferentPasswordsError, setIsDifferentPasswordsError ] = useState(false)
    const { register, reset, handleSubmit, formState: { errors } } = useForm(formOptions);
    const onSubmit = ({ password_old, password_new, password2_new }) => {
        if (password_new !== password2_new) {
            setIsDifferentPasswordsError(true);
            return;
        }
        const payload = { old_password: password_old, password: password_new }
        changePasswordApi(payload)
        .then(() => {
            reset({
                password_old: '',
                password_new: '',
                password2_new: ''
            });
            setIsDisplaySecondPassword(false);
            toast.success('Пароль успешно изменен', toastOptions);
        })
        .catch(() => {
            reset({
                password_old: '',
                password_new: '',
                password2_new: ''
            });
            setIsDisplaySecondPassword(false);
            toast.error('Ошибка изменения пароля', toastOptions);
        })
    }
    return (
        <div className="edit-password-component container">
            <div className="title">
                Пароль
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control-block">
                    <label className="form-label">Старый пароль</label>
                    <div className="row">
                        <div className="col-md-12">
                            <input 
                                type="password" 
                                className="form-control" 
                                placeholder=""
                                {...register("password_old")}
                            />
                            {errors.password_old?.message && (
                                <p className="error">{errors.password_old?.message}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="form-control-block">
                    <label className="form-label">Новый пароль</label>
                    <div className="row">
                        <div className="col-md-12">
                            <input 
                                type="password" 
                                className="form-control" 
                                placeholder=""
                                {...register("password_new")}
                                onFocus={() => {
                                    setIsDisplaySecondPassword(true);
                                    isDifferentPasswordsError && setIsDifferentPasswordsError(false);
                                }}
                            />
                            {errors.password_new?.message && (
                                <p className="error">{errors.password_new?.message}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="hint">👍 Рекомендуем придумать пароль длиной 8 и более символов</div>
                <div className={`form-control-block ${!isDisplaySecondPassword && 'second-password'}`}>
                    <label className="form-label">Повторите пароль</label>
                    <div className="row">
                        <div className="col-md-12">
                            <input 
                                type="password" 
                                className="form-control" 
                                placeholder=""
                                {...register("password2_new")}
                                onFocus={() => {
                                    isDifferentPasswordsError && setIsDifferentPasswordsError(false);
                                }}
                            />
                            {errors.password2_new?.message && (
                                <p className="error">{errors.password2_new?.message}</p>
                            )}
                            {isDifferentPasswordsError && (
                                <p className="error">😢 Пароли должны совпадать</p>
                            )}
                        </div>
                    </div>
                </div>
                <button>
                    Изменить пароль
                </button>
            </form>
        </div>
    );
})

export { EditPasswordComponent }