import './index.scss'
import { observer } from 'mobx-react-lite'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext } from 'react'
import ShieldGreenImg from '@assets/svg/shield-green.svg';

const DepositSuccessModal = observer(({transactionId = ''}) => {
    const { modal } = useContext(Context);

    return (
        <div className="deposit-success-modal">
            <div className="header-modal">
                Статус операции
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x" alt="close"/>
                </div>
            </div>
            <form className="form">
                <div className="status-icon">
                    <img src={ShieldGreenImg} alt="success" />
                </div>
                <h1 className="status-title">
                    Платеж выполнен
                </h1>
                <h5 className="status-subtitle">
                    Ваша банковская транзакция <span className="transaction-id-bold">{transactionId && `№${transactionId}`}</span> была успешно завершена.
                </h5>
                <div className="actions">
                    <button type='button' className="confirm" onClick={() => modal.closeModal()}>
                        Закрыть
                    </button>
                </div>
            </form>
        </div>
    )
})

export { DepositSuccessModal }