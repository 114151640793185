import { observer } from 'mobx-react-lite';
import { useContext, useState, useEffect } from 'react';
import { likeTaskList as likeTaskListApi, addWidthToLikedTask } from '@api/task-api';
import './index.scss';
import url from 'url';
import { TaskTemplate } from '@Components/TaskTemplate';


const FavoritePage = observer(() => {
    const [ tasks, setTasks ] = useState([]);

    useEffect(() => {
        getTasks();
    }, []);
    const getTasks = async () => {
        likeTaskListApi()
        .then((res) => {
            addWidthToLikedTask(res.data);
            setTasks(res.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }


    return (
        <div className="favorite-page">
            <div className="container">
                <div className="page-header">
                    Избранное
                </div>
                <div className="main-second">
                    <div className="row">
                    {
                        tasks.map((item, key) => {
                            return (
                                <div className={`col-lg-${item.width}`} key={key}>
                                    {/* {item.Title} */}
                                    <TaskTemplate task={item}></TaskTemplate>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        </div>
    )
})

export { FavoritePage }