import { observer } from 'mobx-react-lite'
import './index.scss'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext } from 'react'
import ProfilePlaceholder from '../../assets/img/profile-placeholder.jpg';
import StarSvg from '../../assets/svg/star.svg';
import { assignTask as assignTaskApi } from '@api/task-api';
import config from '@/config';

const HireFreelancer = observer(({ taskId, assignedUser, taskName, callGetTask }) => {
    const { modal, userStore } = useContext(Context);

    const assignTask = () => {
        const obj = {
            assigned_user_id: assignedUser.id
        }
        assignTaskApi(taskId, obj)
        .then((res) => {
            console.log(res);
            callGetTask();
            userStore.newHireUser(assignedUser.id, taskId);
            modal.closeModal();
        })
        .catch((error) => {
            console.log(error);
        })
    }
    
    return (
        <div className="hire-freelancer">
            <div className="header-modal">
                Найм исполнителя
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x" alt="close"/>
                </div>
            </div>
            <div className="user">
                <div className="user-img">
                    <img src={assignedUser.image ? config.strapi.image_url + '/' + assignedUser.image : ProfilePlaceholder} />
                </div>
                <div className="user-info">
                    <div className="user-content">
                        <div className="user-name">{`${assignedUser.first_name} ${assignedUser.last_name}`}</div>
                        <div className="profession-rating">
                            <div className="profession">{assignedUser.specialization ? assignedUser.specialization : 'Исполнитель'}</div>
                            <div className="rating">
                                <img src={StarSvg} />
                                <span>{assignedUser.rating >= 0 ? assignedUser.rating.toFixed(1) : '0.0' }</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text">
                Подтвердите, что Вы хотите нанять данного специалиста для работы над заказом
                <span className="task-name">
                    {` ${taskName}`}
                </span>
            </div>
            <div className="actions">
                <button className="cancel" onClick={() => modal.closeModal()} >
                    Отменить
                </button>
                <button className="my-button first-button" onClick={assignTask}>
                    Подтвердить
                </button>
            </div>
        </div>
    )
})

export { HireFreelancer }