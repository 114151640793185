import { observer } from 'mobx-react-lite'
import './index.scss'


const BalancePage = observer(() => {

    return (
        <div className="balance-page">
            <div className="container">
            </div>
        </div>
    )
})

export { BalancePage }