import { observer } from 'mobx-react-lite';
import './index.scss';
import XImg from '@assets/svg/x.svg';
import { Context } from '@/index';
import { useContext, useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { SELECT_STYLES, PRICE_FOR_CHOICES, toastOptions } from '@shared/index';
import { createTask as createTaskApi, uploadFiles as uploadFilesApi, uploadUrls as uploadUrlsApi } from '@api/task-api';
import { AttachFilesAndLinks } from '@Components/AttachFilesAndLinks';
import { toast } from 'react-toastify';

// ----------------
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// ----------------
const validationSchema = Yup.object().shape({
    title: Yup.string()
        .required('Введите название задания')
        .min(6, 'Название должно быть не менее 6 символов'),
    description: Yup.string()
        .required('Введите описание задания')
        .min(16, 'Описание должно быть не менее 16 символов'),
    category: Yup.object()
        .required('Выберите категорию'),
    price: Yup.number()
        .typeError('Введите число')
        .required('Введите сумму')
        .min(1000, 'Бюджет должен быть не менее 1000 рублей'),
    price_for: Yup.object()
        .required('Выберите тарификацию'),
    deadline: Yup.number()
        .typeError('Введите число')
        .required('Введите количество часов')
        .min(1, 'Срок должен быть не менее 1 часа'),               
});

const formOptions = { resolver: yupResolver(validationSchema), shouldUnregister: true }

const CreateTaskPage = observer(({callback = null}) => {
    const { modal, tasksCategoriesStore, programmingLanguagesStore, userStore } = useContext(Context)
    const [ selectedProgrammingLanguages, setSelectedProgrammingLanguages ] = useState([]);
    const [ isCustomDeadline, setIsCustomDeadline ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);
    const {
        register,
        unregister,
        // errors,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm(formOptions);
    const selectedFilesRef = useRef([]);
    const selectedLinksRef = useRef([]);
    const [ isUploading, setIsUploading ] = useState(false);
    const buttonSpinner = useRef(null);

    useEffect(() => {
        if(isUploading) {
            setIsDisabled(true);
            buttonSpinner.current.style.display = "inline-block";
        } else {
            setIsDisabled(false);
            buttonSpinner.current.style.display = "none";
        }
    }, [isUploading]);

    const onSubmit = (task) => {
        task.price_for = task.price_for.value;
        task.category = task.category.id;
        task.programming_language = selectedProgrammingLanguages;
        task.task_type = userStore.isFreelancer ? 2 : 1;
        setIsUploading(true);
        createTaskApi(task)
        .then( async (res) => {
            const [
                uploadTaskUrlsResult,
                uploadTaskFilesResult
            ] = await Promise.allSettled([
                (res && res.data && selectedLinksRef.current.length) ? uploadUrlsApi({task: res.data.id, url: selectedLinksRef.current}) : Promise.resolve(),
                (res && res.data && selectedFilesRef.current.length) ? uploadFilesApi(res.data.id, selectedFilesRef.current) : Promise.resolve(),
            ]);
            if (uploadTaskUrlsResult.status === 'fulfilled' && uploadTaskFilesResult.status === 'fulfilled'){
                toast.success(`${task.task_type === 1 ? 'Заказ создан' : 'Услуга создана'} успешно`, toastOptions);
            } else {
                toast.error(`${task.task_type === 1 ? 'Заказ создан' : 'Услуга создана'} с ошибками`, toastOptions);
            }
            if (uploadTaskUrlsResult.status === 'rejected') {
                toast.error(`Ошибка добавления ссылок к ${task.task_type === 1 ? 'заказу' : 'услуге'}`, toastOptions);
            }
            if (uploadTaskFilesResult.status === 'rejected') {
                toast.error(`Ошибка добавления файлов к ${task.task_type === 1 ? 'заказу' : 'услуге'}`, toastOptions);
            }
            setIsUploading(false);
            if (callback) {
                callback();
            }
            modal.closeModal();
        })
        .catch(() => {
            setIsUploading(false);
            if (callback) {
                callback();
            }
            toast.error(`Ошибка создания ${task.task_type === 1 ? 'заказа' : 'услуги'}`, toastOptions);
            modal.closeModal();
        });
    }

    const selectProgrammingLanguage = (pl) => {
        if (selectedProgrammingLanguages.includes(pl.id)) {
            let temp = selectedProgrammingLanguages.filter(v => v != pl.id);
            setSelectedProgrammingLanguages(temp);
        } else {
            setSelectedProgrammingLanguages(selectedProgrammingLanguages => [...selectedProgrammingLanguages, pl.id]);
        }
    }

    const setIsCustomDeadlineHeandler = (e) => {
        setIsCustomDeadline(true);
    }

    const onFilesOrLinksChange = (files, links, isSizeLimit) => {
        selectedFilesRef.current = files;
        selectedLinksRef.current = links;
        setIsDisabled(isSizeLimit);
    }

    return (
        <div className="create-task-page">
                
            <div className="main">
                <div className="header-modal">
                    { userStore.isFreelancer ? 'Создать новую услугу 😎' : 'Создать новую задачу 😎' }
                    <div className="x-block" onClick={() => modal.closeModal()}>
                        <img src={XImg} className="x"/>
                    </div>
                </div>
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-control-block">
                        <label htmlFor="title" className="form-label">
                            { userStore.isFreelancer ? 'Название услуги' : 'Название заказа' }
                        </label>
                        <input type="text" className="form-control" id="title" 
                        placeholder={ userStore.isFreelancer ? 'Введите название услуги' : 'Например, разработать дизайн главной страницы сайта...' }
                        // {...register("title", { required: true })}/>
                        {...register("title")}/>
                        {errors.title?.message && (
                           <p className="error">{errors.title?.message}</p>
                        )}
                    </div>
                    <div className="form-control-block">
                        <label htmlFor="description" className="form-label">
                            { userStore.isFreelancer ? 'Описание услуги' : 'Что требуется сделать?' }
                        </label>
                        <textarea className="form-control" id="description" rows="4" 
                        placeholder={ userStore.isFreelancer ?
                            'Постарайтесь максимально подробно и точно описать какую именно услугу Вы оказываете и что получит Заказчик по итогу.'  : 
                            "Например, необходимо разработать современный, простой и легкий к восприятию дизайн главной страницы сайта. Более подробное техническое задание в приложении..." } 
                        // {...register("description", { required: true })}></textarea>
                        {...register("description")}></textarea>
                        {errors.description?.message && (
                           <p className="error">{errors.description?.message}</p>
                        )}
                    </div>
                    <AttachFilesAndLinks onChangeCallback={onFilesOrLinksChange} />
                    <div className="form-control-block">
                        <label htmlFor="task_category" className="form-label">
                            { userStore.isFreelancer ? 'К какой категории относится услуга?' : 'К какой категории относится заказ?' }
                        </label>
                        <Controller
                            name="category"
                            control={control}
                            rules={{ required: true }}
                            render = {({ field: { onChange, onBlur, value, ref } })=> (
                                <Select
                                    fullWidth
                                    placeholder="Выберите категорию"
                                    styles={SELECT_STYLES}
                                    options={tasksCategoriesStore.items}
                                    getOptionLabel={(t) => t.name}
                                    getOptionValue={(t) => t.id}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    defaultValue={''}
                                />
                            )}
                        />
                        {errors.category?.message && (
                           <p className="error">{errors.category?.message}</p>
                        )}
                    </div>
                    <div className="form-control-block">
                        <label htmlFor="task_category" className="form-label">Выберите один или несколько языков программирования</label>
                        <div className="selected-filters-block">
                        {
                            programmingLanguagesStore.items.map(pl => {
                                return (
                                    <button 
                                        type="button" 
                                        key={pl.id} className={'button ' + (selectedProgrammingLanguages.includes(pl.id) ? 'blue' : '')} 
                                        onClick={() => selectProgrammingLanguage(pl)}
                                    >
                                        {pl.name}
                                    </button>
                                )
                            })
                        }
                        </div>
                    </div>
                    <div className="row">
                        <div className={userStore.isFreelancer ? "col-md-12" : "col-md-6"}>
                            <div className="form-control-block">
                                <label className="form-label">
                                    { userStore.isFreelancer ? "Определите бюджет услуги" : "Определите бюджет заказа" }
                                </label>
                                <div className="row">
                                    <div className="col-5 padding-right-zero">
                                        <input type="number" className="form-control" placeholder="1000 ₽"  {...register("price")}/>
                                        {errors.price?.message && (
                                            <p className="error">{errors.price?.message}</p>
                                        )}
                                    </div>
                                    <div className="col-2 vertically-middle padding-right-zero padding-left-zero">
                                        за
                                    </div>
                                    <div className="col-5 padding-left-zero">
                                        <Controller
                                            name="price_for"
                                            control={control}
                                            rules={{ required: true }}
                                            render = {({ field: { onChange, onBlur, value, ref } })=> (
                                                <Select
                                                    fullWidth
                                                    placeholder="Что?"
                                                    styles={SELECT_STYLES}
                                                    options={PRICE_FOR_CHOICES}
                                                    defaultValue={''}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                />
                                            )}
                                        />
                                        {errors.price_for?.message && (
                                            <p className="error">{errors.price_for?.message}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {   userStore.isFreelancer && setValue('deadline', 1)  }
                        {
                            !userStore.isFreelancer &&
                            <div className="col-md-6">
                                <div className="form-control-block">
                                    <label htmlFor="duration" className="form-label">
                                        { userStore.isFreelancer ? "Укажите срок выполнения" : "Укажите желаемый срок выполнения" }
                                    </label>
                                    { isCustomDeadline ? (
                                        <div>
                                            <input type="number" className="form-control" defaultValue="" placeholder="В часах" {...register("deadline")}/>
                                        </div>
                                    ) : (
                                        <div className="btn-group radio-group" role="group" aria-label="Basic radio toggle button group">
                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" {...register("deadline")} value="24"/>
                                            <label className="btn btn-outline-primary radio-button" htmlFor="btnradio1">24 часа</label>

                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" {...register("deadline")} value="72"/>
                                            <label className="btn btn-outline-primary radio-button" htmlFor="btnradio2">3 дня</label>

                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio3" {...register("deadline")} value="168"/>
                                            <label className="btn btn-outline-primary radio-button" htmlFor="btnradio3">7 дней</label>

                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio4" {...register("deadline")} value="1000"/>
                                            <label className="btn btn-outline-primary radio-button" htmlFor="btnradio4" onClick={setIsCustomDeadlineHeandler}>другое</label>
                                        </div>
                                    )}
                                    {errors.deadline?.message && (
                                        <p className="error">{errors.deadline?.message}</p>
                                    )}
                                </div>
                            </div>
                        }
                        
                    </div>
                    
                    
                    <div className="invalid-feedback">
                        Пожалуйста проверьте на правильность все поля
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <button type="button" className="my-button second-button" onClick={() => modal.closeModal()}>Отменить</button>
                        </div>
                        <div className="col-md-6">
                            <button type="submit" className="my-button first-button" disabled={isDisabled}>
                                <span ref={buttonSpinner} className="button-custom-spinner spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                { userStore.isFreelancer ? "Создать услугу" : "Создать заказ" }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
})

export { CreateTaskPage }