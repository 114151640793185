import './index.scss'
import { observer } from 'mobx-react-lite'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext } from 'react'
import FailedImg from '@assets/svg/failed.svg';

const DepositDeclinedModal = observer(({transactionId = ''}) => {
    const { modal } = useContext(Context);

    return (
        <div className="deposit-declined-modal">
            <div className="header-modal">
                Статус операции
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x" alt="close"/>
                </div>
            </div>
            <form className="form">
                <div className="status-icon">
                    <img src={FailedImg} alt="failed" />
                </div>
                <h1 className="status-title">
                    Ошибка платежа
                </h1>
                <h5 className="status-subtitle">
                    Транзакция <span className="transaction-id-bold">{transactionId && `№${transactionId}`}</span> была отклонена банком. Пожалуйста, попробуйте снова. В случае повторения ошибки — удалите карту и добавьте ее снова, тщательно проверив правильность ввода данных. Если ошибка не будет устранена - обратитесь в банк, выпустивший карту.
                </h5>
                <div className="actions">
                    <button type='button' className="confirm" onClick={() => modal.closeModal()}>
                        Закрыть
                    </button>
                </div>
            </form>
        </div>
    )
})

export { DepositDeclinedModal }