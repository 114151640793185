import './index.scss'
import { observer } from 'mobx-react-lite'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext, useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import StarImg from '@assets/img/star.png';
import usePooling from "@hooks/usePooling";
import config from '@/config';
import { sendMoney as sendMoneyApi, receiveMoney as receiveMoneyApi, getTransactionStatus as getTransactionStatusApi} from '@api/payment-api';
import { createCreditCard as createCreditCardApi } from '@api/user-api';
import MastercardIcon from '@assets/svg/balance-mastercard.svg'; 
import VisaIcon from '@assets/svg/balance-visa.svg'; 
import VisaWhiteIcon from '@assets/svg/balance-visa-white.svg'; 
import MirIcon from '@assets/svg/balance-mir.svg';
import MirWhiteIcon from '@assets/svg/balance-mir-white.svg';
import FourDigitsIcon from '@assets/svg/four-digits.svg';
import FourDigitsWhiteIcon from '@assets/svg/four-digits-white.svg';
import TickIcon from '@assets/svg/tick.svg';
import { numberWithSpaces } from '@utils/utils';
import { toast } from 'react-toastify';
import { getCardType, formatExpirationDate, formatCardHolderName } from '@utils/utils';
import { toastOptions } from '@shared/index';
import { DepositDeclinedModal } from '@Components/DepositDeclinedModal';
import { DepositSuccessModal } from '@Components/DepositSuccessModal';

const cardTypeIcon = (type, isWhite = false) => {
    switch (type) {
        case 'mastercard':
            return MastercardIcon;
        case 'visa':
            if (isWhite) {
                return VisaWhiteIcon;
            }
            return VisaIcon;
        case 'mir':
            if (isWhite) {
                return MirWhiteIcon;
            }
            return MirIcon;
    }
}

const WithdrawDepositModal = observer(({deposit = true, selectedCardIndex = 0, selectedCardId, callback = null}) => {
    const { modal, userStore, freeze } = useContext(Context);
    const [ selectedCard, setSelectedCard ] = useState(selectedCardIndex);
    const [ hoverCard, setHoverCard ] = useState(selectedCardIndex);
    const [ cardType, setCardType ] = useState('');
    const [ isDisabled, setIsDisabled ] = useState(true);
    const [ isCustomCard, setIsCustomCard ] = useState(false);
    const [ transactionId, setTransactionId ] = useState(null);
    const [ isPooling, setIsPooling ] = useState(false);
    const [ isRedirect, setIsRedirect ] = useState(false);
    const buttonSpinner = useRef(null);

    const validationSchemaDeposit = Yup.object().shape({
        cardNumber: Yup.string()
            .required('Укажите номер карты')
            .min(19, 'Номер карты должен содержать 16 цифр')
            .max(19, 'Номер карты должен содержать 16 цифр'),
        cvc: Yup.string()
            .required('Укажите CVC/CVV')
            .min(3, 'CVC/CVV должен содержать от 3 до 4 цифр')
            .max(4, 'CVC/CVV должен содержать от 3 до 4 цифр'),
        cardHolder: Yup.string()
            .required('Укажите имя владельца карты')
            .min(3, 'Имя владельца карты должно содержать от 3 до 22 символов')
            .max(22, 'Имя владельца карты должно содержать от 3 до 22 символов'),
        amount: Yup.number()
            .required('Укажите сумму')
            .typeError('Укажите сумму')
            .min(500, 'Сумма должна быть не менее 500₽')
            .max(1000000, 'Сумма должна быть не более 1000000₽'),
        expirationDate: Yup.string()
            .required('Укажите срок действия карты')
            .typeError('Указан неверный срок действия карты. Пример: 05/30')
            .max(5, 'Указан неверный срок действия карты. Пример: 05/30')
            .matches(
                /([0-9]{2})\/([0-9]{2})/,
                'Указан неверный срок действия карты. Пример: 05/30'
            )
            .test(
                'test-credit-card-expiration-date',
                'Cрок действия карты истек',
                expirationDate => {
                if (!expirationDate) {
                    return false
                }
            
                const today = new Date()
                const monthToday = today.getMonth() + 1
                const yearToday = today
                    .getFullYear()
                    .toString()
                    .substr(-2)
            
                const [expMonth, expYear] = expirationDate.split('/')
            
                if (Number(expYear) < Number(yearToday)) {
                    return false
                } else if (
                    Number(expMonth) < monthToday &&
                    Number(expYear) <= Number(yearToday)
                ) {
                    return false
                }
            
                return true
                }
            )
            .test(
                'test-credit-card-expiration-date',
                'Месяц истечения срока действия карты указан с ошибкой',
                expirationDate => {
                    if (!expirationDate) {
                        return false
                    }
                    const today = new Date()
                        .getFullYear()
                        .toString()
                        .substr(-2)
                
                    const [expMonth] = expirationDate.split('/')
                
                    if (Number(expMonth) > 12) {
                        return false
                    }
                
                    return true
                }
        )
        .required('Укажите срок действия карты')
    });
    
    const validationSchemaWithdraw = Yup.object().shape({
        cardNumber: Yup.string()
            .required('Укажите номер карты')
            .min(19, 'Номер карты должен содержать 16 цифр')
            .max(19, 'Номер карты должен содержать 16 цифр'),
        cardHolder: Yup.string()
            .required('Укажите имя владельца карты')
            .min(3, 'Имя владельца карты должно содержать от 3 до 22 символов')
            .max(22, 'Имя владельца карты должно содержать от 3 до 22 символов'),
        amount: Yup.number()
            .typeError('Укажите сумму')
            .required('Укажите сумму')
            .min(500, 'Сумма должна быть не менее 500₽')
            .max(1000000, 'Сумма должна быть не более 1000000₽')
            .test(
                'test-credit-card-amount',
                '😥 На Вашем счете недостаточно средств',
                amount => {
                    if (!amount) {
                        return false
                    }
                    if (amount > userStore.user.balance) {
                        return false
                    }
                    return true
                }
            ),
    });

    const validationSchemaSavedCardDeposite = Yup.object().shape({
        card_id: Yup.string()
            .required('Выберите сохраненную карту'),
        amount: Yup.number()
            .typeError('Укажите сумму')
            .required('Укажите сумму')
            .min(500, 'Сумма должна быть не менее 500₽')
            .max(1000000, 'Сумма должна быть не более 1000000₽'),
    });
    
    const validationSchemaSavedCardWithdraw = Yup.object().shape({
        card_id: Yup.string()
            .required('Выберите сохраненную карту'),
        amount: Yup.number()
            .typeError('Укажите сумму')
            .required('Укажите сумму')
            .min(500, 'Сумма должна быть не менее 500₽')
            .max(1000000, 'Сумма должна быть не более 1000000₽')
            .test(
                'test-credit-card-amount',
                '😥 На Вашем счете недостаточно средств',
                amount => {
                    if (!amount) {
                        return false
                    }
                    if (amount > userStore.user.balance) {
                        return false
                    }
                    return true
                }
            ),
    });
    
    const formOptions = { resolver: yupResolver(isCustomCard ? 
        deposit === 'true' ? validationSchemaDeposit : validationSchemaWithdraw
        : 
        deposit === 'true' ? validationSchemaSavedCardDeposite : validationSchemaSavedCardWithdraw), shouldUnregister: true }
    const { register, watch, handleSubmit, formState: { errors }, setValue } = useForm(formOptions);

    useEffect(() => {
        if (userStore.user.credit_cards.length > 0) {
            setValue('card_id', selectedCardId);
            setIsCustomCard(false)
        } else {
            setIsCustomCard(true)
        }
    }, [userStore]);

    useEffect(() => {
        if(isPooling) {
            freeze.showFreeze();
            setIsDisabled(true);
            buttonSpinner.current.style.display = "inline-block";
        } else {
            freeze.closeFreeze();
            setIsDisabled(false);
            buttonSpinner.current.style.display = "none";
        }
    }, [isPooling]);

    const onSubmit = async (data) => {
        if (data.saveCard) {
            var expData = data.expirationDate.split("/");
            const payload = {
                card_number: data.cardNumber.replaceAll(" ", ""),
                exp_month: expData[0],
                exp_year: expData[1],
                cvc: data.cvc,
                cardholder_name: data.cardHolder.toUpperCase(),
                type: cardType
            };
    
            const response = await createCreditCardApi(payload);
            data.card_id = response.data.id;
        }
        if (deposit === 'true') {
            let payload = {}
            if (data.card_id) {
                payload = {
                    credit_card: data.card_id,
                    amount: data.amount,
                };
            } else {
                var expData = data.expirationDate.split("/");
                payload = {
                    card_number: data.cardNumber.replaceAll(" ", ""),
                    exp_month: expData[0],
                    exp_year: expData[1],
                    cvc: data.cvc,
                    cardholder_name: data.cardHolder.toUpperCase(),
                    amount: data.amount,
                };
            }
    
            sendMoneyApi(payload)
            .then((res) => {
                console.log(res);
                setTransactionId(res.data.TransactionId);
                setIsPooling(true);
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
            setValue('amount', '');
            let payload = {}
            if (data.card_id) {
                payload = {
                    credit_card: data.card_id,
                    amount: data.amount,
                };
            } else {
                payload = {
                    receiver_card_number: data.cardNumber.replaceAll(" ", ""),
                    receiver_cardholder_name: data.cardHolder.toUpperCase(),
                    amount: data.amount,
                };
            }
    
            receiveMoneyApi(payload)
            .then((res) => {
                if(callback) {
                    callback();
                }
                toast.success(`Выведено ${numberWithSpaces(data.amount)} ₽`, toastOptions);
                console.log(res);
            })
            .catch((error) => {
                toast.error(`Ошибка вывода средств`, toastOptions);
                console.log(error);
            });
        }
    }

    usePooling(
        async () => {
            const transaction = await getTransactionStatusApi(transactionId).catch(
                (error) => {
                    setIsPooling(false);
                    modal.showModal(<DepositDeclinedModal />);
                    console.log(error);
                }
            );
    
            switch (transaction.data.TransactionState) {
                case "success":
                    if(callback) {
                        callback();
                    }
                    setIsPooling(false);
                    setIsRedirect(false);
                    freeze.setRedirect(null);
                    modal.showModal(<DepositSuccessModal />);
                    break;
        
                case "wait_for_3ds":
                    const { AcsUrl, PaReq, MD } = transaction.data.StateDetails;
                    // if (!openNewTab) {
                    const formData = [
                        {
                        name: "PaReq",
                        value: PaReq,
                        },
                        {
                        name: "MD",
                        value: MD,
                        },
                        {
                        name: "TermUrl",
                        value: config.strapi.api_url + '/payment/wait_for_3ds/',
                        },
                    ];
            
                    const form = document.createElement("form");
            
                    form.action = AcsUrl;
                    form.method = "POST";
                    // form.target = "_blank";
            
                    formData.forEach(({ name, value }) => {
                        const input = document.createElement("input");
            
                        input.type = "hidden";
                        input.name = name;
                        input.value = value;
            
                        form.appendChild(input);
                    });
            
                    document.body.appendChild(form);
            
                    form.submit();
            
                    document.body.removeChild(form);
            
                    //   setOpenNewTab(true);
                    // }
                    break;
            
                case "declined":
                    setIsPooling(false);
                    setIsRedirect(false);
                    modal.showModal(<DepositDeclinedModal />);
                    break;

                case "redirect":
                    if(!isRedirect) {
                        setIsRedirect(true);
                        let redirectUrl = transaction.data.StateDetails.RedirectUrl;
                        let decodedRedirectUrl = decodeURIComponent(redirectUrl);
                        freeze.setRedirect(decodedRedirectUrl);
                        const form = document.createElement("form");
                        form.action = decodedRedirectUrl;
                        form.method = "GET";
                        document.body.appendChild(form);
                        form.submit();
                        document.body.removeChild(form);
                    }
                    break;
            
                default:
                    // setIsPooling(false);
                    break;
            }
            // console.log(transaction);
        },
        isPooling ? 1000 : null
    );

    const formatCardNumberAndCardType = (event) => {
        setCardType(getCardType(event.target.value.replace(/\s/g, '')));
        event.target.value = [...event.target.value.replace(/\s/g, '')].map((d, i) =>
            (i) % 4 == 0 ? ' ' + d : d).join('').trim()
    }

    useEffect(() => {
        if (isCustomCard) {
            const subscription = watch((value) => {
                const { cardHolder, cardNumber, cvc, expirationDate, amount } = value;
                if (deposit === 'true') {
                    (cardHolder && cardNumber && cvc && expirationDate && amount) ? setIsDisabled(false) : setIsDisabled(true);
                } else {
                    (cardHolder && cardNumber && amount) ? setIsDisabled(false) : setIsDisabled(true);
                }
            });
            return () => subscription.unsubscribe();
        } else {
            const subscription = watch((value) => {
                const { amount } = value;
                    amount ? setIsDisabled(false) : setIsDisabled(true);
            });
            return () => subscription.unsubscribe();
        }
    }, [watch, isCustomCard]);

    return (
        <div className="withdraw-modal">
            <div className="header-modal border-bottom">
                {deposit === 'true' ? 'Внести средства' : 'Вывести средства'}
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x"/>
                </div>
            </div>
            <div className="balance-amount-block">
                <div className="amount">
                    <img src={StarImg} />
                    Ваш баланс составляет<span>{numberWithSpaces(userStore.user.balance)} ₽</span>
                </div>
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                {userStore && userStore.user?.credit_cards && userStore.user?.credit_cards?.length > 0 &&
                    <div className="choose-card">
                        <div className="subtitle">Выберите карту</div>
                        <div className="cards row g-3 row-cols-1 row-cols-lg-2">
                            {userStore.user.credit_cards && userStore.user.credit_cards
                                .slice()
                                .sort((a, b) => a.id > b.id ? 1 : -1)
                                .map((card, index) => 
                                <div className="col" key={`withdraw-deposit-modal-card-${index}`}>
                                    <div
                                        key={`balance-popup-card-${index}`}
                                        className={`${selectedCard === index ? 'card-selected' : 'card'}`}
                                        onClick={() => {
                                            setSelectedCard(index);
                                            setIsCustomCard(false);
                                            setValue("card_id", card.id);
                                        }}
                                        onMouseEnter={() => setHoverCard(index)}
                                        onMouseLeave={() => setHoverCard(selectedCard)}
                                    >
                                        <div className="card-content">
                                            <div className="card-type">
                                                <img src={`${cardTypeIcon(card.type, selectedCard === index || hoverCard === index)}`} />
                                            </div>
                                            <div className="dots">
                                                <img src={`${selectedCard === index ? FourDigitsWhiteIcon : FourDigitsIcon }`} className="dot" />
                                                <img src={`${selectedCard === index ? FourDigitsWhiteIcon : FourDigitsIcon }`} className="dot" />
                                                <img src={`${selectedCard === index ? FourDigitsWhiteIcon : FourDigitsIcon }`} className="dot" />
                                            </div>
                                            <div className="card-digits">
                                                {card.last_4_digits}
                                            </div>
                                        </div>
                                        <div className="card-checkmark">
                                            {selectedCard === index && <img src={TickIcon} />}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col">
                                <div
                                    className={`${isCustomCard ? 'another-card-selected' : 'another-card'}`}
                                    onClick={() => {
                                        setIsCustomCard(true);
                                        setSelectedCard(null);
                                        setHoverCard(null);
                                        setValue('card_id', null);
                                        setIsDisabled(true);
                                    }}
                                >
                                    Другая карта
                                </div>
                            </div>
                        </div>
                    </div>
                }
            { isCustomCard &&
                <>
                    <div className="form-control-block">
                        <label htmlFor="cardNumber" className="form-label">
                            Номер карты
                        </label>
                        <input
                            maxLength="19"
                            className={`form-control ${cardType ? cardType : 'card'}`}
                            id="cardNumber"
                            placeholder="" 
                            {...register('cardNumber')}
                            onKeyUp={formatCardNumberAndCardType}
                            // onChange={(e) => {
                                
                            // }}
                        />
                        {errors.cardNumber?.message && (
                            <p className="error">{errors.cardNumber?.message}</p>
                        )}
                    </div>
                    {
                        deposit === 'true' &&
                        <>
                            <div className="form-control-block">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="expirationDate" className="form-label">
                                            Срок действия
                                        </label>
                                        <input
                                            maxLength="5"
                                            className="form-control date"
                                            id="expirationDate"
                                            placeholder="05/30" 
                                            {...register('expirationDate')}
                                            onKeyUp={formatExpirationDate}
                                        />
                                        {errors.expirationDate?.message && (
                                            <p className="error">{errors.expirationDate?.message}</p>
                                        )}
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="cvc" className="form-label">
                                            CVC/CVV
                                        </label>
                                        <input
                                            maxLength="4"
                                            type="password"
                                            className="form-control secret"
                                            id="cvc"
                                            placeholder="•••"
                                            {...register('cvc')}
                                        />
                                        {errors.cvc?.message && (
                                            <p className="error">{errors.cvc?.message}</p>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </>
                    }
                    
                    <div className="form-control-block">
                        <label htmlFor="cardHolder" className="form-label">
                            Имя владельца карты
                        </label>
                        <input
                            className="form-control"
                            id="cardHolder"
                            placeholder="SERGEY IVANOV" 
                            {...register('cardHolder')}
                            onKeyUp={formatCardHolderName}
                        />
                        {errors.cardHolder?.message && (
                            <p className="error">{errors.cardHolder?.message}</p>
                        )}
                    </div>
                    {deposit === 'true' && 
                        <div className="form-control-block">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="saveCard"
                                    {...register('saveCard')}
                                />
                                <label className="form-check-label" htmlFor="saveCard">
                                    Запомнить карту
                                </label>
                            </div>
                        </div>
                    }
                </>
            }

                    <div className="form-control-block">
                        <label htmlFor="amount" className="form-label">
                            Сумма
                        </label>
                        <input
                            type='number'
                            className="form-control"
                            id="amount"
                            placeholder="500 ₽" 
                            {...register('amount')}
                        />
                        {errors.amount?.message && (
                            <p className="error">{errors.amount?.message}</p>
                        )}
                    </div>
                    <div className="actions">
                        <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                            Закрыть
                        </button>
                        <button type='submit' className="confirm" disabled={isDisabled}>
                        <span ref={buttonSpinner} className="button-custom-spinner spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {deposit === 'true' ? 'Внести' : 'Вывести'}
                        </button>
                    </div>
            </form>
        </div>
    )
})

export { WithdrawDepositModal }