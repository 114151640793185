import './index.scss';
import config from '@/config';
import { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { isEllipsisActive} from '@utils/utils';
import ProfilePlaceholderImg from '@assets/img/profile-placeholder.jpg';
import { likeTask as likeTaskApi, removeLikeTask as removeLikeTaskApi, blockTask as blockTaskApi, removeBlockTask as removeBlockTaskApi } from '@api/task-api';
import { LoginPage } from '@Pages/LoginPage';
import { Context } from '@/index';
import { useContext } from 'react';

const TaskTemplate = observer(({task}) => {
    const { modal } = useContext(Context);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const personRef = useRef(null);
    const [ fistNameOnlyFirstLetter, setFistNameOnlyFirstLetter ] = useState(false);
    const [ isLiked, setIsLiked ] = useState(false);
    const [ isBlocked, setIsBlocked ] = useState(false);

    useEffect(() => {
        setIsLiked(task.liked);
    }, [task]);

    const likeTask = (e) => {
        e.preventDefault();
        if (isLiked) {
            setIsLiked(false);
            removeLikeTaskApi(task.id)
            .then(() => {
                console.log('success');
            })
            .catch((err) => {
                console.log(err);
            });
        } else {
            setIsLiked(true);
            likeTaskApi(task.id)
            .then(() => {
                console.log('success');
            })
            .catch((err) => {
                let { data, status } = err.response;
                if (status === 401 && data.detail === "Authentication credentials were not provided.") {
                    modal.showModal(<LoginPage/>);
                } else {
                    console.log(err);
                }
            });
        }
    }
    const blockTask = (e) => {
        e.preventDefault();
        if(isBlocked) {
            setIsBlocked(false);
            removeBlockTaskApi(task.id)
            .then(() => {
                console.log('success');
            })
            .catch((err) => {
                console.log(err);
            });
        } else {
            setIsBlocked(true);
            blockTaskApi(task.id)
            .then(() => {
                console.log('success');
            })
            .catch((err) => {
                let { data, status } = err.response;
                if (status === 401 && data.detail === "Authentication credentials were not provided.") {
                    modal.showModal(<LoginPage/>);
                } else {
                    console.log(err);
                }
            });
        }
    }

    useEffect(() => {
        setFistNameOnlyFirstLetter(isEllipsisActive(lastNameRef.current));
    }, []);

    return (
        <Link to={`/tasks/${task.id}`} key={task.id}>
            <div className="main-second-item">
                <div className="category d-flex justify-content-between">
                    <div className="category-name d-flex">
                        <div className={"category-type " + (task.task_type === 1 ? 'category-type-1' : 'category-type-2')}>
                            { task.task_type === 1 ? 'Заказ' : 'Услуга' }
                        </div>
                        <div className="category-name-task">{task.category.name}</div>
                    </div>
                    <div className="favorite-block">
                        <span className={"heart " + (isLiked ? 'heart-clicked' : '')} onClick={likeTask}></span>
                        <span className={"block " + (isBlocked ? 'block-clicked' : '')} onClick={blockTask}></span>
                    </div>
                </div>
                <div className="header">
                    {task.title}
                </div>
                <div className="salary">
                    от {Number(task.price) > 9999 ? task.price.toLocaleString('ru-RU') : task.price} ₽
                </div>
                <div ref={personRef} className="person">
                    <div className="user-image">
                    <img className="task-user-image" alt="user image"
                        src={task?.created_user?.image ? `${config.strapi.image_url}/${task.created_user.image}` : ProfilePlaceholderImg}
                    />
                    </div>
                    <div className="space-12">{' '}</div>
                    {!task?.created_user?.first_name && !task?.created_user?.last_name && task.created_user.username}
                    <div ref={firstNameRef} className="first-name">
                        {task?.created_user?.first_name && fistNameOnlyFirstLetter ? `${task.created_user.first_name[0]}.` : task.created_user.first_name }
                    </div>
                    <div className="space">{' '}</div>
                    <div ref={lastNameRef} className="last-name">
                        {task?.created_user?.last_name && `${task.created_user.last_name}`}
                    </div>
                    <div className="space-4">{' '}</div>
                    <div className="rating">
                        {`(${task.created_user.rating.toFixed(1).replace('.',',')})`}
                    </div>
                </div>
                <div className="propose">
                    { task.task_type === 1 ? 'Предложений:' : 'Заказов в очереди:' }{` ${task.total_responded_users}`}
                </div>
            </div>
        </Link>
    );
})

export { TaskTemplate }