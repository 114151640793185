const dev = {
  strapi: {
    api_url: 'http://127.0.0.1:8000',
    image_url: 'http://127.0.0.1:8000/task/documents',
    websocket_url: 'ws://127.0.0.1:8000/ws',
  },
  google_maps_api_key: 'AIzaSyAbgD7IOXtYPRUhn2irw2kRiCPnQHLwOI4',
}

const prod = {
  strapi: {
    api_url: 'https://api.devssquad.com',
    image_url: 'https://api.devssquad.com',
    websocket_url: 'wss://api.devssquad.com/ws',
  },
  google_maps_api_key: 'AIzaSyAbgD7IOXtYPRUhn2irw2kRiCPnQHLwOI4',
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev

export default {
  ...config,
}
