import { observer } from 'mobx-react-lite'
import './index.scss'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext } from 'react'
import { changeTaskStatus as changeTaskStatusApi } from '@api/task-api';

const SubmitTaskModal = observer(({taskId, callGetTask}) => {
    const { modal } = useContext(Context);

    const submitTask = () => {
        const object = {
            task_status: 'submission'
        }
        changeTaskStatusApi(taskId, object)
        .then(() => {
            callGetTask();
            modal.closeModal();
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <div className="submit-task-modal">
            <div className="header-modal">
                Сдача работы на проверку
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x"/>
                </div>
            </div>
            <div className="description">
                Убедитесь в том, что Вы полностью выполнили все пункты заказа в соответствии с техническим заданием.
            </div>
            <div className="row">
                <div className="col-md-6">
                    <button type="button" className="my-button2" onClick={() => modal.closeModal()}>Закрыть</button>
                </div>
                <div className="col-md-6">
                    <button type="button" className="my-button" onClick={submitTask}>Сдать работу</button>
                </div>
            </div>
            
        </div>
    )
})

export { SubmitTaskModal }