import { useContext, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Context } from './index'
import './App.scss'

import { MyHomePage } from '@Pages/MyHomePage'
import { TasksPage } from '@Pages/TasksPage'
import { BalancePage } from '@Pages/BalancePage'
import { CreateTaskPage } from '@Pages/CreateTaskPage'
import { FaqPage } from '@Pages/FaqPage'
import { ServiceRulesPage } from '@Pages/ServiceRulesPage'
import { PaymentServiceRulesPage } from '@Pages/PaymentServiceRulesPage'
import { FavoritePage } from '@Pages/FavoritePage'
import { ProfilePage } from '@Pages/ProfilePage'
import { EditProfilePage } from '@Pages/EditProfilePage'
import { TaskPage } from '@Pages/TaskPage'
import { UsersPage } from '@Pages/UsersPage'
import { UserPage } from '@Pages/UserPage'
import { NotFoundPage } from '@Pages/NotFoundPage'
import { ContactsPage } from '@Pages/ContactsPage'
import { InternalServerErrorPage } from '@Pages/InternalServerErrorPage'
import { TaskHistoryPage } from '@Pages/TaskHistoryPage'
import { NotificationPage } from '@Pages/NotificationPage'
import { TermsPage } from '@Pages/TermsPage'
import { PolicyPage } from '@Pages/PolicyPage'

import { Header } from '@Components/Header'
import { Footer } from '@Components/Footer'
import ModalComponent from '@Components/Modal'
import FreezeModal from '@Components/FreezeModal'

import { PrivateRoute } from './private-route'
import { observer } from 'mobx-react-lite'
import styled, { ThemeProvider } from 'styled-components'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const theme = {
 media: {
  xs: '(max-width: 576px)',
  sm: '(min-width: 576px) and (max-width: 768px)',
  md: '(max-width: 768px)',
  lg: '(max-width: 1044px)',
 },
}

const StyledContent = styled.div`
 flex: 1;
`

const App = observer(() => {
 const { modal, freeze } = useContext(Context)

 return (
  <>
   <ThemeProvider theme={theme}>
    <BrowserRouter>
     <div className="flex-wrapper">
      <Header />
      <Routes>
       <Route path="/" element={<MyHomePage />} />
       <Route path="/tasks" element={<TasksPage />} />
       <Route path="/tasks/:id" element={<TaskPage />} />
       <Route path="/users" element={<UsersPage />} />
       <Route path="/user/:id" element={<UserPage />} />
       <Route path="/faq" element={<FaqPage />} />
       <Route path="/service-rules" element={<ServiceRulesPage />} />
       <Route path="/payment-service" element={<PaymentServiceRulesPage />} />
       <Route path="/contacts" element={<ContactsPage />} />
       <Route
        path="/task-history"
        element={
         <PrivateRoute>
          {' '}
          <TaskHistoryPage />{' '}
         </PrivateRoute>
        }
       />
       <Route
        path="/create-task"
        element={
         <PrivateRoute>
          {' '}
          <CreateTaskPage />{' '}
         </PrivateRoute>
        }
       />
       <Route path="/profile/:id" element={<ProfilePage />} />
       <Route
        path="/edit-profile/:id"
        element={
         <PrivateRoute>
          {' '}
          <EditProfilePage />{' '}
         </PrivateRoute>
        }
       />
       <Route
        path="/balance"
        element={
         <PrivateRoute>
          {' '}
          <BalancePage />{' '}
         </PrivateRoute>
        }
       />
       <Route
        path="/favorite"
        element={
         <PrivateRoute>
          {' '}
          <FavoritePage />{' '}
         </PrivateRoute>
        }
       />
       <Route
        path="/notification"
        element={
         <PrivateRoute>
          {' '}
          <NotificationPage />{' '}
         </PrivateRoute>
        }
       />
       <Route path="/terms" element={<TermsPage />} />
       <Route path="/policy" element={<PolicyPage />} />
       <Route path="/500" element={<InternalServerErrorPage />} />
       <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
      <ToastContainer bodyClassName="toastBody" />
      {modal.body && <ModalComponent>{modal.body}</ModalComponent>}
      {freeze.isFreeze && <FreezeModal />}
     </div>
    </BrowserRouter>
   </ThemeProvider>
  </>
 )
})

export default App
