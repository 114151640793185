import config from '../config';
import instance from './axios-instance';

const STRAPI_API_URL = config.strapi.api_url + '/service_rules';

export const getCategories = () => {
    return instance.get(`${STRAPI_API_URL}/`);
}

export const getSubcategoryContent = (id) => {
    return instance.get(`${STRAPI_API_URL}/${id}/`);
}