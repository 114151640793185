import './index.scss';
import { useRef, useState, useEffect } from 'react';
import { Context } from '@/index';
import { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SELECT_STYLES, LOCATIONS, toastOptions } from '@shared/index';
import { filesEndingOfTheWord, linksEndingOfTheWord, formatDateYear, isOdd } from  '@utils/utils';
import Select from 'react-select';
import ProfilePlaceholder from '@assets/img/profile-placeholder.jpg';
import config from '@/config';
import {
    updateUser as updateUserApi,
    getUserInfo as getUserInfoApi,
    updateProfile as updateProfileApi,
    updateUserImage as updateUserImageApi,
    deleteUserImage as deleteUserImageApi,
    getSpecializationList as getSpecializationListApi
} from '@api/user-api';
import FileProjectImg from '@assets/svg/file-project.svg';
import LinkProjectImg from '@assets/svg/link-project.svg';
import AddTaskMiniImg from '@assets/svg/add-task-mini.svg';
import BinImg from '@assets/svg/bin.svg';
import { EditProgrammingLanguagesModal } from '@Components/EditProgrammingLanguagesModal';
import { EditSkillsModal } from '@Components/EditSkillsModal';
import { AddProjectsModal } from '@Components/AddProjectsModal';
import { EditProjectsModal } from '@Components/EditProjectsModal';
import { EditExperienceModal } from '@Components/EditExperienceModal';
import { PortfolioProjectModal } from '@Components/PortfolioProjectModal';
import { DeletePortfolioProjectModal } from '@Components/DeletePortfolioProjectModal';
import { DeleteAccountModal } from '@Components/DeleteAccountModal';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { toast } from 'react-toastify';

const EditProfileComponent = observer(() => {
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: config.google_maps_api_key,
        language: 'ru',
        options: {
            types: ["(cities)"]
        },
        // temp solution for callback error
        libraries: 'places&callback=Function.prototype'
    });

    const { modal, userStore } = useContext(Context);
    const { id } = userStore.user;
    const [ bioLength, setBioLength ] = useState(0);
    const [ googleLocation, setGoogleLocations ] = useState(LOCATIONS);
    const savePlaceDetailsToState = (optionsPlacePredictions) => {
        setGoogleLocations(optionsPlacePredictions);
    };
    const [ specializationList, setSpecializationList ] = useState([]);
    const [ userSpecialization, setUserSpecialization ] = useState('');
    const [ userLocation, setUserLocation ] = useState('');
    const [ isDisabled, setIsDisabled ] = useState(true);
    const [ profileData, setProfileData ] = useState({});
    const [ portfoliosSlicer, setPortfoliosSlicer] = useState(0);
    const validationSchemaFreelancer = Yup.object().shape({
        first_name: Yup.string()
            .required('Введите имя')
            .min(2, 'Имя должно быть не менее 2 символов'),
        last_name: Yup.string()
            .required('Введите фамилию')
            .min(2, 'Фамилия должна быть не менее 2 символов'),
        profession: Yup.object()
            .typeError('Выберите специализацию')
            .required('Выберите специализацию'),
        about_me: Yup.string()
            .required('Введите информацию о себе')
            .min(16, 'Информация о себе должна быть не менее 16 символов')             
            .max(1200, 'Информация о себе должна быть не более 1200 символов'),
        location: Yup.object()
            .typeError('Выберите местоположение')
            .required('Выберите местоположение'),
    })
    const validationSchemaManager = Yup.object().shape({
        first_name: Yup.string()
            .required('Введите имя')
            .min(2, 'Имя должно быть не менее 2 символов'),
        last_name: Yup.string()
            .required('Введите фамилию')
            .min(2, 'Фамилия должна быть не менее 2 символов'), 
        location: Yup.object()
            .typeError('Выберите местоположение')
            .required('Выберите местоположение'),           
    })
    const formOptions = { resolver: yupResolver(userStore.user && userStore.isFreelancer ?
        validationSchemaFreelancer : validationSchemaManager) };
    const { register, watch, setValue, handleSubmit, formState: { errors }, control } = useForm(formOptions);
    const hiddenUploadImageInput = useRef(null);
    const handleUploadProfileImageClick = (e) => {
        hiddenUploadImageInput.current.click();
    };
    const changeImage = (e) => {
        const formData = new FormData();
        const newImages = Array.from(e.target.files);
        formData.append('image', newImages[0]);
        updateUserImageApi(formData)
        .then(() => {
            userStore.fetchUser();
            toast.success('Фото изменено', toastOptions);
        })
        .catch(() => {
            userStore.fetchUser();
            toast.error('Ошибка изменения фото', toastOptions);
        });
    };
    const removeImage = () => {
        deleteUserImageApi()
        .then(() => {
            userStore.fetchUser();
            toast.success('Фото удалено', toastOptions);
        })
        .catch(() => {
            userStore.fetchUser();
            toast.error('Ошибка удаления фото', toastOptions);
        })
    }
    const updateProfession = (profession) => {
        const updateProfile = {
            specialization: profession.id
        }
        updateProfileApi(updateProfile)
        .then(() => {
            toast.success('Специализация успешно сохранена', toastOptions);
        })
        .catch(() => {
            toast.error('Ошибка сохранения специализации', toastOptions);
        })
    }
    const updateGeodate = (geodata) => {
        console.log(geodata);
        const updateUser = {
            first_name: userStore.user.first_name,
            last_name: userStore.user.last_name,
            geodata: geodata
        };
        updateUserApi(updateUser)
        .then(() => {
            toast.success('Местоположение успешно сохранено', toastOptions);
        })
        .catch(() => {
            toast.error('Ошибка сохранения местоположения', toastOptions);
        })
    }
    const onSubmit = ({
        first_name,
        last_name,
        about_me,
        location,
    }) => {
        if (userStore.isFreelancer) {
            if(about_me !== userStore.user.about_me) {
                const updateProfile = {
                    about_me,
                }
                updateProfileApi(updateProfile)
                .then(() => {
                    toast.success('Ваша информация о себе успешно сохранена', toastOptions);
                    userStore.fetchUser();
                    getUserInfo();
                })
                .catch(() => {
                    toast.error('Ошибка сохранения информации о себе', toastOptions);
                    userStore.fetchUser();
                    getUserInfo();
                })
            }
        }
        if (first_name !== userStore.user.first_name || last_name !== userStore.user.last_name) {
            const updateUser = {
                first_name,
                last_name,
                geodata: {
                    value: location.value,
                    label: location.label,
                    utc_offset: location.utc_offset,
                    locality: location.locality,
                    country: location.country
                }
            }
            updateUserApi(updateUser)
            .then(() => {
                toast.success('Имя и фамилия успешно сохранены', toastOptions);
                userStore.fetchUser();
                getUserInfo();
            })
            .catch(() => {
                toast.error('Ошибка сохранения имени и фамилии', toastOptions);
                userStore.fetchUser();
                getUserInfo();
            })
        }
    }
    useEffect(() => {
        if (profileData?.portfolios) {
            let slicer = isOdd(profileData.portfolios.length) ? (profileData.portfolios.length - 1) / 2 : profileData.portfolios.length / 2;
            setPortfoliosSlicer(slicer);
        }
    }, [profileData.portfolios]);
    useEffect(() => {
        const subscription = watch((value) => {
            if(userStore.isFreelancer) {
                const { first_name, last_name, about_me } = value;
                if(about_me.length !== userStore.user.about_me.length) {
                    setBioLength(about_me.length);
                }
                if(first_name !== userStore.user.first_name || last_name !== userStore.user.last_name || about_me !== userStore.user.about_me) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
            } else {
                const { first_name, last_name } = value;
                if(first_name !== userStore.user.first_name || last_name !== userStore.user.last_name) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, userStore.user]);
    useEffect(() => {
        getUserInfo();
    }, [id]);
    const getUserInfo = () => {
        getUserInfoApi(id)
        .then((res) => {
            setProfileData(res.data);
        })
        .catch((err) => {

        })
    }
    useEffect(() => {
        setBioLength(userStore.user.about_me.length);
        getSpecializationListApi()
        .then((result) => {
            setSpecializationList(result.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);
    useEffect(() => {
        if (specializationList && profileData) {
            setUserLocation(profileData.geodata);
            setUserSpecialization(profileData.specialization);
            setValue('location', profileData.geodata);
            setValue('profession', profileData.specialization);
        }

    }, [specializationList, profileData]);
    useEffect(() => {
        const placePredictionsCopy = [...placePredictions];
        const optionsPlacePredictions = placePredictionsCopy.map((item) => {
            placesService?.getDetails({
                placeId: item.place_id
            }, () => {
                savePlaceDetailsToState(optionsPlacePredictions);
            });
            return { value: item.place_id, label: item.description };
        });
    }, [placePredictions]);

    const projectTemplate = (project) => {
        return (
            <div
                className="project"
                key={`project-${project.id}`}
                onClick={
                    () => modal.showModal(<EditProjectsModal project={project} callback={getUserInfo} />)
                }
            >
                <div className="project-title-header">
                    <div className="project-title">
                        {project.name}
                    </div>
                    <div
                        className="remove-project"
                        onClick={(e) => {
                            e.stopPropagation();
                            modal.showModal(<DeletePortfolioProjectModal
                                project={project}
                                callback={getUserInfo}
                            />)
                        }}
                    >
                        <span className="bin-icon"></span>
                    </div>
                </div>
                <div className="project-description">
                    {project.description.length > 100 ? `${project.description.slice(0, 100)}...` : project.description}
                </div>
                <div className="project-files-links">
                    <div className="files-links">
                        <img src={FileProjectImg} />
                        {`${project.document_urls.length} ${filesEndingOfTheWord(project.document_urls.length)}`}
                    </div>
                    <div className="files-links">
                        <img src={LinkProjectImg} />
                        {`${project.urls.length} ${linksEndingOfTheWord(project.urls.length)}`}
                    </div>
                </div>
            </div>
        );
    }

    return (<>
        <div className="edit-profile-component container">
            <div className="title">
                Редактировать профиль
            </div>
            <div className="user-image-block">
                <div className="user-image">
                    <img
                        src={userStore.user && userStore.user.image ? config.strapi.image_url + '/' + userStore.user.image : ProfilePlaceholder}
                    />
                </div>
                <div className="user-image-actions">
                    <button className="user-image-edit" type="button" onClick={handleUploadProfileImageClick}>
                        Изменить фото
                    </button>
                    <input
                            type="file"
                            name="filename"
                            ref={hiddenUploadImageInput}
                            onChange={changeImage}
                            accept="image/png, image/gif, image/jpeg"
                        />
                    <button className="user-image-delete" type="button" onClick={removeImage}>
                        Удалить фото
                    </button>
                </div>
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control-block">
                    <label className="form-label">Имя</label>
                    <div className="row">
                        <div className="col-md-12">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Например, Пётр"
                                {...register("first_name", { value: userStore.user.first_name })} />
                                {errors.first_name?.message && (
                                    <p className="error">{errors.first_name?.message}</p>
                                )}
                        </div>
                    </div>
                </div>
                <div className="form-control-block">
                    <label className="form-label">Фамилия</label>
                    <div className="row">
                        <div className="col-md-12">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Например, Пётров"
                                {...register("last_name", { value: userStore.user.last_name })} />
                                {errors.first_name?.message && (
                                    <p className="error">{errors.first_name?.message}</p>
                                )}
                        </div>
                    </div>
                </div>
                {userStore.user && userStore.isFreelancer ? (
                    <>
                        <div className="form-control-block">
                            <label className="form-label">Специализация</label>
                            <div className="row">
                                <div className="col-md-12">
                                    <Controller
                                        name="profession"
                                        control={control}
                                        rules={{ required: true }}
                                        render = {({ field: { onChange, onBlur, value, ref } })=> (
                                            <Select
                                                fullWidth
                                                placeholder="Выберите cпециализацию"
                                                styles={SELECT_STYLES}
                                                options={specializationList}
                                                getOptionValue={(p) => p.id}
                                                getOptionLabel={(p) => p.name}
                                                onChange={(e) => {
                                                    setUserSpecialization(e);
                                                    onChange(e);
                                                    updateProfession(e);
                                                }}
                                                onBlur={onBlur}
                                                value={userSpecialization}
                                            />
                                        )}
                                    />
                                    {errors.profession?.message && (
                                        <div className="error">{errors.profession?.message}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="form-control-block">
                            <label className="form-label">О себе</label>
                            <div className="row">
                                <div className="col-md-12">
                                    <textarea
                                        className="form-control"
                                        id="bio"
                                        rows="4" 
                                        placeholder="Вкратце расскажи о себе — какие у тебя навыки и опыт, чем ты можешь быть интересен потенциальному заказчику. Хвастаться можно и нужно 👍" 
                                        {...register("about_me", { value: userStore.user.about_me })}
                                    >
                                    </textarea>
                                    <div className="tip">{`${bioLength}/1200`}</div>
                                    {errors.about_me?.message && (
                                        <p className="error">{errors.about_me?.message}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="form-control-block">
                            <label className="form-label">Местоположение</label>
                            <div className="row">
                                <div className="col-md-12">
                                <Controller
                                    name="location"
                                    control={control}
                                    rules={{ required: true }}
                                    render = {({ field: { onChange, onBlur, value, ref } })=> (
                                        <Select
                                            fullWidth
                                            isClearable
                                            placeholder="Выберите местоположение"
                                            styles={SELECT_STYLES}
                                            options={googleLocation}
                                            onChange={(item, action) => {
                                                if (action.action === 'clear') {
                                                    console.log(action);
                                                    setUserLocation('');
                                                    onChange('');
                                                } else {
                                                    if(item) {
                                                        placesService?.getDetails({
                                                            placeId: item.value
                                                        }, (placeDetails) => {
                                                            if(placeDetails && placeDetails.utc_offset_minutes) {
                                                                item.utc_offset = placeDetails.utc_offset_minutes;
                                                                item.country = placeDetails.address_components[placeDetails.address_components.length-1].short_name;
                                                                item.locality = placeDetails.address_components[0].short_name;                                                                ;
                                                                onChange(item);
                                                                setUserLocation(item);
                                                                updateGeodate(item);
                                                            }
                                                        })
                                                    }
                                                }
                                            }}
                                            onBlur={onBlur}
                                            defaultValue={''}
                                            cacheOptions
                                            defaultOptions
                                            onInputChange={(value) => {
                                                getPlacePredictions({ input: value });
                                            }}
                                            value={userLocation}
                                            
                                        />
                                    )}
                                />
                                {errors.location?.message && (
                                    <div className="error">{errors.location?.message}</div>
                                )}
                                </div>
                            </div>
                        </div>
                        {/* Programming Languages */}
                        <div className="resume-block">
                            <div className="header">
                                <div className="title">Языки программирования</div>
                                <div
                                    className="edit"
                                    onClick={() => modal.showModal(<EditProgrammingLanguagesModal
                                        savedProgrammingLanguages={profileData?.programming_language}
                                        callback={getUserInfo}
                                    />)}
                                >
                                    Редактировать
                                </div>
                            </div>
                            {profileData?.programming_language && profileData?.programming_language?.length > 0 ? (
                                <div className="grey-boxes">
                                    {profileData.programming_language.map((programmingLanguage, index) =>
                                        <div
                                            key={`programming-language-${index}`}
                                            className="grey-box"
                                        >
                                            {programmingLanguage.name}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="empty">
                                    Вы еще не указали ни одного языка. По языкам программирования заказчики подбирают нужного специалиста для заказа.{' '} 
                                    <span
                                        className="add"
                                        onClick={() => modal.showModal(<EditProgrammingLanguagesModal callback={getUserInfo} />)}
                                    >
                                        Добавить
                                    </span>
                                </div>
                            )}
                        </div>
                        {/* Skills */}
                        <div className="resume-block">
                            <div className="header">
                                <div className="title">Навыки</div>
                                <div className="edit" onClick={() => modal.showModal(<EditSkillsModal savedSkills={profileData?.skills} callback={getUserInfo}/>)}>
                                    Редактировать
                                </div>
                            </div>
                            <div className="subtitle">
                                Укажи свои основные навыки — стек технологий, методологии, и т.д.
                            </div>
                            {profileData?.skills && profileData?.skills?.length > 0 ? (
                                <div className="grey-boxes">
                                    {profileData.skills.map((skill, index) =>
                                        <div
                                            key={`skill-${index}`}
                                            className="grey-box"
                                        >
                                            {skill}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="empty">
                                    Вы еще не указали навыки. По списку навыков заказчиков сможет понять, что вы — лучший кандидат для заказа.{' '}  
                                    <span
                                        className="add"
                                        onClick={() => modal.showModal(<EditSkillsModal callback={getUserInfo}/>)}
                                    >
                                        Добавить
                                    </span>
                                </div>
                            )}
                        </div>
                        {/* Projects */}
                        <div className="resume-block">
                            <div className="header">
                                <div className="title">Портфолио</div>
                            </div>
                            <div className="subtitle">
                                Прикрепи свои лучшие работы, чтобы показать заказчику твой уровень мастерства
                            </div>
                            {profileData?.portfolios && profileData?.portfolios?.length > 0 ? (
                                <div className="projects row g-3">
                                    <div className="col-lg-6">
                                        <div
                                            className="add-task"
                                            onClick={() =>  modal.showModal(<AddProjectsModal callback={getUserInfo}/>)}
                                        >
                                            <button className="add">
                                                <img src={AddTaskMiniImg} />
                                            </button>
                                            <div className="title">
                                                Добавить работу
                                            </div>
                                        </div>
                                        {profileData.portfolios.slice(0, portfoliosSlicer).map((project) => projectTemplate(project))}
                                    </div>
                                    <div className="col-lg-6">
                                        {profileData.portfolios.slice(portfoliosSlicer).map((project) => projectTemplate(project))}
                                    </div>
                                </div>
                            ) : (
                                <div className="empty">
                                    У вас нет работ в портфолио. Хорошее портфолио позволяет вам выделиться на фоне конкурентов и блеснуть своими навыками.{' '}  
                                    <span
                                        className="add"
                                        onClick={() => modal.showModal(<AddProjectsModal callback={getUserInfo}/>)}
                                    >
                                        Добавить
                                    </span>
                                </div>
                            )}
                        </div>
                        {/* Experiences */}
                        <div className="resume-block">
                           
                            {profileData?.work_experiences && profileData?.work_experiences?.length > 0 ? (
                                <>
                                    <div className="header">
                                        <div className="title">Опыт работы</div>
                                            <div className="edit"
                                                onClick={() => modal.showModal(<EditExperienceModal
                                                    savedExperiences={profileData?.work_experiences}
                                                    callback={getUserInfo}/>)}
                                            >
                                                Редактировать
                                            </div>
                                    </div>
                                    <div className="experiences">
                                        {profileData.work_experiences.map((experience, index) =>
                                            <div
                                                key={`experience-${index}`}
                                                className="experience"
                                            >
                                                <div className="company-position">
                                                    <div className="company">
                                                        {experience.company}
                                                    </div>
                                                    <div className="position">
                                                        {experience.position}
                                                    </div>
                                                </div>
                                                <div className="start-end">
                                                    {`${formatDateYear(experience.start_date)} - ${formatDateYear(experience.end_date)}`}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="header">
                                        <div className="title">Опыт работы</div>
                                            <div className="edit"
                                                onClick={() => modal.showModal(<EditExperienceModal
                                                    savedExperiences={[{
                                                        company: '',
                                                        position: '',
                                                        duty: '',
                                                        start_date: [''],
                                                        end_date: ['']
                                                    }]}
                                                    callback={getUserInfo}/>)}
                                            >
                                                Редактировать
                                            </div>
                                    </div>
                                    <div className="empty">
                                        Вы еще не указали навыки. По списку навыков заказчиков сможет понять, что вы — лучший кандидат для заказа.{' '}  
                                        <span
                                            className="add"
                                            onClick={() => modal.showModal(<EditExperienceModal
                                                savedExperiences={[{
                                                    company: '',
                                                    position: '',
                                                    duty: '',
                                                    start_date: [''],
                                                    end_date: ['']
                                                }]}
                                                callback={getUserInfo} />)}
                                        >
                                            Добавить
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="form-control-block">
                        <label className="form-label">Местоположение</label>
                        <div className="row">
                            <div className="col-md-12">
                            <Controller
                                name="location"
                                control={control}
                                rules={{ required: true }}
                                render = {({ field: { onChange, onBlur, value, ref } })=> (
                                    <Select
                                        fullWidth
                                        isClearable
                                        placeholder="Выберите местоположение"
                                        styles={SELECT_STYLES}
                                        options={googleLocation}
                                        onChange={(item, action) => {
                                            if (action.action === 'clear') {
                                                console.log(action);
                                                setUserLocation('');
                                                onChange('');
                                            } else {
                                                if(item) {
                                                    placesService?.getDetails({
                                                        placeId: item.value
                                                    }, (placeDetails) => {
                                                        if(placeDetails && placeDetails.utc_offset_minutes) {
                                                            item.utc_offset = placeDetails.utc_offset_minutes;
                                                            item.country = placeDetails.address_components[placeDetails.address_components.length-1].short_name;
                                                            item.locality = placeDetails.address_components[0].short_name;                                                                ;
                                                            onChange(item);
                                                            setUserLocation(item);
                                                            updateGeodate(item);
                                                        }
                                                    })
                                                }
                                            }
                                        }}
                                        onBlur={onBlur}
                                        defaultValue={''}
                                        cacheOptions
                                        defaultOptions
                                        onInputChange={(value) => {
                                            getPlacePredictions({ input: value });
                                        }}
                                        value={userLocation}
                                        
                                    />
                                )}
                            />
                            {errors.location?.message && (
                                <div className="error">{errors.location?.message}</div>
                            )}
                            </div>
                        </div>
                    </div>
                )}
                <div className="save-button">
                    <button type="submit" className="my-button" disabled={isDisabled}>Сохранить</button>
                </div>            
            </form>
        </div>
        <div className="delete-account" onClick={() => modal.showModal(<DeleteAccountModal />)}>
            Удалить аккаунт
        </div>
    </>);
})

export { EditProfileComponent }