import { makeAutoObservable } from 'mobx';
import { me } from '@api/user-api';
import NotificationWebSocketInstance from '@api/notification-websocket';
import { getNotification as getNotificationApi, createNotification as createNotificationApi } from '@api/notification-api';
import { toast } from 'react-toastify';
import { toastOptions } from '@shared/index';

export default class UserStore {
  constructor() {
    this._token = localStorage.getItem('token') || null;
    this._user = null;
    this._notifications =  [];
    this._notificationsConnectionPending = false;
    this._notificationsConnectionEstablished = false;
    if (localStorage.getItem('user')) {
      try {
        this._user = JSON.parse(localStorage.getItem('user'));
      } catch (e) {}
    }
    this._isFreelancer = localStorage.getItem('isFreelancer') === 'true';
    this.fetchUser();
    makeAutoObservable(this);
  }

  setUser(user) {
    this._user = user;
    this.getNotifications();
    this.getNotificationWebSocket();
  }

  get user() {
    return this._user;
  }

  setIsFreelancer(isFreelancer) {
    localStorage.setItem("isFreelancer", isFreelancer);
    this._isFreelancer = isFreelancer;
  }

  get isFreelancer() {
    return this._isFreelancer;
  }

  setUserImage(image) {
    this._user.image = image;
  }

  fetchUser = () => {
    const token = localStorage.getItem('token');
    token &&
    me().then((user) => {
      this.setUser(user);
    });
  }

  logout() {
    this._user = null;
    this._token = null;
    this.removeItemsFromLocalstorage();
    NotificationWebSocketInstance.disconnect();
    this._notifications =  [];
    this._notificationsConnectionEstablished = false;
    this._notificationsConnectionPending = false;
  }

  removeItemsFromLocalstorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
  }

  // Notifications

  getNotificationWebSocket = async () => {
    if (this._notificationsConnectionEstablished == true) {
      NotificationWebSocketInstance.disconnect();
    }
    if (this._notificationsConnectionPending == false) {
      this._notificationsConnectionPending = true;
      NotificationWebSocketInstance.connect(this._user.id);
      var startTime = new Date().getTime();
      this.waitForSocketConnection(startTime, () => {
        this._notificationsConnectionEstablished = true;
        this._notificationsConnectionPending = false;
        NotificationWebSocketInstance.addCallbacks(this.addNotificationCallback.bind(this));
      });
    }
  }

  waitForSocketConnection = (startTime, callback) => {
    if(new Date().getTime() - startTime > 10000) {
      return;
    }
    setTimeout(
      () => {
        // Check if websocket state is OPEN
        if (NotificationWebSocketInstance.state() === 1) {
          console.log("Connection is made")
          callback();
          return;
        } else {
          console.log("wait for connection...")
          this.waitForSocketConnection(startTime, callback);
        }
    }, 100); // wait 100 milisecond for the connection...
  }

  addNotificationCallback = (notification) => {
    this._notifications.push(notification);
    toast.success(notification.from_user.first_name + ' ' + notification.from_user.last_name + ' ' + notification.message, toastOptions);
  }

  newRespondedUser = (receiverId, taskId) => {
    this._newNotification(receiverId, taskId, 1);
  }

  newChatMessage = (receiverId, taskId) => {
    this._newNotification(receiverId, taskId, 2);
  }

  newHireUser = (receiverId, taskId) => {
    this._newNotification(receiverId, taskId, 3);
  }

  _newNotification = (receiverId, taskId, messageId) => {
    const messageBody = {
      for_user: receiverId,
      message: messageId,
      task: taskId
    }
    createNotificationApi(messageBody)
    .then((res) => {
        console.log(res);
    })
    .catch((error) => {
        console.log(error);
    })
  }

  get notifications() {
    return this._notifications;
  }

  setNotifications(items) {
    this._notifications = items
  }
  getNotifications = async () => {
    await getNotificationApi(this._user.id, 3)
    .then((res) => {
      if (res && res.data && res.data.results) {
        this.setNotifications(res.data.results);
      }
    })
    .catch((error) => {
      this.setNotifications([]);
    })
  }
}
