import { Context } from '../index';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
// import { LoginPage } from '@Pages/LoginPage';

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const { userStore, modal } = useContext(Context)
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         userStore.user ? <Component {...props} /> : modal.showModal(<LoginPage />)
//       }
//     />
//   )
// }

const PrivateRoute = ({ children }) => {
  const { userStore, modal } = useContext(Context);

  // return userStore.user ? children : modal.showModal(<LoginPage />);
  return userStore.user ? children : <Navigate to="/" replace />;
};

export { PrivateRoute }
