import { makeAutoObservable } from 'mobx'

export default class FreezeStore {
  freeze = {
    show: false,
    redirect: null
  }

  constructor() {
    makeAutoObservable(this)
  }

  setRedirect(redirect) {
    this.freeze.redirect = redirect;
  }

  get redirect() {
    return this.freeze.redirect;
  }

  get isFreeze() {
    return this.freeze.show
  }

  showFreeze() {
    this.freeze.show = true
  }

  closeFreeze() {
    this.freeze.show = false
  }
}
