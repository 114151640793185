import './index.scss'
import { observer } from 'mobx-react-lite'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext } from 'react'
// ----------------
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// ----------------
import { updateProfile as updateProfileApi } from '@api/user-api';
import { toast } from 'react-toastify';
import { toastOptions } from '@shared/index';

const validationSchema = Yup.object().shape({
    bio: Yup.string()
        .required('Введите информацию о себе')
        .min(16, 'Информация о себе должна быть не менее 16 символов')             
        .max(1200, 'Информация о себе должна быть не более 1200 символов'),               
})
const formOptions = { resolver: yupResolver(validationSchema), shouldUnregister: true }

const EditBioModal = observer(({ savedAboutMe, callback }) => {
    const { modal } = useContext(Context);
    const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

    const onSubmit = (data) => {
        console.log(data);
        const updateProfile = {
            about_me: data.bio,
        }
        updateProfileApi(updateProfile)
        .then(() => {
            callback();
            toast.success('Информация о себе успешно сохранена', toastOptions);
            modal.closeModal();
        })
        .catch(() => {
            callback();
            toast.error('Произошла ошибка изменения раздела о себе', toastOptions);
            modal.closeModal();
        })
    }

    return (
        <div className="edit-bio-modal">
            <div className="header-modal">
                Редактировать информацию о себе
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x"/>
                </div>
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control-block">
                    <label htmlFor="bio" className="form-label">
                        Кратко напишите о себе  — что вас увлекает, какие области вам наиболее интересны, и т.д.
                    </label>
                    <textarea
                        className="form-control"
                        id="bio"
                        rows="4" 
                        placeholder="" 
                        {...register("bio")}
                        defaultValue={savedAboutMe}
                    >
                    </textarea>
                    {errors.bio?.message && (
                        <p className="error">{errors.bio?.message}</p>
                    )}
                </div>
                <div className="actions">
                    <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                        Отменить
                    </button>
                    <button type='submit' className="confirm">
                        Сохранить
                    </button>
                </div>
            </form>
        </div>
    )
})

export { EditBioModal }