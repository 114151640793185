import './index.scss'
import { observer } from 'mobx-react-lite'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { removePortfolio as removePortfolioApi } from '@api/user-api';
import { toast } from 'react-toastify';
import { toastOptions } from '@shared/index';

const validationSchema = Yup.object().shape({
    projectId: Yup.string()
      .required('Не указан ID проекта')
});

const DeletePortfolioProjectModal = observer(({project, callback}) => {
    const { modal } = useContext(Context);
    
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue } = useForm(formOptions);

    useEffect(() => {
        if (project.id) {
            setValue('projectId', project.id);
        }
    }, [project]);

    const onSubmit = (data) => {
        console.log(data);
        removePortfolioApi(data.projectId)
        .then(() => {
            callback();
            toast.success('Проект удален', toastOptions);
            modal.closeModal();
        })
        .catch(() => {
            callback();
            toast.error('Ошибка удаления проекта', toastOptions);
            modal.closeModal();
        });
    }

    return (
        <div className="delete-portfolio-project-modal">
            <div className="header-modal delete-portfolio-project-header">
                Подтвердите удаление
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x" alt="close" />
                </div>
            </div>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control-block">
                    <label className="form-label">
                        Вы уверены, что хотите удалить проект <span className="project-name">{project.name}</span>?
                    </label>
                </div>
                <div className="actions">
                    <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                        Закрыть
                    </button>
                    <button type='submit' className="confirm">
                        Удалить проект
                    </button>
                </div>
            </form>
        </div>
    )
})

export { DeletePortfolioProjectModal }