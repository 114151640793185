import './index.scss'
import { observer } from 'mobx-react-lite'
import XImg from '@assets/svg/x.svg'
import { Context } from '@/index'
import { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { createCreditCard as createCreditCardApi } from '@api/user-api';
import { toast } from 'react-toastify';
import { getCardType, formatExpirationDate, formatCardHolderName } from '@utils/utils';
import { toastOptions } from '@shared/index';

const validationSchema = Yup.object().shape({
    cardNumber: Yup.string()
      .required('Укажите номер карты')
      .min(19, 'Номер карты должен содержать 16 цифр')
      .max(19, 'Номер карты должен содержать 16 цифр'),
    cvc: Yup.string()
      .required('Укажите CVC/CVV')
      .min(3, 'CVC/CVV должен содержать от 3 до 4 цифр')
      .max(4, 'CVC/CVV должен содержать от 3 до 4 цифр'),
    cardHolder: Yup.string()
      .required('Укажите имя владельца карты')
      .min(3, 'Имя владельца карты должно содержать от 3 до 22 символов')
      .max(22, 'Имя владельца карты должно содержать от 3 до 22 символов'),
    expirationDate: Yup.string()
      .required('Укажите срок действия карты')
      .typeError('Указан неверный срок действия карты. Пример: 05/30')
      .max(5, 'Указан неверный срок действия карты. Пример: 05/30')
      .matches(
        /([0-9]{2})\/([0-9]{2})/,
        'Указан неверный срок действия карты. Пример: 05/30'
      )
      .test(
        'test-credit-card-expiration-date',
        'Cрок действия карты истек',
        expirationDate => {
          if (!expirationDate) {
            return false
          }
    
          const today = new Date()
          const monthToday = today.getMonth() + 1
          const yearToday = today
            .getFullYear()
            .toString()
            .substr(-2)
    
          const [expMonth, expYear] = expirationDate.split('/')
    
          if (Number(expYear) < Number(yearToday)) {
            return false
          } else if (
            Number(expMonth) < monthToday &&
            Number(expYear) <= Number(yearToday)
          ) {
            return false
          }
    
          return true
        }
      )
      .test(
        'test-credit-card-expiration-date',
        'Месяц истечения срока действия карты указан с ошибкой',
        expirationDate => {
          if (!expirationDate) {
            return false
          }
    
          const [expMonth] = expirationDate.split('/')
    
          if (Number(expMonth) > 12) {
            return false
          }
    
          return true
        }
      )
      .required('Укажите срок действия карты')
});

const AddCardModal = observer(({callback = null}) => {
    const { modal } = useContext(Context);

    const [ cardType, setCardType ] = useState('');
    const [ isDisabled, setIsDisabled ] = useState(true);
    
    const formOptions = { resolver: yupResolver(validationSchema) }
    const { register, watch, handleSubmit, formState: { errors } } = useForm(formOptions);

    const onSubmit = (data) => {
        console.log(data);
        var expData = data.expirationDate.split("/");
        const payload = {
            card_number: data.cardNumber.replaceAll(" ", ""),
            exp_month: expData[0],
            exp_year: expData[1],
            cvc: data.cvc,
            cardholder_name: data.cardHolder.toUpperCase(),
            type: cardType
        };

        createCreditCardApi(payload)
        .then((res) => {
            console.log(res);
            toast.success('Карта добавлена', toastOptions);
            if (callback) {
                callback();
            }
            modal.closeModal();
        })
        .catch((error) => {
            toast.error('Ошибка добавления карты', toastOptions);
            console.log(error);
            modal.closeModal();
        });
    }

    const formatCardNumberAndCardType = (event) => {
        setCardType(getCardType(event.target.value.replace(/\s/g, '')));
        event.target.value = [...event.target.value.replace(/\s/g, '')].map((d, i) =>
            (i) % 4 === 0 ? ' ' + d : d).join('').trim()
    }

    useEffect(() => {
        const subscription = watch((value) => {
            const { cardHolder, cardNumber, cvc, expirationDate } = value;
            (cardHolder && cardNumber && cvc && expirationDate) ? setIsDisabled(false) : setIsDisabled(true);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <div className="add-card-modal">
            <div className="header-modal">
                Добавить способ оплаты
                <div className="x-block" onClick={() => modal.closeModal()}>
                    <img src={XImg} className="x" alt="close"/>
                </div>
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control-block">
                    <label htmlFor="cardNumber" className="form-label">
                        Номер карты
                    </label>
                    <input
                        maxLength="19"
                        className={`form-control ${cardType ? cardType : 'card'}`}
                        id="cardNumber"
                        placeholder="" 
                        {...register('cardNumber')}
                        onKeyUp={formatCardNumberAndCardType}
                        // onChange={(e) => {
                            
                        // }}
                    />
                    {errors.cardNumber?.message && (
                        <p className="error">{errors.cardNumber?.message}</p>
                    )}
                </div>
                <div className="form-control-block">
                <div className="row">
                    <div className="col-6">
                        <label htmlFor="expirationDate" className="form-label">
                            Срок действия
                        </label>
                        <input
                            maxLength="5"
                            className="form-control date"
                            id="expirationDate"
                            placeholder="05/30" 
                            {...register('expirationDate')}
                            onKeyUp={formatExpirationDate}
                        />
                        {errors.expirationDate?.message && (
                            <p className="error">{errors.expirationDate?.message}</p>
                        )}
                    </div>
                    <div className="col-6">
                        <label htmlFor="cvc" className="form-label">
                            CVC/CVV
                        </label>
                        <input
                            maxLength="4"
                            type="password"
                            className="form-control secret"
                            id="cvc"
                            placeholder="•••"
                            {...register('cvc')}
                        />
                        {errors.cvc?.message && (
                            <p className="error">{errors.cvc?.message}</p>
                        )}
                    </div>
                </div>

                </div>
                <div className="form-control-block">
                    <label htmlFor="cardHolder" className="form-label">
                        Имя владельца карты
                    </label>
                    <input
                        className="form-control"
                        id="cardHolder"
                        placeholder="SERGEY IVANOV" 
                        {...register('cardHolder')}
                        onKeyUp={formatCardHolderName}
                    />
                    {errors.cardHolder?.message && (
                        <p className="error">{errors.cardHolder?.message}</p>
                    )}
                </div>
                <div className="actions">
                    <button type='button' className="cancel" onClick={() => modal.closeModal()}>
                        Закрыть
                    </button>
                    <button type='submit' className="confirm" disabled={isDisabled}>
                        Добавить карту
                    </button>
                </div>
            </form>
        </div>
    )
})

export { AddCardModal }