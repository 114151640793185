import config from '../config';
import instance from './axios-instance';
import axios from 'axios';

const STRAPI_API_URL = config.strapi.api_url + '/task';
let cancelToken;

export const getTasks = (page, ascending = false, categoryNames = []) => {
    let params = {};
    if (categoryNames.length) {
        params.category__name = categoryNames.toString();
    }
    if (ascending) {
        params.ordering = "created"; 
    } else { 
        params.ordering = "-created"; 
    } 
    if (Number(page) > 1) {
        params.page = page;
        return instance.get(`${STRAPI_API_URL}/`, { params });
    }
    return instance.get(`${STRAPI_API_URL}/`, { params });
}

export const filterTasks = (str = '', minPrice = 0, maxPrice = 0, minDeadline = 0, maxDeadline = 0, programmingLanguages = [], categoryNames = [], taskType = 0, ascending = false, page, page_size) => {
    if (cancelToken) {
        cancelToken.cancel("Operation cancelled due to new request");
    }
    cancelToken = axios.CancelToken.source();
    let params = {};
    if (str) {
        params.search = str;
    }
    if (minPrice && minPrice > 0) {
        params.min_price = minPrice;
    }
    if (maxPrice && maxPrice > 0) {
        params.max_price = maxPrice;
    }
    if (minDeadline && minDeadline > 0) {
        params.min_deadline = minDeadline;
    }
    if (maxDeadline && maxDeadline > 0) {
        params.max_deadline = maxDeadline;
    }
    if (programmingLanguages.length) {
        params.programming_language__name = programmingLanguages.toString();
    }
    if (categoryNames.length) {
        let categoryNamesToString = categoryNames.toString();
        if(categoryNamesToString) {
            params.category__name = categoryNamesToString;
        }
    }
    if (taskType && taskType > 0) {
        params.task_type = taskType;
    }
    if (ascending) { 
        params.ordering = "created"; 
    } else { 
        params.ordering = "-created"; 
    } 
    if (Number(page) > 1) {
        params.page = page;
    }
    if (page_size) {
        params.page_size = page_size;
    }
    const config = {
        headers: {},
        params: params,
        cancelToken: cancelToken.token
    }
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['authorization'] = 'Bearer ' + token
    }
    return axios.get(`${STRAPI_API_URL}/`, config)
}

export const getTasksForProfile = (id, task_statuses, task_type, page_size) => {
    let params = {};
    if (task_statuses && task_statuses.length > 0) {
        params.task_status = task_statuses.toString();
    }
    if (task_type) {
        params.task_type = task_type;
    }
    if (page_size) {
        params.page_size = page_size;
    }
    return instance.get(`${STRAPI_API_URL}/tasks/${id}/`, { params })
}

export const assignedToMeTasks = (task_status, task_type) => {
    let params = {};
    if (task_status) {
        params.task_status = task_status;
    }
    if (task_type) {
        params.task_type = task_type;
    }
    return instance.get(`${STRAPI_API_URL}/assigned-to-me-tasks/`, { params })
}

export const getTask = (id) => {
    return instance.get(`${STRAPI_API_URL}/${id}/`)
}

export const getTaskCategories = () => {
    return instance.get(`${STRAPI_API_URL}/task-category-list/`);
}

export const getTaskCategoriesCount = () => {
    return instance.get(`${STRAPI_API_URL}/task-category-count-list/`)
}

export const getProgrammingLanguages = () => {
    return instance.get(`${STRAPI_API_URL}/programming-language-list/`)
}

export const getProgrammingLanguagesCount = () => {
    return instance.get(`${STRAPI_API_URL}/programming-language-count-list/`)
}

export const createTask = (task) => {
    return instance.post(`${STRAPI_API_URL}/create/`, task)
}

export const updateTask = (taskId, task) => {
    return instance.put(`${STRAPI_API_URL}/update/${taskId}/`, task)
}

export const uploadUrls = (obj) => {
    return instance.post(`${STRAPI_API_URL}/urls/`, obj)
}

export const uploadFiles = (taskId, files) => {
    const formData = new FormData();
    formData.append("task", taskId.toString());
    for  (var i =  0; i <  files.length; i++)  {  
      formData.append("document",  files[i]);
    }
    return instance.post(`${STRAPI_API_URL}/documents/`, formData, { "Content-Type": "multipart/form-data" })
}

export const assignTask = (taskId, obj) => {
    return instance.put(`${STRAPI_API_URL}/assign-task/${taskId}/`, obj)
}

export const changeTaskStatus = (taskId, obj) => {
    return instance.put(`${STRAPI_API_URL}/change-status-task/${taskId}/`, obj)
}

export const getAllTasksCount = () => {
    return instance.get(`${STRAPI_API_URL}/all-task-count/`)
}

export const likeTask = (taskId) => {
    return instance.post(`${STRAPI_API_URL}/like-task/${taskId}/`)
}

export const removeLikeTask = (taskId) => {
    return instance.delete(`${STRAPI_API_URL}/like-task/${taskId}/`)
}

export const blockTask = (taskId) => {
    return instance.post(`${STRAPI_API_URL}/block-task/${taskId}/`)
}

export const removeBlockTask = (taskId) => {
    return instance.delete(`${STRAPI_API_URL}/block-task/${taskId}/`)
}

export const likeTaskList = () => {
    return instance.get(`${STRAPI_API_URL}/like-task-list/`)
}

export const blockTaskList = () => {
    return instance.get(`${STRAPI_API_URL}/block-task-list/`)
}

export const addWidthToTask = (h) => {
    if (h && h.results.length) {
        for (let i = 0; i < h.results.length/9; i++) {
            if (h.results[i*9]) {
                h.results[i*9].width = 6;
            }
            if (h.results[i*9+1]) {
                h.results[i*9+1].width = 3;
            }
            if (h.results[i*9+2]) {
                h.results[i*9+2].width = 3;
            }
            if (h.results[i*9+3]) {
                h.results[i*9+3].width = 3;
            }
            if (h.results[i*9+4]) {
                h.results[i*9+4].width = 6;
            }
            if (h.results[i*9+5]) {
                h.results[i*9+5].width = 3;
            }
            if (h.results[i*9+6]) {
                h.results[i*9+6].width = 4;
            }
            if (h.results[i*9+7]) {
                h.results[i*9+7].width = 4;
            }
            if (h.results[i*9+8]) {
                h.results[i*9+8].width = 4;
            }
        }
    }
}

export const addWidthToTask2 = (h) => {
    if (h && h.results.length) {
        let previosWidth = 4;
        for (let i = 0; i < h.results.length; i++) {
            if (i % 2 === 1) {
                h.results[i].width = 12 - previosWidth;
            } else {
                previosWidth = Math.floor(Math.random() * 2 + 1) * 4;
                h.results[i].width =  previosWidth;
            }
        }
    }
}

export const addWidthToLikedTask = (h) => {
    if (h && h.length) {
        for (let i = 0; i < h.length/9; i++) {
            if (h[i*9]) {
                h[i*9].width = 6;
            }
            if (h[i*9+1]) {
                h[i*9+1].width = 3;
            }
            if (h[i*9+2]) {
                h[i*9+2].width = 3;
            }
            if (h[i*9+3]) {
                h[i*9+3].width = 3;
            }
            if (h[i*9+4]) {
                h[i*9+4].width = 6;
            }
            if (h[i*9+5]) {
                h[i*9+5].width = 3;
            }
            if (h[i*9+6]) {
                h[i*9+6].width = 4;
            }
            if (h[i*9+7]) {
                h[i*9+7].width = 4;
            }
            if (h[i*9+8]) {
                h[i*9+8].width = 4;
            }
        }
    }
}