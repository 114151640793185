import config from '../config';
import instance from './axios-instance';

const STRAPI_API_URL = config.strapi.api_url + '/notifications';

export const getNotification = (id, page_size) => {
    let params = {};
    if (page_size) {
        params.page_size = page_size;
    }
    return instance.get(`${STRAPI_API_URL}/notifications/${id}/`, { params });
}

export const createNotification = (data) => {
    return instance.post(`${STRAPI_API_URL}/create-notification/`, data);
}