import './index.scss';
import WordIcon from '@assets/img/ms-word.png';
import ExcelIcon from '@assets/img/ms-excel.png';
import PowerPointIcon from '@assets/img/ms-powerpoint.png';
import PDFIcon from '@assets/img/adobe-pdf.png';
import FileIcon from '@assets/img/file-icon.png';
import XMini from '@assets/svg/x-mini.svg';
import { useState, useRef, createRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { formatBytes, trimFileName } from '@utils/utils';

const prewiewFiles = [
    {id: 1},
    {id: 2},
    {id: 3},
    {id: 4},
    {id: 5}
];

const PreviewAttachedFiles = forwardRef(({ files = [], onChangeCallback }, ref) => {
    const [ selectedFiles, setSelectedFiles ] = useState(files);
    const prewiewFilesRef = useRef(prewiewFiles.map(() => createRef()));

    const sortFiles = (a, b) => {
        let aType = a.type.split('/')[0];
        let bType = b.type.split('/')[0];
        if (aType === 'image' && bType === 'application') return -1;
        if (aType === 'application' && bType === 'image') return 1;
        return 0;
    }

    const removeFileHandler = (e) => {
        const fileId = e.target.getAttribute('id');
        const array = [...selectedFiles];
        array.splice(fileId, 1);
        setSelectedFiles(array);
        onChangeCallback(array);
    }

    useImperativeHandle(ref, () => ({

        addFiles(newFiles) {
            const array = [...newFiles, ...selectedFiles];
            setSelectedFiles(array.slice(0,5));
        }
    }));

    useEffect(() => {
        selectedFiles.sort(sortFiles).forEach((file, index) => {
            let [type, subtype] = file.type.split('/');
            if (type === 'application') {
                prewiewFilesRef.current[index].current.className = 'preview-file';
                switch (subtype) {
                    case 'msword':
                        prewiewFilesRef.current[index].current.src = WordIcon;
                        break;
                    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                        prewiewFilesRef.current[index].current.src = WordIcon;
                        break;
                    case 'vnd.ms-excel':
                        prewiewFilesRef.current[index].current.src = ExcelIcon;
                        break;
                    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                        prewiewFilesRef.current[index].current.src = ExcelIcon;
                        break;
                    case 'vnd.ms-powerpoint':
                        prewiewFilesRef.current[index].current.src = PowerPointIcon;
                        break;
                    case 'vnd.openxmlformats-officedocument.presentationml.presentation':
                        prewiewFilesRef.current[index].current.src = PowerPointIcon;
                        break;
                    case 'pdf':
                        prewiewFilesRef.current[index].current.src = PDFIcon;
                        break;
                    default:
                        prewiewFilesRef.current[index].current.src = FileIcon;
                        break;
                }
            }
            if (type === 'image') {
                const prewiewImage = (currentRef, file) => {
                    let reader  = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = function() {
                        currentRef.current.src = reader.result;
                        currentRef.current.className = 'preview-image';
                    }
                }
                prewiewImage(prewiewFilesRef.current[index], file);
            }
        });
    }, [selectedFiles]);

    return (
        <div className="preview-attached-files-component">
            <div className="files">
                {selectedFiles.length > 0 &&
                    selectedFiles.sort(sortFiles).map((file, index) =>
                        <div key={file.name} className="file">
                            <div className="file-bg-for-img">
                                <img ref={prewiewFilesRef.current[index]} />
                                <button id={index} className="remove-file-block" onClick={removeFileHandler}>
                                    <img id={index} src={XMini} />
                                </button>
                            </div>
                            <div className="file-name">{trimFileName(file.name)}</div>
                            <div className="file-size">{formatBytes(file.size, 0)}</div>
                        </div>
                    )
                }
            </div>
        </div>
    )
})

export { PreviewAttachedFiles }