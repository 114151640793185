import config from '../config';

const STRAPI_API_URL = config.strapi.websocket_url + '/notification';

class NotificationWebSocket {
    static instance = null;
    callbacks = {};
  
    static getInstance() {
      if (!NotificationWebSocket.instance) {
        NotificationWebSocket.instance = new NotificationWebSocket();
      }
      return NotificationWebSocket.instance;
    }
  
    constructor() {
      this.socketRef = null;
    }
  
    connect(roomName) {
      this.socketRef = new WebSocket(`${STRAPI_API_URL}/${roomName}/`);
      this.socketRef.onopen = () => {
        console.log('WebSocket open');
      };
      this.socketRef.onmessage = e => {
        console.log(e.data)
        this.socketNewMessage(e.data);
      };
  
      this.socketRef.onerror = e => {
        console.log(e.message);
      };
      this.socketRef.onclose = () => {
        console.log("WebSocket closed let's reopen");
        // this.connect(roomName);
      };
    }

    disconnect() {
      this.socketRef.close();
    }

    socketNewMessage(data) {
        const parsedData = JSON.parse(data);
        const command = parsedData.command;
        if (Object.keys(this.callbacks).length === 0) {
            return;
        }
        if (command === 'new_message') {
            this.callbacks[command](parsedData.message);
        }
    }
  
    // function for Notification component
    newNotificationMessage(message) {
        this.sendMessage(message); 
    }

    addCallbacks(newMessageCallback) {
        this.callbacks['new_message'] = newMessageCallback;
    }
    
    sendMessage(data) {
      try {
        this.socketRef.send(JSON.stringify({ ...data }));
      }
      catch(err) {
        console.log(err.message);
      }  
    }
  
    state() {
      return this.socketRef.readyState;
    }
  
    waitForSocketConnection(callback){
      const socket = this.socketRef;
      setTimeout(
        () => {
          if (socket.readyState === 1) {
            console.log("Connection is made")
            if(callback != null){
              callback();
            }
            return;
          } else {
            console.log("wait for connection...")
            this.waitForSocketConnection(callback);
          }
        }, 50); // wait 5 milisecond for the connection...
    }
  
  }
  
  const NotificationWebSocketInstance = NotificationWebSocket.getInstance();
  
  export default NotificationWebSocketInstance;
  