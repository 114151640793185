import { observer } from 'mobx-react-lite'
import { Markup } from 'react-render-markup'
import './index.scss'
import { useState, useEffect } from 'react'
import {
 getCategories as getCategoriesApi,
 getSubcategoryContent as getSubcategoryContentApi,
} from '@api/service-rules-api'

const TermsPage = observer(() => {
 const [content, setContent] = useState('')

 useEffect(() => {
  const getCategories = async () => {
   getCategoriesApi()
    .then((res) => {
     console.log(res.data)
     getContent(6)
    })
    .catch((error) => {
     console.log(error)
    })
   // console.log(result.results);
  }
  getCategories()
 }, [])

 const getContent = (id) => {
  const getSubcategoryContent = async () => {
   getSubcategoryContentApi(id)
    .then((res) => {
     if (res.data && res.data.content) {
      setContent(res.data.content)
     } else {
      setContent('')
     }
    })
    .catch((error) => {
     setContent('')
     console.log(error)
    })
   // console.log(result.results);
  }
  getSubcategoryContent()
 }

 return (
  <div className="service-rules-page">
   <div className="container">
    <div className="main">
     <div className="row gy-3">
      <div className="col-lg-12">
       <div className="right-block">
        <Markup markup={content} />
        {/* {content} */}
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 )
})

export { TermsPage }
