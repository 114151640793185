import { observer } from 'mobx-react-lite'
import './index.scss'
import { useState, useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { getQuestionCategories } from '@api/faq-api'


const FaqPage = observer(() => {
    const [ questionCategories, setQuestionCategories ] = useState([]);
    
    useEffect(() => {
        const getQuestionsF = async () => {
            getQuestionCategories()
            .then((res) => {
                setQuestionCategories(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
            // console.log(result.results);
        }
        getQuestionsF();
    }, []);

    return (
        <div className="faq-page">
            <div className="container">
                <div className="header">
                    👋 Привет, как мы можем тебе помочь?
                </div>
                <div className="faq-main">
                    {
                        questionCategories.length > 0 && questionCategories.map((c, i) => {
                            return (
                                <div className="questions-block" key={i}>
                                    <div className="subheader">
                                        {c.name}
                                    </div>
                                    <div className="questions">
                                        <Accordion defaultActiveKey="0">
                                            {
                                                c.questions.map((q, i) => {
                                                    return (
                                                        <Accordion.Item eventKey={i} key={i}>
                                                            <Accordion.Header>{q.question}</Accordion.Header>
                                                            <Accordion.Body>{q.answer}</Accordion.Body>
                                                        </Accordion.Item>
                                                    );
                                                })
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            );
                        })
                    }
                    
                </div>
            </div>
        </div>
    )
})

export { FaqPage }