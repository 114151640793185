export const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
};

export const SELECT_STYLES_PL = {
    // option: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isSelected ? '#ef5350' : '',
    // }),
    // ----
    control: (base, state) => ({
        ...base,
        background: '#fff',
        border: '1px solid #DEE5E9',
        borderRadius: '16px',
        padding: '10px 16px',
        display: 'flex',
        flexWrap: 'nowrap',

    //----
    //   borderColor: state.isFocused ? '#ef5350' : '',
    //   boxShadow: state.isFocused ? '#ef5350' : '',
    //   '&:hover': {
    //     borderColor: state.isFocused ? '#ef5350' : '',
    //   },
    // ----
    }),
    
    valueContainer: (base) => ({
        alignItems: 'center',
        display: 'flex',
        gap: '12px',
        flexWrap: 'wrap',
        padding: '0',
    }),

    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: '#F6F5F7',
            borderRadius: '16px',
            paddingLeft: '10px',
            paddingRight: '13px',
            paddingTop: '9px',
            paddingBottom: '9px',
            margin: '0px'
        };
      },
    //   multiValueLabel: (styles, { data }) => ({
    //     ...styles,
    //     color: data.color,
    //   }),
      multiValueRemove: (styles) => ({
        ...styles,
        color: '#000000',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: '#D4CFD9',
          color: 'white',
        },
      }),
};


export const SELECT_STYLES = {
    // option: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isSelected ? '#ef5350' : '',
    // }),
    control: (base, state) => ({
      ...base,
      background: '#fff',
      border: '1px solid #DEE5E9',
      borderRadius: '16px',
      padding: '10px 16px',
    //   borderColor: state.isFocused ? '#ef5350' : '',
    //   boxShadow: state.isFocused ? '#ef5350' : '',
    //   '&:hover': {
    //     borderColor: state.isFocused ? '#ef5350' : '',
    //   },
    }),
    
    valueContainer: (base) => ({
        padding: '0',
    })
};

export const PRICE_FOR_CHOICES = [
    { value: 'project', label: 'Проект' },
    { value: 'hour', label: 'Час' },
];

// Типы заказов (task_type)
// 1 - Заказ
// 2 - Услуга

export const YEARS_START = [
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
    { value: '2019', label: '2019' },
    { value: '2018', label: '2018' },
    { value: '2017', label: '2017' },
    { value: '2016', label: '2016' },
    { value: '2015', label: '2015' },
    { value: '2014', label: '2014' },
    { value: '2013', label: '2013' },
    { value: '2012', label: '2012' },
    { value: '2011', label: '2011' },
    { value: '2010', label: '2010' },
    { value: '2009', label: '2009' },
    { value: '2008', label: '2008' },
    { value: '2007', label: '2007' },
    { value: '2006', label: '2006' },
    { value: '2005', label: '2005' },
    { value: '2004', label: '2004' },
    { value: '2003', label: '2003' },
    { value: '2002', label: '2002' },
    { value: '2001', label: '2001' },
    { value: '2000', label: '2000' },
    { value: '1999', label: '1999' },
    { value: '1998', label: '1998' },
    { value: '1997', label: '1997' },
    { value: '1996', label: '1996' },
    { value: '1995', label: '1995' },
    { value: '1994', label: '1994' },
    { value: '1993', label: '1993' },
    { value: '1992', label: '1992' },
    { value: '1991', label: '1991' },
    { value: '1990', label: '1990' },
    { value: '1989', label: '1989' },
    { value: '1988', label: '1988' },
    { value: '1987', label: '1987' },
    { value: '1986', label: '1986' },
    { value: '1985', label: '1985' },
    { value: '1984', label: '1984' },
    { value: '1983', label: '1983' },
    { value: '1982', label: '1982' },
    { value: '1981', label: '1981' },
    { value: '1980', label: '1980' },
    { value: '1979', label: '1979' },
    { value: '1978', label: '1978' },
    { value: '1977', label: '1977' },
    { value: '1976', label: '1976' },
    { value: '1975', label: '1975' },
    { value: '1974', label: '1974' },
    { value: '1973', label: '1973' },
    { value: '1972', label: '1972' },
    { value: '1971', label: '1971' },
    { value: '1970', label: '1970' },
];

export const YEARS_END = [
    { value: '2023', label: 'Сейчас' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
    { value: '2019', label: '2019' },
    { value: '2018', label: '2018' },
    { value: '2017', label: '2017' },
    { value: '2016', label: '2016' },
    { value: '2015', label: '2015' },
    { value: '2014', label: '2014' },
    { value: '2013', label: '2013' },
    { value: '2012', label: '2012' },
    { value: '2011', label: '2011' },
    { value: '2010', label: '2010' },
    { value: '2009', label: '2009' },
    { value: '2008', label: '2008' },
    { value: '2007', label: '2007' },
    { value: '2006', label: '2006' },
    { value: '2005', label: '2005' },
    { value: '2004', label: '2004' },
    { value: '2003', label: '2003' },
    { value: '2002', label: '2002' },
    { value: '2001', label: '2001' },
    { value: '2000', label: '2000' },
    { value: '1999', label: '1999' },
    { value: '1998', label: '1998' },
    { value: '1997', label: '1997' },
    { value: '1996', label: '1996' },
    { value: '1995', label: '1995' },
    { value: '1994', label: '1994' },
    { value: '1993', label: '1993' },
    { value: '1992', label: '1992' },
    { value: '1991', label: '1991' },
    { value: '1990', label: '1990' },
    { value: '1989', label: '1989' },
    { value: '1988', label: '1988' },
    { value: '1987', label: '1987' },
    { value: '1986', label: '1986' },
    { value: '1985', label: '1985' },
    { value: '1984', label: '1984' },
    { value: '1983', label: '1983' },
    { value: '1982', label: '1982' },
    { value: '1981', label: '1981' },
    { value: '1980', label: '1980' },
    { value: '1979', label: '1979' },
    { value: '1978', label: '1978' },
    { value: '1977', label: '1977' },
    { value: '1976', label: '1976' },
    { value: '1975', label: '1975' },
    { value: '1974', label: '1974' },
    { value: '1973', label: '1973' },
    { value: '1972', label: '1972' },
    { value: '1971', label: '1971' },
    { value: '1970', label: '1970' },
];

// export const PROFESSIONS = [
//     { value: 'front-end-developer', label: 'Front-end разработчик'},
//     { value: 'back-end-developer', label: 'Back-end разработчик'},
//     { value: 'Full-stack-developer', label: 'Full-стек разработчик'},
//     { value: 'dev-ops', label: 'DevOps'}
// ];

export const LOCATIONS = [
    {value: 'ChIJybDUc_xKtUYRTM9XV8zWRD0', label: 'Москва, Россия', utc_offset: 180},
    {value: 'ChIJ7WVKx4w3lkYR_46Eqz9nx20', label: 'Санкт-Петербург, Россия', utc_offset: 180},
    {value: 'ChIJl03MkOHl30IRhenT4XMGOps', label: 'Новосибирск, Россия', utc_offset: 420},
    {value: 'ChIJS9tioOplwUMRIH9W99dDAtU', label: 'Екатеринбург, Россия', utc_offset: 300},
    {value: 'ChIJmc2sfCutXkERZYyttbl3y38', label: 'Казань, Россия', utc_offset: 180}
]