import url from 'url';

export const getNextPageNumber = (next) => {
    if(next) {
        const params = new URLSearchParams(url.parse(next).query);
        const nextPageNumber = params.get('page');
        return nextPageNumber;
    }
}

export const daysEndingOfTheWordFilter = (n) => {
    if (n > 9 && n < 20) {
        return "дней";
    } else if (n > 100) {
        return "дней";
    } else {
        let lastChar = n  % 10;
        switch (lastChar) {
            case 0:
                return "дней";
            case 1:
                return "дня";
            case 2:
                return "дней";
            case 3:
                return "дней";
            case 4:
                return "дней";
            case 5:
                return "дней";
            case 6:
                return "дней";
            case 7:
                return "дней";
            case 8:
                return "дней";
            case 9:
                return "дней";
            default:
                return "дней";
        }
    }
}

export const daysEndingOfTheWord = (n) => {
    if (n > 9 && n < 20) {
        return "дней";
    } else {
        let lastChar = n  % 10;
        switch (lastChar) {
            case 0:
                return "дней";
            case 1:
                return "день";
            case 2:
                return "дня";
            case 3:
                return "дня";
            case 4:
                return "дня";
            case 5:
                return "дней";
            case 6:
                return "дней";
            case 7:
                return "дней";
            case 8:
                return "дней";
            case 9:
                return "дней";
            default:
                return "дней";
        }
    }
}

export const hoursEndingOfTheWord = (n) => {
    if (n > 9 && n < 20) {
        return "часов";
    } else {
        let lastChar = n  % 10;
        switch (lastChar) {
            case 0:
                return "часов";
            case 1:
                return "час";
            case 2:
                return "часа";
            case 3:
                return "часа";
            case 4:
                return "часа";
            case 5:
                return "часов";
            case 6:
                return "часов";
            case 7:
                return "часов";
            case 8:
                return "часов";
            case 9:
                return "часов";
            default:
                return "часов";
        }
    }
}

export const taskDeadlineCalculation = (hours) => {
    let days = Math.floor(hours / 24);
    let modulo = hours % 24;
    if (modulo > 0) {
        return `${days} ${daysEndingOfTheWord(days)} ${modulo} ${hoursEndingOfTheWord(modulo)}`;
    }
    return `${days} ${daysEndingOfTheWord(days)}`;
}

export const isOdd = (num) => {
    return (num % 2) === 1;
}

export const formatCardHolderName = (event) => {
    event.target.value = event.target.value.toUpperCase();
}

export const formatExpirationDate = (event) => {
    // https://codepen.io/stoXmod/pen/bGePmdd
    // let inputChar = String.fromCharCode(event.keyCode);
    let code = event.keyCode;
    let allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
        return;
    }

    event.target.value = event.target.value.replace(
        /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
    ).replace(
        /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
    ).replace(
        /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
    ).replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
    ).replace(
        /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
    ).replace(
        /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
    ).replace(
        /\/\//g, '/' // Prevent entering more than 1 `/`
    );
}

export const getCardType = (number) => {
    // visa
    const reVisa = new RegExp("^4");
    if (number.match(reVisa) != null)
        return "visa";

    // MIR
    const reMir = new RegExp("^220[0-4]");
    if (number.match(reMir) != null)
        return "mir";
    
    // TODO Start only with '2'
    // Mastercard 1
    const reMastercardOne = new RegExp("^2[2-7][2-9]|2[3-7]");
    if (number.match(reMastercardOne) != null)
        return "mastercard";

    // Mastercard 2
    const reMastercardTwo = new RegExp("^5[1-5]");
    if (number.match(reMastercardTwo) != null)
        return "mastercard";

    return "visa";
}

export const isEllipsisActive = (e) => {
    const temp = e.cloneNode(true);

    temp.style.position = "fixed";
    temp.style.overflow = "visible";
    temp.style.whiteSpace = "nowrap";
    temp.style.visibility = "hidden";

    e.parentElement.appendChild(temp);

    try {
        const fullWidth = temp.getBoundingClientRect().width;
        const displayWidth = e.getBoundingClientRect().width;

        return fullWidth > displayWidth;
    } finally {
        temp.remove();
    }
}

export const numberWithSpaces = (nr) => {
	return nr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const tasksEndingOfTheWord = (n) => {
    if (n > 9 && n < 20) {
        return "задач";
    } else {
        let lastChar = n  % 10;
        switch (lastChar) {
            case 0:
                return "задач";
            case 1:
                return "задача";
            case 2:
                return "задачи";
            case 3:
                return "задачи";
            case 4:
                return "задачи";
            case 5:
                return "задач";
            case 6:
                return "задач";
            case 7:
                return "задач";
            case 8:
                return "задач";
            case 9:
                return "задач";
            default:
                return "задач";
        }
    }
}

export const filesEndingOfTheWord = (num) => {
    switch (num) {
        case 0:
            return 'файлов';
        case 1:
            return 'файл'; 
        case 2:
            return 'файла';
        case 3:
            return 'файла';
        case 4:
            return 'файла';
        case 5:
            return 'файлов';
        default:
            return 'файлов';
    }
}

export const linksEndingOfTheWord = (num) => {
    switch (num) {
        case 0:
            return 'ссылок';
        case 1:
            return 'ссылка'; 
        case 2:
            return 'ссылки';
        case 3:
            return 'ссылки';
        case 4:
            return 'ссылки';
        case 5:
            return 'ссылок';
        default:
            return 'ссылок';
    }
}

export const getNumberRatingReviews = (n) => {
    if (n > 9 && n < 20) {
        return "отзывов";
    } else {
        let lastChar = n  % 10;
        switch (lastChar) {
            case 0:
                return "отзывов";
            case 1:
                return "отзыв";
            case 2:
                return "отзыва";
            case 3:
                return "отзыва";
            case 4:
                return "отзыва";
            case 5:
                return "отзывов";
            case 6:
                return "отзывов";
            case 7:
                return "отзывов";
            case 8:
                return "отзывов";
            case 9:
                return "отзывов";
            default:
                return "отзывов";
        }
    }
}

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
}

export const formatDate = (_date) => {
    let _today = new Date();
    let today = [
        padTo2Digits(_today.getDate()),
        padTo2Digits(_today.getMonth() + 1),
        _today.getYear()-100,
    ].join('.');
    let date = [
        padTo2Digits(_date.getDate()),
        padTo2Digits(_date.getMonth() + 1),
        _date.getYear()-100,
    ].join('.');
    let time = [
        padTo2Digits(_date.getHours()),
        padTo2Digits(_date.getMinutes()),
        // padTo2Digits(_date.getSeconds()),
    ].join(':')
    return (`${date === today ? 'Сегодня' : date} ${time}`);
}

export const formatDateYear = (date) => {
    let parsedDate = new Date(date);
    return parsedDate.getFullYear();
}

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const trimFileName = (str) => {
    let [fileName, extension] = str.split('.');
    if (fileName.length > 7) {
        fileName = `${fileName.substring(0, 7)}...`;
    }
    return `${fileName}.${extension}`;
}

export const profileTimeUTC = (utc_offset) => {
    let now = new Date();
    let hours = now.getUTCHours() + Number(utc_offset) / 60;
    let minutes = now.getUTCMinutes();
    if (hours > 23) {
        hours -= 24;
    } else if (hours < 0) {
        hours += 24;
    }
    if (hours < 10) {
        hours = `0${hours}`;
    }
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
}