import config from '../config'
import instance from './axios-instance'

const STRAPI_API_URL = config.strapi.api_url + '/account'

export const register = (email, password, first_name, last_name) => {
    return new Promise((resolve, reject) => {
        instance.post(`${STRAPI_API_URL}/register/`, { email, password, first_name, last_name })
        .then((res) => {
            const a = res.data;
            localStorage.setItem('token', a.token.access);
            localStorage.setItem('refresh_token', a.token.refresh);
            resolve(res.data)
        })
        .catch((error) => {
            console.log(error);
            reject(error.response)
        })
    });
}

export const login = (email, password) => {
    return new Promise((resolve, reject) => {
        instance.post(`${STRAPI_API_URL}/api/token/`, { username: email, password: password })
        .then((res) => {
            const a = res.data;
            localStorage.setItem('token', a.access);
            localStorage.setItem('refresh_token', a.refresh);
            resolve(res.data)
        })
        .catch((error) => {
            console.log(error);
            reject(error.response)
        })
    });
}

export const refreshToken = (token) => {
    return instance.post(`${STRAPI_API_URL}/api/token/refresh/`, { refresh: token })
}

export const me = () => {
    return new Promise((resolve, reject) => {
        instance.get(`${STRAPI_API_URL}/me/`)
        .then((res) => {
            const u = res.data;
            localStorage.setItem('user', JSON.stringify(u));
            resolve(res.data)
        })
        .catch((error) => {
            console.log(error);
            reject(error.response)
        })
    });
}

export const updateUser = (obj) => {
    return instance.put(`${STRAPI_API_URL}/update-user/`, obj)
}

export const updateUserImage = (obj) => {
    return instance.put(`${STRAPI_API_URL}/update-user-image/`, obj)
}

export const deleteUserImage = () => {
    return instance.delete(`${STRAPI_API_URL}/remove-user-image/`)
}

export const updateProfile = (obj) => {
    return instance.put(`${STRAPI_API_URL}/update-profile/`, obj)
}

export const createCreditCard = (obj) => {
    return instance.post(`${STRAPI_API_URL}/credit-card-create/`, obj)
}

export const deleteCreditCard = (cardId) => {
    return instance.delete(`${STRAPI_API_URL}/credit-card-delete/${cardId}/`)
}

export const updateCreditCard = (cardId, obj) => {
    return instance.put(`${STRAPI_API_URL}/credit-card-update/${cardId}/`, obj)
}

export const updateSkills = (obj) => {
    return instance.post(`${STRAPI_API_URL}/user-skills/`, obj)
}

export const createPortfolio = (obj) => {
    return instance.post(`${STRAPI_API_URL}/portfolio/`, obj)
}

export const removePortfolio = (id) => {
    return instance.delete(`${STRAPI_API_URL}/portfolio/${id}/`)
}

export const updatePortfolio = (id, obj) => {
    return instance.put(`${STRAPI_API_URL}/portfolio/${id}/`, obj)
}

export const uploadPortfolioUrls = (obj) => {
    return instance.post(`${STRAPI_API_URL}/portfolio-urls/`, obj)
}

export const uploadPortfolioFiles = (id, files) => {
    const formData = new FormData();
    formData.append("portfolio", id.toString());
    for  (var i =  0; i <  files.length; i++)  {  
      formData.append("document",  files[i]);
    }
    return instance.post(`${STRAPI_API_URL}/portfolio-documents/`, formData, { "Content-Type": "multipart/form-data" })
}

export const updateWorkExperiences = (obj) => {
    return instance.post(`${STRAPI_API_URL}/work-experiences/`, obj)
}

export const getUserInfo = (id) => {
    return instance.get(`${STRAPI_API_URL}/user-info/${id}/`)
}

export const getSpecializationList = () => {
    return instance.get(`${STRAPI_API_URL}/specialization-list/`)
}

export const changePassword = (obj) => {
    return instance.put(`${STRAPI_API_URL}/change-password/`, obj)
}

export const deleteUser = (obj) => {
    return instance.post(`${STRAPI_API_URL}/delete-user/`, obj)
}

export const logout = () => {
    removeItemsFromLocalstorage();
}

const removeItemsFromLocalstorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
}